"use client";

import { IoChevronForwardOutline } from "react-icons/io5";
import React, { useEffect } from "react";
import {
  DateCalendar,
  LocalizationProvider,
  StaticDatePicker,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import ListView from "../Components/Elements/ListView";
import { getList, URLPRIFIX } from "../functions/api";
import { useStore } from "../Components/StateProvider";
import { getlocal } from "../functions/localstorage";
import { contentHeader, TableHeader, TableText } from "../Constancs/cssconst";
import DocumentUpdate from "../Components/Elements/DocumentUpdate";
import TaskAssignment from "../Components/Elements/TaskAssignment";
import UpcomingDueDate from "../Components/Elements/UpcomingDueDate";
import Schedule from "../Components/Elements/Schedule";
// import RecentActivity from "../Components/Elements/RecentActivity";
// import { Calendar } from "@nextui-org/calendar";
// import { today, getLocalTimeZone, isWeekend } from "@internationalized/date";
// import { useLocale } from "@react-aria/i18n";

const Dashboard = () => {
  const store = useStore();
  const props = getlocal("properties");
  const init = {
    assignTask: [],
    DocumentList: [],
    Duedatelistdoc: [],
    Schedulecal: "",
  };

  useEffect(() => {
    store.setmodule("dashboard", init);
    store.stopLoading();
    store.setheader("Hello, " + getlocal("system")?.user?.name);
    console.log("store.getuser()?.id", getlocal("system")?.user?.id);
  }, []);

  return (
    <div className="grid h-[95%] max-sm:h-[200%] grid-cols-6 text-[#04047A]">
      <div className="bg-Alice_Blue col-span-6 max-sm:col-span-6 md:rounded-[15px] lg:rounded-[20px] xl:rounded-[30px] m-[8px] p-3 flex items-center flex-col xl:h-[310px] lg:h-[290px] relative">
        <DocumentUpdate store={store} props={props} />
      </div>
      <div className="bg-tertiary col-span-2 max-sm:col-span-6 md:rounded-[15px] lg:rounded-[20px] xl:rounded-[30px] m-[8px] p-3 flex items-center flex-col gap-4 xl:h-[310px] lg:h-[290px] ">
        <UpcomingDueDate store={store} props={props} />
      </div>
      <div className="bg-[#EFF4FF]  col-span-2 max-sm:col-span-6 md:rounded-[15px] lg:rounded-[20px] xl:rounded-[30px] m-[8px] relative p-3 xl:h-[310px] lg:h-[290px] ">
        <Schedule store={store} props={props} />
      </div>
      {/* <div className="bg-Alice_Blue col-span-2 max-sm:col-span-6 md:rounded-[15px] lg:rounded-[20px] xl:rounded-[30px] m-[8px] p-3 flex items-center flex-col gap-4 xl:h-[310px] lg:h-[290px] ">
        <RecentActivity store={store} props={props} />
      </div> */}
      <div className="bg-tertiary col-span-2 max-sm:col-span-6 md:rounded-[15px] lg:rounded-[20px] xl:rounded-[30px] m-[8px] p-3 flex items-center flex-col gap-4 relative xl:h-[310px] lg:h-[290px]">
        <TaskAssignment store={store} props={props} />
      </div>
    </div>
  );
};

export default Dashboard;
