import React, { useEffect, useRef, useState } from "react";
import WordEditor from "../Components/Elements/WordEditor/WordEditor";
import { GetRecords, UpdateRecord } from "../functions/api";
import { useStore } from "../Components/StateProvider";
import { useLocation, useParams } from "react-router-dom";
import QuillEditor from "../Components/Elements/WordEditor/QuillEditor";
import HtmlToPdf from "../Components/Elements/HtmlToPdf";
import { saveAs } from "file-saver";
import * as quillToWord from "quill-to-word";
// import * as quillToWord from "html-to-docx";

import { Document, Packer, Paragraph, TextRun, AlignmentType } from "docx";
import { Quill } from "react-quill";
import { secondaryBTN } from "../Constancs/cssconst";

const Editor = () => {
  const { id } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [comments, setComments] = useState([]);
  const quillRef = useRef(null);

  const store = useStore();

  useEffect(() => {
    store.setmodule("Editor", { editor: "" });
    store.setheader("Editor");
    GetRecords("/editor/geteditorlist/" + id).then((res) => {
      console.log("Editor responce", res);

      if (res.success) {
        store.setvalue("editor", res.record.response[0].editor_content);
      }
    });
  }, []);

  const handleExportDocx = async () => {
    try {
      // const htmlContent = editorContent;

      // Create a new Document
      const doc = new Document({
        sections: [
          {
            properties: {},
            children: [
              new Paragraph({
                children: htmlToTextRun(store.getvalue("editor")),
                alignment: AlignmentType.LEFT, // Adjust alignment if needed
              }),
            ],
          },
        ],
      });

      // Convert Document to buffer and save as DOCX
      const blob = await Packer.toBlob(doc);
      saveAs(blob, "document.docx");
    } catch (error) {
      console.error("Error exporting DOCX file:", error);
    }
  };
  const htmlToTextRun = (html) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");
    const textRuns = [];

    const processNode = (node) => {
      if (node.nodeType === Node.TEXT_NODE) {
        if (node.textContent.trim()) {
          textRuns.push(new TextRun(node.textContent));
        }
      } else if (node.nodeType === Node.ELEMENT_NODE) {
        let textRun = new TextRun({
          text: node.textContent,
          bold: node.nodeName === "STRONG" || node.style.fontWeight === "bold",
          italics: node.nodeName === "EM" || node.style.fontStyle === "italic",
          underline: node.style.textDecoration === "underline",
          font: node.style.fontFamily || "Arial",
          size: parseInt(node.style.fontSize) || 24,
          color: node.style.color || "000000",
        });
        textRuns.push(textRun);

        Array.from(node.childNodes).forEach((child) => processNode(child));
      }
    };

    Array.from(doc.body.childNodes).forEach((node) => processNode(node));

    return textRuns;
  };
  const handleAddComment = () => {
    const selection = quillRef.current.getEditor().getSelection();
    if (selection) {
      const commentText = prompt("Enter your comment:");
      if (commentText) {
        const newComment = {
          id: comments.length + 1,
          text: commentText,
          range: selection,
        };
        setComments([...comments, newComment]);

        // Apply a highlight to the selected text
        quillRef.current
          .getEditor()
          .formatText(selection.index, selection.length, {
            background: "yellow",
          });
      }
    }
  };

  return (
    <div className="bg-secondary">
      <QuillEditor comments={comments} quillRef={quillRef} />
      {/* <WordEditor id={"Editor254"} /> */}
      <div className="flex justify-end items-center px-[2%] py-[2%] gap-5 absolute bottom-0 right-0">
        <button
          className="py-3 px-10 rounded-xl font-bold bg-tertiary text-primary"
          onClick={() => {
            console.log("Close start");
            window.close();
          }}
        >
          Back
        </button>
        <button
          className="py-3 px-10 rounded-xl font-bold bg-primary text-secondary"
          onClick={() => {
            console.log("save start");

            UpdateRecord("/editor/updateeditor/" + id, {
              editor_content: store.getvalue("editor"),
            }).then((res) => {
              console.log("Save Editor", res);
              alert("Saved Successfully");
            });
          }}
        >
          save
        </button>
        {/* <button
          className="py-3 px-10 rounded-xl font-bold bg-primary text-secondary"
          onClick={async () => {
            handleExportDocx();
            // console.log("save start");
            // const delta = new Quill(".ql-editor").getContents();
            // console.log("Text 223",delta);
            // const quillToWordConfig = quillToWord.Config = {
            //   exportAs: "blob"
            // };
            // const blob = await quillToWord.generateWord(delta);

            // console.log("Text 223",blob);
            // saveAs(blob, "word-export.docx");
          }}
        >
          Download Word Doc
        </button>

        <HtmlToPdf HtmlContent={store.getvalue("editor")} />
        <button className={secondaryBTN} onClick={handleAddComment}>
          Add Comment
        </button> */}
      </div>
    </div>
  );
};

export default Editor;
