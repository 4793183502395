import React, { useEffect, useState } from "react";
import AccountsLayout from "../AccountsLayout";
import { useLocation, useParams } from "react-router-dom";
import { useStore } from "../../../Components/StateProvider";
import {
  DeleteRecord,
  getList,
  GetListview,
  SuccessAlert,
  URLPRIFIX,
  WarningAlert,
} from "../../../functions/api";
import Filters from "../../../Components/Fields/Filters";
import ListView from "../../../Components/Elements/ListView";
import { getStorage } from "../../../functions/localstorage";
import Pagination from "../../../Components/Fields/Pagination";
import {
  URL_Account_Journal_Delete,
  URL_Account_Journal_List,
} from "../../../Constancs/apiconst";

const JournalsList = () => {
  const location = useLocation();
  const { companyId } = useParams();
  const queryParams = new URLSearchParams(location.search);
  const tablename = "companies";
  const tableURL = "/companies/getusers";
  const DeleteURL = "/companies/deleteusers/";
  let storage = getStorage();
  const [transtypeid, settransactiontypeid] = useState();
  const [list, setlist] = useState([]);

  const Header = [
    ["DATE", "createdDate"],
    ["JOURNAL ENTRY", "referenceNumber"],
    ["ACCOUNT", "ledger_name"],
    ["PARTNER", "clientname"],
    ["DEBIT ", "debit"],
    ["CREDIT ", "credit"],
    ["CREATED BY", "createdBy"],
    ["NOTES", "notes"],
    ["STATUS", "status"],
    ["ACTION", "ACTION"],
  ];
  const store = useStore();
  const init = {
    filterfield: "all",
    search: "",
    sort: "desc",
    currentpage: 1,
    totalpage: 1,
  };

  useEffect(() => {
    store.setmodule("journals", init).then((res) => {
      store.stopLoading();
      store.setheader(tablename + " List");

      getList({
        listUrl: URL_Account_Journal_List + companyId,
        store: store,
        field: queryParams.get("field") || init.filterfield,
        search: queryParams.get("search") || init.search,
        sort: queryParams.get("sort") || init.sort,
      }).then((res) => {
        setlist(res.data.list);
        res = res.data;
        console.log("res of journal list", res);

        let totalCount = res.totalCount / 10;

        // console.log("totalCount value", totalCount % 1 == 0);
        if (totalCount % 1 == 0) {
          // console.log("totalCount", totalCount);
          totalCount = Math.floor(totalCount);
        } else {
          // console.log("totalCount", totalCount);
          totalCount = Math.floor(totalCount) + 1;
        }

        store.setvalue("totalpage", totalCount);
        store.setvalue("currentpage", 1);
        // // console.log("Res, ", totalCount);

        store.setvalue("list", res.list);
        // let head = Object.keys(res.list[0]);
        // head.splice(5, 0, "ACTION");
        // store.setvalue("header", head);
      });
    });
  }, []);

  function change(props) {
    console.log("search data - ", props);
    getList({
      listUrl: URL_Account_Journal_List + companyId,
      store: store,
      field: props.field,
      search: props.search,
      sort: props.sort,
    }).then((res) => {
      setlist(res.data.list);
      res = res.data;
      console.log("res of journal list:change", res);

      let totalCount = res.totalCount / 10;

      // console.log("totalCount value", totalCount % 1 == 0);
      if (totalCount % 1 == 0) {
        // console.log("totalCount", totalCount);
        totalCount = Math.floor(totalCount);
      } else {
        // console.log("totalCount", totalCount);
        totalCount = Math.floor(totalCount) + 1;
      }

      store.setvalue("totalpage", totalCount);
      // // console.log("Res, ", totalCount);

      store.setvalue("list", res.list);
      // let head = Object.keys(res.list[0]);
      // head.splice(5, 0, "ACTION");
      // store.setvalue("header", head);
    });
  }
  function onpage(props) {
    getList({
      listUrl: URL_Account_Journal_List + companyId,
      store: store,
      field: store.getvalue("filterfield"),
      search: store.getvalue("search"),
      sort: store.getvalue("sort"),
      page: props,
    }).then((res) => {
      setlist(res.data.list);
      res = res.data;
      console.log("res of journal list:onpage", res);

      let totalCount = res.totalCount / 10;

      // console.log("totalCount value", totalCount % 1 == 0);
      if (totalCount % 1 == 0) {
        // console.log("totalCount", totalCount);
        totalCount = Math.floor(totalCount);
      } else {
        // console.log("totalCount", totalCount);
        totalCount = Math.floor(totalCount) + 1;
      }

      store.setvalue("totalpage", totalCount);
      store.setvalue("currentpage", props);
      // // console.log("Res, ", totalCount);

      store.setvalue("list", res.list);
      // let head = Object.keys(res.list[0]);
      // head.splice(5, 0, "ACTION");
      // store.setvalue("header", head);
    });
  }

  function onview(transaction_id, transaction_type_id) {
    console.log("transaction_type_id", transaction_type_id);

    switch (transaction_type_id) {
      case 1:
        store.navTo(
          "/" +
            URLPRIFIX +
            "/" +
            companyId +
            "/accounts/invoice/view/" +
            transaction_id
        );
        break;
      case 2:
        store.navTo(
          "/" +
            URLPRIFIX +
            "/" +
            companyId +
            "/accounts/journal/view/" +
            transaction_id
        );
        break;
      case 3:
        store.navTo(
          "/" +
            URLPRIFIX +
            "/" +
            companyId +
            "/accounts/expense/view/" +
            transaction_id
        );
        break;
      case 4:
        store.navTo(
          "/" +
            URLPRIFIX +
            "/" +
            companyId +
            "/accounts/receipt/view/" +
            transaction_id
        );
        break;
      case 7:
        store.navTo(
          "/" +
            URLPRIFIX +
            "/" +
            companyId +
            "/accounts/payable/view/" +
            transaction_id
        );
        break;

      // default:
      //   break;
    }
  }

  return (
    <AccountsLayout HeaderTitle={"Journals"}>
      <div className="grid grid-rows-10 h-full ">
        <div className="row-span-1 relative">
          <Filters fields={Header} onchange={change} />
          {store.getprops("add") && (
            <div className="absolute right-10 top-0">
              <button
                className="bg-primary text-secondary py-2 text-[12px] px-6 rounded-3xl"
                onClick={() => {
                  store.removemodule(
                    tablename.substring(0, tablename.length - 1)
                  );
                  store.navTo(
                    "/" +
                      URLPRIFIX +
                      "/" +
                      companyId +
                      "/accounts/journal/create"
                  );
                }}
              >
                Add Journal
              </button>
            </div>
          )}
        </div>
        <div className="row-span-9 h-full w-full ">
          <div
            className="py-5 px-[30px] h-full max-2xl:w-[1000px] w-full"
            contentEditable={false}
          >
            <div
              className={`flex  overflow-auto `}
              style={{ height: "90%", width: "1080px" }}
            >
              <table className="table-auto w-full h-fit">
                <thead className="sticky top-0 bg-secondary text-left mt-10 border-b">
                  <tr className="px-1">
                    {Header.map(([name, label], index) => {
                      if (label !== "id") {
                        return (
                          <th
                            key={index}
                            className={
                              " min-w-[150px] pr-[10px] max-sm:text-[26px] md:text-[20px] lg:text-[16px] xl:text-[20px] font-bold "
                            }
                          >
                            {name}
                          </th>
                        );
                      }
                    })}
                  </tr>
                </thead>

                <tbody>
                  {list.map((item, index) => (
                    <tr
                      key={index}
                      className={
                        "border-b h-8 hover:bg-[#b3b3f5] rounded-xl " +
                        (item.error && ` bg-red bg-opacity-35 `)
                      }
                    >
                      {Header.map(([name, label], fieldindex) => {
                        console.log("Listview actioon", item);

                        if (label == "ACTION") {
                          return (
                            <td
                              key={fieldindex}
                              className={
                                "min-w-[150px] pr-[10px] max-sm:text-[26px] md:text-[20px] lg:text-[12px] xl:text-[16px] overflow-hidden"
                              }
                            >
                              <div className="flex gap-7">
                                {onview && (
                                  <img
                                    src="/Assets/images/eye.png "
                                    className=" cursor-pointer w-5 h-5 hover:scale-125"
                                    onClick={() => {
                                      console.log("transaction_type_id", item);

                                      onview(
                                        item["transactionId"],
                                        item["transactionTypeId"]
                                      );
                                    }}
                                  />
                                )}
                              </div>
                            </td>
                          );
                        } else if (label === "activeStatus") {
                          return (
                            <td
                              key={fieldindex}
                              className={
                                "min-w-[150px] pr-[10px] max-sm:text-[26px] md:text-[20px] lg:text-[12px] xl:text-[16px] overflow-hidden"
                              }
                            >
                              {item[label] ? "Active" : "Inactive"}
                            </td>
                          );
                        } else if (
                          label.toLowerCase().includes("date") &&
                          item[label] != undefined
                        ) {
                          const [date, time] = item[label].split("T");
                          if (time != null && time != undefined) {
                            // console.log("Time ", date, time);

                            return (
                              <td
                                key={fieldindex}
                                className={
                                  "min-w-[150px] pr-[10px] max-sm:text-[26px] md:text-[20px] lg:text-[12px] xl:text-[16px] overflow-hidden"
                                }
                                onClick={() => {
                                  onclick && onclick(item["id"]);
                                }}
                              >
                                {date}
                              </td>
                            );
                          } else {
                            return (
                              <td
                                key={fieldindex}
                                className={
                                  "min-w-[150px] pr-[10px] max-sm:text-[26px] md:text-[20px] lg:text-[12px] xl:text-[16px] overflow-hidden"
                                }
                              >
                                ---
                              </td>
                            );
                          }
                        } else if (label != "id") {
                          return (
                            <td
                              key={fieldindex}
                              className={
                                "min-w-[150px] pr-[10px] max-sm:text-[26px] md:text-[20px] lg:text-[12px] xl:text-[16px] overflow-hidden"
                              }
                              onClick={() => {
                                onclick && onclick(item["id"]);
                              }}
                            >
                              {item[label]}
                            </td>
                          );
                        } else {
                          <td
                            key={fieldindex}
                            className={
                              "min-w-[150px] pr-[10px] max-sm:text-[26px] md:text-[20px] lg:text-[12px] xl:text-[16px] overflow-hidden"
                            }
                          >
                            ---
                          </td>;
                        }
                      })}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="float-right mr-8 mt-6">
              <Pagination onchange={onpage} />
            </div>
          </div>
        </div>
      </div>
    </AccountsLayout>
  );
};

export default JournalsList;
