import Dropdown from "../../Components/Fields/Dropdown";
import {
  CheckMandatory,
  ErrorAlert,
  GetRecords,
  saveRecord,
  SuccessAlert,
  UpdateRecord,
} from "../../functions/api";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import String from "../../Components/Fields/String";
import { useStore } from "../../Components/StateProvider";
import FormView from "../../Components/Elements/FormView";
import Journal from "../../Components/Fields/Journal";

const UserForm = () => {
  const { option, id } = useParams();
  const tablename = "user";
  const setrecord = "/users/register";
  const getrecord = `/users/users/${id}`;
  const updaterecord = "/users/updateusers/" + id;
  const updatepasswordAPI = "/users/updatepassword/";
  const store = useStore();

  const modules2 = [
    {
      name: "dashboard",
      permissions: {
        on_off: false,
        add: false,
        view: false,
        update: false,
        delete: false,
        print: false,
      },
    },
    {
      name: "users",
      permissions: {
        on_off: false,
        add: false,
        view: false,
        update: false,
        delete: false,
        print: false,
      },
    },
    {
      name: "contacts",
      permissions: {
        on_off: false,
        add: false,
        view: false,
        update: false,
        delete: false,
        print: false,
      },
    },
    {
      name: "categories",
      permissions: {
        on_off: false,
        add: false,
        view: false,
        update: false,
        delete: false,
        print: false,
      },
    },
    {
      name: "films",
      permissions: {
        on_off: false,
        add: false,
        view: false,
        update: false,
        delete: false,
        print: false,
      },
    },
    {
      name: "forms",
      permissions: {
        on_off: false,
        add: false,
        view: false,
        update: false,
        delete: false,
        print: false,
      },
    },
    {
      name: "nonlitigation",
      permissions: {
        on_off: false,
        add: false,
        view: false,
        update: false,
        delete: false,
        print: false,
      },
    },

    {
      name: "projects",
      permissions: {
        on_off: false,
        add: false,
        view: false,
        update: false,
        delete: false,
        print: false,
      },
    },
    {
      name: "documents",
      permissions: {
        on_off: false,
        add: false,
        view: false,
        update: false,
        delete: false,
        print: false,
      },
    },
    {
      name: "tasks",
      permissions: {
        on_off: false,
        add: false,
        view: false,
        update: false,
        delete: false,
        print: false,
      },
    },
    {
      name: "companies",
      permissions: {
        on_off: false,
        add: false,
        view: false,
        update: false,
        delete: false,
        print: false,
      },
    },
    {
      name: "AccountGroups",
      permissions: {
        on_off: false,
        add: false,
        view: false,
        update: false,
        delete: false,
        print: false,
      },
    },
    {
      name: "ChartsOfAccounts",
      permissions: {
        on_off: false,
        add: false,
        view: false,
        update: false,
        delete: false,
        print: false,
      },
    },
    {
      name: "customers",
      permissions: {
        on_off: false,
        add: false,
        view: false,
        update: false,
        delete: false,
        print: false,
      },
    },
    {
      name: "invoices",
      permissions: {
        on_off: false,
        add: false,
        view: false,
        update: false,
        delete: false,
        print: false,
      },
    },
    {
      name: "Expenses",
      permissions: {
        on_off: false,
        add: false,
        view: false,
        update: false,
        delete: false,
        print: false,
      },
    },
    {
      name: "payables",
      permissions: {
        on_off: false,
        add: false,
        view: false,
        update: false,
        delete: false,
        print: false,
      },
    },
    {
      name: "receipts",
      permissions: {
        on_off: false,
        add: false,
        view: false,
        update: false,
        delete: false,
        print: false,
      },
    },
    {
      name: "journals",
      permissions: {
        on_off: false,
        add: false,
        view: false,
        update: false,
        delete: false,
        print: false,
      },
    },
    {
      name: "balancesheets",
      permissions: {
        on_off: false,
        add: false,
        view: false,
        update: false,
        delete: false,
        print: false,
      },
    },
    {
      name: "trailbalances",
      permissions: {
        on_off: false,
        add: false,
        view: false,
        update: false,
        delete: false,
        print: false,
      },
    },
    {
      name: "profitandLoss",
      permissions: {
        on_off: false,
        add: false,
        view: false,
        update: false,
        delete: false,
        print: false,
      },
    },
    {
      name: "generalledgers",
      permissions: {
        on_off: false,
        add: false,
        view: false,
        update: false,
        delete: false,
        print: false,
      },
    },
    {
      name: "tdsreports",
      permissions: {
        on_off: false,
        add: false,
        view: false,
        update: false,
        delete: false,
        print: false,
      },
    },
  ];
  // role_id list
  // 1 - Admin
  // 2 - Editor
  // 3 - Viewers
  const init = {
    firstname: "",
    lastname: "",
    username: "",
    address: "",
    aadhar_no: "",
    emergency_contact_no: "",
    email: "",
    password: "",
    activestatus: true,
    access_control: modules2,
    role_id: "",
  };

  useEffect(() => {
    // console.log("Params",param);

    store.setmodule(tablename, init);
    if (id !== undefined) {
      store.setheader(option.toUpperCase() + " USER");
      store.setprops("activestatus", { hidden: false });
      store.setprops("passwordpopup", { hidden: true });
      // props for the Mandatory field
      store.setprops("username", { mandatory: false });
      store.setprops("email", { mandatory: false });
      store.setprops("password", { hidden: true, mandatory: false });
      store.setprops("role_id", { mandatory: false });
      if (option === "profile") {
        store.setprops("username", { hidden: false, readonly: false });
        store.setprops("firstname", { hidden: false, readonly: false });
        store.setprops("lastname", { hidden: false, readonly: false });
        store.setprops("email", { hidden: false, readonly: true });
        store.setprops("password", { hidden: true, readonly: true });
        store.setprops("activestatus", { hidden: true, readonly: false });
        store.setprops("access_control", { hidden: false, readonly: true });
        store.setprops("role_id", { hidden: false, readonly: true });
        store.setprops("status", { hidden: false, readonly: true });
      } else if (option === "view") {
        store.setprops("firstname", { hidden: false, readonly: true });
        store.setprops("lastname", { hidden: false, readonly: true });
        store.setprops("username", { hidden: false, readonly: true });
        store.setprops("email", { hidden: false, readonly: true });
        //store.setprops("password", { hidden: true, readonly: true });
        store.setprops("activestatus", { hidden: false, readonly: true });
        store.setprops("access_control", { hidden: false, readonly: true });
        store.setprops("role_id", { hidden: false, readonly: true });
        store.setprops("status", { hidden: false, readonly: true });
        store.setprops("address", { hidden: false, readonly: true });
        store.setprops("aadhar_no", { hidden: false, readonly: true });
        store.setprops("emergency_contact_no", {
          hidden: false,
          readonly: true,
        });
      } else {
        store.setprops("firstname", { hidden: false, readonly: false });
        store.setprops("lastname", { hidden: false, readonly: false });
        store.setprops("username", { hidden: false, readonly: false });
        store.setprops("email", { hidden: false, readonly: false });
        store.setprops("password", { hidden: true, readonly: false });
        store.setprops("activestatus", { hidden: false, readonly: false });
        store.setprops("access_control", { hidden: false, readonly: false });
        store.setprops("role_id", { hidden: false, readonly: false });
        store.setprops("status", { hidden: false, readonly: false });
        store.setprops("address", { hidden: false, readonly: false });
        store.setprops("aadhar_no", { hidden: false, readonly: false });
        store.setprops("emergency_contact_no", {
          hidden: false,
          readonly: false,
        });
      }
      GetRecords(getrecord).then((res) => {
        console.log("USer Data - ", res, res.record);
        if (res.success) {
          init.firstname = res.record.firstName;
          init.lastname = res.record.lastName;
          init.username = res.record.userName;
          init.address = res.record.address;
          init.aadhar_no = res.record.aadhar_no;
          init.emergency_contact_no = res.record.emergency_contact_no;
          init.email = res.record.email;
          init.access_control =
            res.record.access_control &&
            res.record.access_control !== "" &&
            JSON.parse(res.record.access_control);
          init.role_id = res.record.role_id;
          init.activestatus = res.record.activeStatus;
          store.reset(init);
        } else if (res.code === 401) {
          ErrorAlert(" Error Found Please contact the Admin");
        }
      });
    } else {
      store.setheader("CREATE NEW USER");
      // props for the Mandatory field
      store.setprops("password", { hidden: false });
      store.setprops("activestatus", { hidden: true });
      store.setprops("username", { mandatory: true });
      store.setprops("email", { mandatory: true });
      store.setprops("password", { mandatory: true });
      store.setprops("role_id", { mandatory: true });
      store.setprops("firstname", { mandatory: false });
      store.setprops("lastname", { mandatory: false });
      store.setprops("access_control", { mandatory: false });
      store.setprops("address", { hidden: false, readonly: false });
      store.setprops("aadhar_no", { hidden: false, readonly: false });
      store.setprops("emergency_contact_no", {
        hidden: false,
        readonly: false,
      });
    }
  }, [option, id]);

  const validateEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };

  function update() {
    store.startLoading();
    const data = store.getrecord();
    let savedata = { ...data };
    savedata.modifiedBy = store.getuser().id;
    savedata.id = Number(id);
    savedata.access_control = JSON.stringify(savedata.access_control);
    const email = savedata.email;
    if (!email) {
      store.stopLoading();
      ErrorAlert("Enter Email Address");
      return;
    }
    if (savedata.email && savedata.email !== "" && !validateEmail(email)) {
      store.stopLoading();
      ErrorAlert("Invalid email address. Please enter a valid email.");
      return;
    }

    console.log("Submiting data", data);
    if (typeof savedata.activeStatus === "string") {
      savedata.activeStatus = savedata.activeStatus == "true" ? true : false;
    }
    UpdateRecord(updaterecord, savedata).then((res) => {
      if (res.success) {
        store.stopLoading();
        SuccessAlert("User Updated Successfully");
        store.reset(init);
        store.navback();
      } else {
        store.stopLoading();
        ErrorAlert(
          res?.errormessage?.data || " Error Found Please contact the Admin"
        );
      }
    });
  }
  function updatepassword() {
    const data = store.getrecord();
    let result = {
      id: Number(id),
      password: data.password,
    };
    if (CheckMandatory(tablename, store)) {
      UpdateRecord(updatepasswordAPI, result).then((res) => {
        if (res.success) {
          store.stopLoading();
          SuccessAlert("User Password Updated Successfully");
          store.setprops("password", {
            hidden: false,
            readonly: true,
          });
          //  store.navback();
          store.setprops("passwordPopup", { hidden: true });
        }
      });
    }
  }

  function Submit() {
    store.startLoading();
    const data = store.getrecord();
    let savedata = { ...data };
    const email = savedata.email;
    if (savedata.email && !validateEmail(email)) {
      store.stopLoading();
      ErrorAlert("Invalid email address. Please enter a valid email.");
      return;
    }
    savedata.modifiedBy = store.getuser().id;
    savedata.createdBy = store.getuser().id;
    if (typeof savedata.activeStatus === "string") {
      savedata.activeStatus = savedata.activeStatus == "true" ? true : false;
    }
    console.log("Submiting savedata", savedata);

    if (CheckMandatory(tablename, store)) {
      savedata.access_control = JSON.stringify(savedata.access_control);
      saveRecord(setrecord, savedata).then((res) => {
        console.log("Resonce of saveRecord - ", res);
        if (res.success) {
          store.stopLoading();
          SuccessAlert("User Submitted Successfully");
          store.reset(init);
          store.navback();
        } else {
          store.stopLoading();
          ErrorAlert(
            res?.errormessage?.response?.data?.errorMessage ||
              " Error Found Please contact the Admin"
          );
        }
      });
    }
  }

  const handlePermissionChange = (moduleName, permissionType, value) => {
    const updatedModules = store.getvalue("access_control").map((module) => {
      if (module.name === moduleName) {
        return {
          ...module,
          permissions: {
            ...module.permissions,
            [permissionType]: value,
          },
        };
      }
      return module;
    });
    // Now you can store the updated modules back
    // For example:
    store.setvalue("access_control", updatedModules);
  };

  function HandleRole(role) {
    if (role === "1") {
      // ADMIN
      let mod = modules2.map((module) => ({
        ...module,
        permissions: {
          ...module.permissions,
          on_off: true,
          add: true,
          view: true,
          update: true,
          delete: true,
          print: true,
        },
      }));
      store.setvalue("access_control", mod);
    } else if (role === "2") {
      //Editor
      let mod = modules2.map((module) => ({
        ...module,
        permissions:
          module.name === "Expenses"
            ? {
                ...module.permissions,
                on_off: true,
                add: true,
                delete: true,
                view: true,
                update: true,
              }
            : {
                ...module.permissions,
                on_off: true,
                view: true,
                update: true,
              },
      }));
      console.log("access_control", mod);

      store.setvalue("access_control", mod);
    } else if (role === "3") {
      //Viewer
      let mod = modules2.map((module) => {
        console.log("HandleRole module", module);
        if (["documents", "tasks", "dashboard"].includes(module.name)) {
          return {
            ...module,
            permissions: { ...module.permissions, on_off: true, view: true },
          };
        } else if (["users"].includes(module.name)) {
          return {
            ...module,
            permissions: { ...module.permissions, on_off: true, update: true },
          };
        } else if (
          [
            "users",
            "contacts",
            "categories",
            "films",
            "forms",
            "projects",
          ].includes(module.name)
        ) {
          return {
            ...module,
            permissions: { ...module.permissions, on_off: true },
          };
        } else if (module.name === "Expenses") {
          return {
            ...module,
            permissions: {
              ...module.permissions,
              on_off: true,
              add: true,
              delete: true,
              view: true,
              update: true,
            },
          };
        } else {
          return {
            ...module,
          };
        }
      });
      store.setvalue("access_control", mod);
    }
  }

  return (
    <FormView
      actions={[
        {
          Name: "Back",
          onclick: () => {
            store.navback();
            store.reset(init);
          },
          visible: true,
        },
        {
          Name: "Change Password",
          onclick: () => {
            store.setprops("password", {
              hidden: false,
              readonly: false,
              mandatory: true,
            });
            store.setprops("passwordPopup", { hidden: false });
          },
          visible:
            (option === "edit" || option === "profile") &&
            store.getprops("update"),
        },
        {
          Name: "Submit",
          onclick: Submit,
          type: "primary",
          visible: id === undefined,
        },
        {
          Name: "Update",
          onclick: update,
          type: "primary",
          visible:
            (option === "edit" || option === "profile") &&
            store.getprops("update"),
        },
      ]}
    >
      {store.getprops("passwordPopup") &&
        store.getprops("passwordPopup").hidden === false && (
          <div className="absolute top-0 left-0 flex h-full w-full justify-center items-center bg-black bg-opacity-35 z-30 rounded-2xl">
            <div className="bg-secondary p-5 w-[50%] rounded-2xl">
              <String name="password" label="New Password :" />
              <div className="flex justify-end items-center px-[1%] pt-[1%] gap-4">
                <button
                  className="bg-primary text-secondary py-2 px-5 rounded-xl font-bold"
                  onClick={updatepassword}
                >
                  Ok
                </button>
                <button
                  className="bg-tertiary text-primary py-2 px-5 rounded-xl font-bold"
                  onClick={() => {
                    store.setprops("password", {
                      hidden: true,
                      readonly: false,
                    });
                    store.setprops("passwordPopup", { hidden: true });
                  }}
                >
                  Back
                </button>
              </div>
            </div>
          </div>
        )}
      <div className="grid grid-cols-2 gap-3">
        <String name="firstname" label="First Name :" />
        <String name="lastname" label="Last Name :" />
        <String name="username" label="User Name :" />
        <String name="email" label="E-mail :" />
        <String name="password" label="Password :" />
        <Dropdown
          name="role_id"
          label="Role Name:"
          list={[
            { value: "1", label: "Admin" },
            { value: "2", label: "Editor" },
            { value: "3", label: "Viewer" },
          ]}
          onchange={HandleRole}
        />
        <String name="aadhar_no" label="Aadhaar Number" />

        <String name="emergency_contact_no" label="Emergency Contact" />
        <span className="col-span-2">
          <Journal name="address" label="Address" />
        </span>
        <Dropdown
          name="activestatus"
          label="Active Status :"
          list={[
            { value: true, label: "Active" },
            { value: false, label: "InActive" },
          ]}
        />
      </div>
      <div className="module-access-control pt-7 px-3">
        <h2 className="font-bold text-[20px]">User Access Control</h2>
        <table className="w-full table-auto border-collapse border border-gray-300">
          <thead>
            <tr>
              <th style={{ "text-align": "left" }} className="border px-6 py-3">
                Module
              </th>
              {/* <th className="border px-4 py-2">Dashboard</th>
              <th className="border px-4 py-2">Masters</th> */}
              <th className="border px-6 py-3"> On / Off</th>
              <th className="border px-6 py-3">Create</th>
              <th className="border px-6 py-3">View</th>
              <th className="border px-6 py-3">Edit</th>
              <th className="border px-6 py-3">Delete</th>
              <th className="border px-6 py-3">Print</th>
            </tr>
          </thead>
          <tbody>
            {store.getvalue("access_control") &&
              store.getvalue("access_control").map((module) => (
                <tr key={module.name}>
                  <td
                    style={{ "text-align": "left" }}
                    className="border px-6 py-3"
                  >
                    {module.name.toUpperCase()}
                  </td>

                  <td className="border px-4 py-2 text-center">
                    <input
                      type="checkbox"
                      className="checkbox-size"
                      name={`${module.name}_on_off`}
                      checked={module.permissions.on_off}
                      disabled={store.getprops("access_control")?.readonly}
                      onChange={(e) =>
                        handlePermissionChange(
                          module.name,
                          "on_off",
                          e.target.checked
                        )
                      }
                    />
                  </td>
                  <td className="border px-4 py-2 text-center">
                    <input
                      type="checkbox"
                      className="checkbox-size"
                      name={`${module.name}_add`}
                      checked={module.permissions.add}
                      disabled={store.getprops("access_control")?.readonly}
                      onChange={(e) =>
                        handlePermissionChange(
                          module.name,
                          "add",
                          e.target.checked
                        )
                      }
                    />
                  </td>
                  <td className="border px-4 py-2 text-center">
                    <input
                      type="checkbox"
                      className="checkbox-size"
                      name={`${module.name}_view`}
                      checked={module.permissions.view}
                      disabled={store.getprops("access_control")?.readonly}
                      onChange={(e) =>
                        handlePermissionChange(
                          module.name,
                          "view",
                          e.target.checked
                        )
                      }
                    />
                  </td>
                  <td className="border px-4 py-2 text-center">
                    <input
                      type="checkbox"
                      className="checkbox-size"
                      name={`${module.name}_update`}
                      checked={module.permissions.update}
                      disabled={store.getprops("access_control")?.readonly}
                      onChange={(e) =>
                        handlePermissionChange(
                          module.name,
                          "update",
                          e.target.checked
                        )
                      }
                    />
                  </td>
                  <td className="border px-4 py-2 text-center">
                    <input
                      type="checkbox"
                      className="checkbox-size"
                      name={`${module.name}_delete`}
                      checked={module.permissions.delete}
                      disabled={store.getprops("access_control")?.readonly}
                      onChange={(e) =>
                        handlePermissionChange(
                          module.name,
                          "delete",
                          e.target.checked
                        )
                      }
                    />
                  </td>
                  <td className="border px-4 py-2 text-center">
                    <input
                      type="checkbox"
                      className="checkbox-size"
                      name={`${module.name}_print`}
                      checked={module.permissions.print}
                      disabled={store.getprops("access_control")?.readonly}
                      onChange={(e) =>
                        handlePermissionChange(
                          module.name,
                          "print",
                          e.target.checked
                        )
                      }
                    />
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </FormView>
  );
};

export default UserForm;
