import { FaEye } from "react-icons/fa";
import { useStore } from "../StateProvider";
import { useState } from "react";

const Password = ({
  className = "",
  name = "",
  label = "",
  onkeypress = () => {},
}) => {
  const store = useStore();
  const [type, settype] = useState("password");

  let props = store.getprops(name) || {};

  if (!props.hidden) {
    return (
      <div className="flex flex-col py-[4%] max-sm:py-[8%] max-sm:text-[18px] md:text-[14px] lg:text-[18px] gap-4">
        <label className={className === "" ? "" : ""}>{label}</label>
        <div className="relative">
          <input
            className={className === "" ? "" : className + " w-full"}
            id={name}
            type={type}
            value={store.getvalue(name)}
            disabled={props.readonly}
            onChange={(event) => {
              store.setvalue(name, event.target.value);
            }}
            onKeyDown={onkeypress}
          />

          <div
            className="absolute bottom-0 right-0 cursor-pointer z-10 h-fit w-fit  text-[26px]"
            onClick={() => {
              settype((prev) => {
                if (prev === "text") {
                  return "password";
                } else {
                  return "text";
                }
              });
            }}
          >
            <FaEye />
          </div>
        </div>
      </div>
    );
  }
};

export default Password;
