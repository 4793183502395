import { useNavigate, useParams } from "react-router-dom";
import { useStore } from "../../../Components/StateProvider";
import { useEffect, useState } from "react";
import {
  getlocal,
  getStorage,
  setStorage,
} from "../../../functions/localstorage";
import {
  baseurl,
  DeleteRecord,
  ErrorAlert,
  getList,
  GetRecords,
  saveRecord,
  SuccessAlert,
  UploadFile,
} from "../../../functions/api";
import AccountsLayout from "../AccountsLayout";
import FormView from "../../../Components/Elements/FormView";
import {
  MdCancel,
  MdDataSaverOff,
  MdOutlineCancel,
  MdOutlineDataSaverOff,
  MdOutlineFileUpload,
  MdOutlineFreeCancellation,
  MdOutlinePaid,
} from "react-icons/md";
import { FaRegEye } from "react-icons/fa6";
import { HiOutlineArrowSmDown, HiOutlineArrowSmUp } from "react-icons/hi";
import { PrimaryBTN } from "../../../Constancs/cssconst";
import {
  FaArrowRight,
  FaPenNib,
  FaRegCalendarCheck,
  FaTruckLoading,
} from "react-icons/fa";
import { TiTick } from "react-icons/ti";
import { IoCheckmarkDoneCircle } from "react-icons/io5";
import { GiFinishLine } from "react-icons/gi";

import axios from "axios";
import { IoIosCloseCircleOutline } from "react-icons/io";
import {
  URL_Account_Charts_List,
  URL_Account_Customer_List,
  URL_Account_Customer_Project_List,
  URL_Account_Expense_By_ID,
  URL_Account_Expense_Create,
  URL_Account_Expense_List,
  URL_Account_Payable_By_ID,
  URL_Account_Payable_Create,
} from "../../../Constancs/apiconst";

function FormHeader({
  State = "New",
  FormName = "",
  FormNumber = "",
  Actions = [],
}) {
  return (
    <div className="flex items-center justify-between bg-secondary h-[38px] rounded-lg p-1 shadow-[0px_0px_10px_3px_rgba(0,0,0,0.1)] ">
      <div className="flex gap-3 h-full items-center">
        <span className=" text-[14px] border flex items-center px-1 py-0.5 rounded-lg">
          {State}
        </span>
        <span className="text-[14px]  flex flex-col">
          <span className="text-[12px] font-bold text-Ocean_Blue">
            {FormName}
          </span>
          <span className="text-[14px] font-semibold ">{FormNumber}</span>
        </span>
      </div>
      <div className="flex gap-3 h-full items-center mr-8">
        {Actions.map((item, index) => {
          if (item.visible)
            return (
              <button
                key={index}
                className={
                  "border px-2 py-1 rounded-lg font-semibold hover:bg-tertiary " +
                  (item.type === "primary"
                    ? " bg-Ocean_Blue text-secondary "
                    : " bg-Alice_Blue ")
                }
                onClick={item.onClick}
              >
                {item.ButtonName}
              </button>
            );
        })}
      </div>
    </div>
  );
}

function FormStage({ Stages = [] }) {
  return (
    <div className="flex items-center justify-center h-[38px] gap-3">
      {Stages.map((stage, index) => (
        <div key={index} className=" flex gap-1 items-center">
          <span
            className={
              " border p-1 rounded-full text-[14px] " +
              (stage.selected
                ? " bg-Ocean_Blue text-secondary "
                : stage.completed === true
                ? " bg-[#3ec95c] text-secondary "
                : stage.completed === false
                ? " bg-[#c93e3e] text-secondary "
                : " ")
            }
          >
            {stage.completed ? <TiTick /> : stage.icon}
          </span>
          <h3 className="text-[14px] pr-3">{stage.Name}</h3>
          {index < Stages.length - 1 ? <FaArrowRight /> : ""}
        </div>
      ))}
    </div>
  );
}

const PayablesForm = () => {
  const { companyId, option, payableId } = useParams();
  const navigate = useNavigate();
  const getrecord = `/users/users/${payableId}`;
  const attachment_url_Prefix = "Journal_";
  const store = useStore();
  const user = getlocal("system")?.user || {};

  let storage = getStorage();

  // *initial values
  const init = {
    customer_id: 0,
    paid_through_acc_id: 0,
    payable_no: "PAY/24-25/0010",
    payable_date: "",
    proj_id: 0,
    payable_customer_notes: "Notes: ",
    payable_terms_and_conditions: "Terms & Conditions: ",
    payable_total: 0,
    payable_status: "",
    payable_category_id: 2,
    attachment_url: [],
    delivery_address: "",
    items: [
      {
        charts_acc_id: 0,
        amount: 0,
        description: "",
      },
    ],
  };
  const initProps = {
    add: false,
    delete: false,
    on_off: false,
    print: false,
    update: false,
    view: false,

    Stages: {
      Stages_list: [
        {
          Name: "Draft",
          icon: <FaPenNib />,
          selected: true,
        },
        {
          Name: "Completed",
          icon: <GiFinishLine />,
          selected: false,
        },
      ],
    },

    payable_no: {},
    paid_through_acc_id: {
      paid_through_acc_id_list: [{ value: "", label: "Please Choose" }],
    },
    payable_date: {},
    payable_category_id: {},
    payable_category_id: {},

    items: {
      particulars_list: [
        {
          value: "",
          label: "Please Select",
        },
      ],
      MandatoryItems: {},
    },

    payable_status: {},

    payable_total: {},
    payable_customer_notes: {},
    payable_terms_and_conditions: {},
    attachment_url: {},
  };

  // *states declaration for records and props
  const [Record, setRecord] = useState(init);
  const [props, setprops] = useState(initProps);

  // *initial values set on the page load
  // *and change on dependencies of option and id changes
  useEffect(() => {
    store.setmodule("payables", init);
    store.stopLoading();
    const localprops = getlocal("properties")?.payables || {};
    HandleMultiPropsChange(localprops);
    GetExpenseList();
    GetPaidThoughtList();
    // GetProjectList();
    // console.log("Params",param);
    if (payableId !== undefined) {
      if (option === "view") {
        HandleMultiPropsChange({
          paid_through_acc_id: { readonly: true },
        //  proj_id: { readonly: true },
          payable_date: { readonly: true },
          payable_customer_notes: { readonly: true },
          items: { readonly: true },
          payable_terms_and_conditions: { readonly: true },
          // expense_category_id: { readonly: true },
         // items: { readonly: true }
        });
      } else {
        HandleMultiPropsChange({
          paid_through_acc_id: { mandatory: true },
          payable_date: { mandatory: true },
        });
      }

      GetRecords(URL_Account_Payable_By_ID + payableId).then((res) => {
        console.log("expenseId Data - ", res, res.record);
        if (res.success) {
          res = res.record;
          handleMultiFieldChange({
            payable_no: res.payables.payable_no,
            payable_date: res.payables.payable_date,
            payable_customer_notes: res.payables.payable_customer_notes,
            payable_terms_and_conditions:
              res.payables.payable_terms_and_conditions,
            payable_total: res.payables.payable_total,
            payable_status: res.payables.payable_status,
            payable_category_id: res.payables.payable_category_id,
            tds_payable_id: res.payables.tds_payable_id,
            tds_payable_amount: res.payables.tds_payable_amount,
            paid_through_acc_id: res.payables.paid_through_acc_id,
            attachment_url: res.payables.attachment_url || [],
            items: res.items || [],
          });
          HandleStates(res.payables.payable_status);
        }else if (res.code === 401) {
                  ErrorAlert(" Error Found Please contact the Admin");
                } 
        // else if (res.code === 401) {
        //   store.logout();
        //   store.navTo("/login");
        // }
      });
    } else {
      store.setheader("CREATE NEW Company");
      GetRecords("/api/v2/payable/generate-payable-id/"+companyId).then((res) => {
        if (res.success) {
          res = res.record;
          handleFieldChange("payable_no", res);
          console.log("payable_no", res);
        }
      });
      HandleMultiPropsChange({
        paid_through_acc_id: { mandatory: true },
        payable_date: { mandatory: true },
      });
    }
  }, [option, payableId]);

  function GetPaidThoughtList() {
    getList({
      listUrl: URL_Account_Charts_List+companyId,
      field: "group_id",
      search: "27",
      count: 100,
    }).then((res) => {
      if (res.success) {
        res = res.data.list || [];
        res = res.map((item) => ({
          ...item,
          value: item.id,
          label: item.ledger_name,
        }));

        HandlePropsChange("paid_through_acc_id", {
          paid_through_acc_id_list: [
            ...initProps.paid_through_acc_id.paid_through_acc_id_list,
            ...res,
          ],
        });
      }
      console.log("URL_Account_Customer_Project_List", res);
    });
  }
  function GetExpenseList() {
    getList({
      listUrl: URL_Account_Charts_List+companyId,
      field: "group_id",
      search: "28",
      count: 100,
    }).then((res) => {
      if (res.success) {
        res = res.data.list || [];
        res = res.map((item) => ({
          ...item,
          value: item.id,
          label: item.ledger_name,
        }));

        HandlePropsChange("items", {
          particulars_list: [...initProps.items.particulars_list, ...res],
        });
      }
      console.log("URL_Account_Customer_Project_List", res);
    });
  }

  // *print the Record and Props if any value change between the Record and Props
  useEffect(() => {
    console.log("Record State change -> ", Record);
    console.log("Props State change -> ", props);
  }, [Record, props]);

  // *create Or update the Record
  function update() {}
  function Submit() {
    store.startLoading();
    let savedata = { ...Record };
    savedata.modified_by = store.getuser().id;
    savedata.created_by = store.getuser().id;
    savedata.company_id = companyId;
    console.log("Submiting savedata", savedata);

    if (CheckMandatory() && CheckForPayablesRecords()) {
      saveRecord(URL_Account_Payable_Create, savedata).then((res) => {
        console.log("Resonce of saveRecord - ", res);
        if (res.success) {
          store.stopLoading();
          SuccessAlert("Payable Submitted Successfully");
          store.navback();
        } else {
          store.stopLoading();
          ErrorAlert(
            res?.errormessage?.response?.data?.errorMessage ||
              " Error Found Please contact the Admin"
          );
        }
      });
    }
  }
  // *Check for the mandatory fields if empty or not
  function CheckMandatory() {
    let out = true;
    let Fields = "";
    for (let obj in props) {
      if (typeof props[obj] === "object") {
        if (props[obj].mandatory) {
          if (Record[obj] == "" || Record[obj] == " ") {
            HandlePropsChange(obj, { mandatory: true, show: true });
            out = false;
          } else {
            HandlePropsChange(obj, { mandatory: true, show: false });
          }
        }
      }
    }
    out === false && ErrorAlert("Please fill the Mandatory Fields.");
    out === false && store.stopLoading();
    return out;
  }
  // *Check for the Payable records fields if empty or not
  function CheckForPayablesRecords() {
    let Payables = [...Record.items];
    let MandatoryItems = { ...props.items.MandatoryItems };
    let out = undefined;

    Payables.map((Payable, index) => {
      console.log(
        "CheckForPayablesRecords",
        Payable.charts_acc_id != 0 &&
          Payable.amount != "" &&
          Payable.amount != 0,
        Payable.charts_acc_id != 0,
        Payable.amount != "",
        Payable.amount != 0,
        Payable
      );
      if (
        Payable.charts_acc_id != 0 &&
        Payable.amount != "" &&
        Payable.amount != 0
      ) {
        out = out == false ? false : true;
        MandatoryItems = { ...MandatoryItems, [index]: false };
        console.log("CheckForPayablesRecords");
      } else {
        out = false;
        MandatoryItems = { ...MandatoryItems, [index]: true };
        console.log("CheckForPayablesRecords");
      }
      HandlePropsChange("items", {
        ...props.items,
        MandatoryItems: MandatoryItems,
      });
      console.log("CheckForPayablesRecords:MandatoryItems", {
        ...props.items.MandatoryItems,
      });
    });
    store.stopLoading();
    if (out == undefined || out == false)
      ErrorAlert("Please Fill the Payable Details");
    return out;
  }

  // *Handle Fields changes in Record States
  function handleFieldChange(fieldName, value) {
    setRecord((prev) => {
      return { ...prev, [fieldName]: value };
    });
  }
  function handleMultiFieldChange(value = {}) {
    setRecord((prev) => {
      for (let fieldName in value) {
        prev[fieldName] = value[fieldName];
      }
      return { ...prev };
    });
  }
  // *Handle Props Changes in Props States
  function HandlePropsChange(fieldName, value = {}) {
    setprops((prev) => {
      let oldprop = prev[fieldName];

      for (let prophead in value) {
        oldprop[prophead] = value[prophead];
      }
      prev[fieldName] = oldprop;
      return { ...prev };
    });
  }
  function HandleMultiPropsChange(NewProps = {}) {
    setprops((prev) => {
      for (let fieldName in NewProps) {
        let oldprop = prev[fieldName];
        let newprop = NewProps[fieldName];
        console.log("typeof newprop", typeof newprop);

        if (typeof newprop === "object") {
          for (let prophead in newprop) {
            oldprop[prophead] = newprop[prophead];
          }
          prev[fieldName] = oldprop;
        } else {
          prev[fieldName] = newprop;
        }
      }
      console.log("updated props", prev);

      return { ...prev };
    });
  }

  // *Handle Change in the fields
  function HandleAttachement(event) {
    var bodyFormData = new FormData();
    bodyFormData.append("file", event.target.files[0]);
    let attachment_url = Record.attachment_url || [];
    UploadFile(attachment_url_Prefix, bodyFormData).then((res) => {
      if (res.success) {
        handleFieldChange("attachment_url", [...attachment_url, res.data]);
      }
    });
  }

  // calculate total value
  function TotalCalculation(items = Record.items) {
    let subTotal = 0;

    let Total = 0;

    items.map((item) => {
      if (item.amount !== "" && item.amount !== undefined) {
        subTotal += Number(item.amount);
      }
    });

    Total = subTotal;
    handleFieldChange("payable_total", Total.toFixed(2));
  }

  function HandleStates(state = "draft") {
    let Stages_list = [...props.Stages.Stages_list];
    switch (state) {
      case "draft":
        Stages_list[0].selected = false;
        Stages_list[0].completed = true;
        Stages_list[1].selected = true;
        Stages_list[1].completed = false;
        break;
      case "completed":
        Stages_list[0].selected = false;
        Stages_list[0].completed = true;
        Stages_list[1].selected = false;
        Stages_list[1].completed = true;
        break;
      default:
        break;
    }
    HandlePropsChange("Stages", { ...props.Stages, Stages_list });
  }
  return (
    <AccountsLayout HeaderTitle={"Payable"}>
      <div className="flex flex-col h-full text-[12px]">
        <FormHeader
          State="Draft"
          FormName="Expense"
          FormNumber={Record.payable_no}
          Actions={[
            {
              ButtonName: "Submit",
              type: "primary",
              onClick: Submit,
              visible: payableId === undefined,
            },
            {
              ButtonName: "Back",
              onClick: () => {
                navigate(-1);
              },
              visible: true,
            },
          ]}
        />
        <FormStage
          Stages={props.Stages.Stages_list}
          currentStage={Record.stage}
        />
        <div className="flex bg-secondary flex-col items-center flex-grow rounded-lg shadow-[0px_0px_10px_3px_rgba(0,0,0,0.1)] overflow-auto">
          <div className="grid grid-cols-2 gap-2 w-[80%] mt-3">
            <div // paid_through_acc_id
              className={
                "flex text-[14px] w-full h-full px-3 flex-row items-center gap-3 " +
                (props.paid_through_acc_id.hidden ? " hidden " : " flex ")
              }
            >
              <label className={"w-fit  min-w-[150px] max-w-full"}>
                Paid Through
                {props.paid_through_acc_id.mandatory && (
                  <span className="text-[#ff2828] text-[14px] h-fit">*</span>
                )}
              </label>
              <select
                id={"paid_through_acc_id"}
                className={
                  " w-full border-b border-Old_Silver border-opacity-35 px-2 py-1 text-Old_Silver disabled:bg-[#c6c6ca] disabled:bg-opacity-20   outline-none " +
                  (props.paid_through_acc_id.mandatory &&
                  props.paid_through_acc_id.show
                    ? " rounded-2xl border-2 border-[#ff2828] "
                    : "")
                }
                onChange={(event) => {
                  handleFieldChange("paid_through_acc_id", event.target.value);
                  TotalCalculation();
                }}
                value={Record.paid_through_acc_id}
                disabled={props.paid_through_acc_id.readonly}
                aria-placeholder="please select sam"
              >
                {props.paid_through_acc_id.paid_through_acc_id_list.map(
                  (item, index) => (
                    <option key={index} value={item.value}>
                      {item.label}
                    </option>
                  )
                )}
              </select>
            </div>

            <div // payable_date
              className={
                "flex text-[14px] w-full h-full px-3 flex-row items-center gap-3 " +
                (props.payable_date.hidden ? " hidden " : " flex ")
              }
            >
              <label className={"w-fit  min-w-[150px] max-w-full"}>
                Date
                {props.payable_date.mandatory && (
                  <span className="text-[#ff2828] text-[14px] h-fit">*</span>
                )}
              </label>
              <input
                className={
                  "w-full border-b border-Old_Silver border-opacity-35 font-medium disabled:bg-[#c6c6ca] text-Old_Silver disabled:bg-opacity-20 px-2 py-1 " +
                  (props.payable_date.mandatory && props.payable_date.show
                    ? " rounded-2xl border-2 border-[#ff2828] "
                    : "")
                }
                id={"payable_date"}
                type="date"
                value={Record.payable_date.replace("T00:00:00Z", "")}
                disabled={props.payable_date.readonly}
                onChange={(event) => {
                  handleFieldChange(
                    "payable_date",
                    event.target.value + "T00:00:00Z"
                  );
                }}
              />
            </div>
          </div>
          <div // Transaction
            className={
              " text-[14px] w-[80%] px-1 mt-2 flex-col items-center gap-1 flex py-2 relative "
            }
          >
            <div className="flex justify-around w-full gap-2 border-y border-Old_Silver border-opacity-35">
              <div className="text-[14px] w-full px-2 py-1">Payable</div>
              <div className="text-[14px] w-full px-2 py-1">Note</div>
              <div className="text-[14px] w-full px-2 py-1">Amount</div>
              <span className=" mr-4 rounded-lg  cursor-pointer"></span>
            </div>
            {Record.items.map((item, index) => (
              <div
                className={
                  " flex justify-around w-full gap-2 border-opacity-35 " +
                  (props?.items?.MandatoryItems[index]
                    ? " border-2 border-[#ff2828] rounded-lg"
                    : " border-b border-Old_Silver ")
                }
                key={index}
              >
                <select // items.charts_acc_id
                  id={"itemsexpenses"}
                  className={
                    " w-full border-Old_Silver px-2 py-1 text-Old_Silver disabled:bg-[#c6c6ca] disabled:bg-opacity-35 outline-none " +
                    (props.items.mandatory && props.items.show
                      ? " rounded-2xl border-2 border-[#ff2828] "
                      : "")
                  }
                  onChange={(event) => {
                    let items = Record.items;
                    items[index].charts_acc_id = event.target.value;
                    handleFieldChange("items", items);
                    // HandleParticulars(event.target.value, index);
                  }}
                  value={item.charts_acc_id}
                  disabled={props.items.readonly}
                >
                  {props.items.particulars_list.map((item, index) => (
                    <option key={index} value={item.value}>
                      {item.label}
                    </option>
                  ))}
                </select>
                <input // items.description
                  className={
                    "w-full border-Old_Silver px-2 py-1 text-Old_Silver disabled:bg-[#c6c6ca] disabled:bg-opacity-20   outline-none " +
                    (props.items.mandatory && props.items.show
                      ? " rounded-2xl border-2 border-[#ff2828] "
                      : "")
                  }
                  id={"itemsdescription"}
                  type="text"
                  value={item.description}
                  disabled={props.items.readonly}
                  onChange={(event) => {
                    let items = Record.items;
                    items[index].description = event.target.value;
                    handleFieldChange("items", items);
                    TotalCalculation();
                  }}
                />
                <input // items.Amount
                  className={
                    "w-full border-Old_Silver px-2 py-1 text-Old_Silver disabled:bg-[#c6c6ca] disabled:bg-opacity-20   outline-none " +
                    (props.items.mandatory && props.items.show
                      ? " rounded-2xl border-2 border-[#ff2828] "
                      : "")
                  }
                  id={"itemsAmount"}
                  type="number"
                  value={item.amount}
                  disabled={props.items.readonly}
                  onChange={(event) => {
                    let items = Record.items;
                    items[index].amount = event.target.value;
                    handleFieldChange("items", items);
                    TotalCalculation();
                  }}
                />
                 {option != "view" && (
                <span
                  onClick={() => {
                    let items = Record.items;
                    items = items.filter(
                      (itemsitem, itemsindex) => itemsindex != index
                    );
                    handleFieldChange("items", items);
                    TotalCalculation(items);
                  }}
                  className="border h-fit w-fit p-1 rounded-lg hover:bg-tertiary cursor-pointer"
                >
                  X
                </span>)}
              </div>
            ))}
             {option != "view" && (
            <div className="flex px-2 pb-2 w-full gap-2 border-b border-Old_Silver border-opacity-35">
              <button
                className={"text-Ocean_Blue hover:drop-shadow-2xl"}
                onClick={() => {
                  let items = Record.items;
                  items.push({
                    charts_acc_id: "",
                    description: "",
                    amount: "",
                  });
                  console.log("let items", items);

                  handleFieldChange("items", items);
                }}
              >
                Add New Line
              </button>
            </div>)}
          </div>

          <div className="grid grid-cols-2 gap-2 w-[80%] mt-1 mb-5">
            <div // attachment_urls
              className={"flex flex-col px-4 col-span-2 "}
            >
              <h3 className="text-[14px]">Attachments</h3>
              <div // attachment_url
                className={
                  "flex text-[14px] w-full h-full px-3 flex-row items-center gap-3 " +
                  (props.attachment_url.hidden ? " hidden " : " flex ")
                }
              > {option != "view" && (
                <button
                  className="border px-2 py-1 rounded-lg flex gap-1 items-center"
                  onClick={() => {
                    document.getElementById("FileInput").click();
                  }}
                >
                  <MdOutlineFileUpload className="text-[#20b25b] text-[18px] font-bold" />
                  Upload File
                </button>)}
                {Record.attachment_url.map((attached, index) => (
                  <button
                    key={index}
                    className="cursor-pointer hover:text-[#3f4fff] hover:bg-[#2a3cff] hover:bg-opacity-15 px-2 py-1 rounded-lg flex items-center gap-1 group transition-all duration-100"
                    target="_blank"
                  >
                    <FaRegEye
                      className="group-hover:block hidden transition-all duration-75 hover:bg-[#5ae271] rounded-full"
                      onClick={() => {
                        store.navtotab(
                          baseurl + "/files/preview?key=" + attached
                        );
                      }}
                    />
                    {attached.replaceAll("Journal_/", "")}
                    <IoIosCloseCircleOutline
                      className="group-hover:block hidden transition-all duration-75 z-50 hover:bg-red  rounded-full"
                      onClick={() => {
                        let attachment_url = Record.attachment_url || [];
                        DeleteRecord("/files/deletefiles?key=" + attached).then(
                          (res) => {
                            if (res.success) {
                              attachment_url = attachment_url.filter(
                                (item, itemindex) => itemindex != index
                              );
                              handleFieldChange("attachment_url", [
                                ...attachment_url,
                              ]);
                              SuccessAlert("Attachment Removed");
                            } else {
                              ErrorAlert("Unable to delete the Attachment");
                            }
                          }
                        );
                      }}
                    />
                  </button>
                ))}
                <input
                  type="file"
                  onChange={HandleAttachement}
                  id="FileInput"
                  className="hidden"
                />
              </div>
            </div>
            <div // payable_customer_notes
              className={
                "flex text-[14px] w-full px-3 mt-4 flex-col items-start gap-1  " +
                (props.payable_customer_notes.hidden ? " hidden " : " flex ")
              }
            >
              <textarea
                className={
                  "w-full border-b border-Old_Silver border-opacity-35 px-2 py-1 text-Old_Silver disabled:bg-[#c6c6ca] disabled:bg-opacity-20 outline-none " +
                  (props.payable_customer_notes.mandatory &&
                  props.payable_customer_notes.show
                    ? " rounded-2xl border-2 border-[#ff2828] "
                    : "")
                }
                id={"payable_customer_notes"}
                value={Record.payable_customer_notes}
                disabled={props.payable_customer_notes.readonly}
                onChange={(event) => {
                  if (event.target.value.length > 7) {
                    handleFieldChange(
                      "payable_customer_notes",
                      event.target.value
                    );
                  } else {
                    handleFieldChange("payable_customer_notes", "Notes: ");
                  }
                }}
              />
            </div>
            <div // payable_total
              className={"flex items-center justify-between px-4 "}
            >
              <div className="w-[30%] text-[14px] text-Old_Silver flex-grow ">
                {"Total (₹)"}
              </div>
              <div className=" w-[70%] text-[16px] font-semibold px-2 py-1 border-Old_Silver ">
                {Record.payable_total}
              </div>
            </div>
            <div // payable_terms_and_conditions
              className={
                "flex text-[14px] w-full px-3 mt-4 flex-col items-start gap-1  " +
                (props.payable_terms_and_conditions.hidden
                  ? " hidden "
                  : " flex ")
              }
            >
              <textarea
                className={
                  "w-full border-b border-Old_Silver border-opacity-35 px-2 py-1 text-Old_Silver disabled:bg-[#c6c6ca] disabled:bg-opacity-20 outline-none " +
                  (props.payable_terms_and_conditions.mandatory &&
                  props.payable_terms_and_conditions.show
                    ? " rounded-2xl border-2 border-[#ff2828] "
                    : "")
                }
                id={"payable_terms_and_conditions"}
                value={Record.payable_terms_and_conditions}
                disabled={props.payable_terms_and_conditions.readonly}
                onChange={(event) => {
                  if (event.target.value.length > 20) {
                    handleFieldChange(
                      "payable_terms_and_conditions",
                      event.target.value
                    );
                  } else {
                    handleFieldChange(
                      "payable_terms_and_conditions",
                      "Terms & Conditions: "
                    );
                  }
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </AccountsLayout>
  );
};

export default PayablesForm;


