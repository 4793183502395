import React, { useEffect, useRef, useState } from "react";
import Dropdown from "./Dropdown";
import { BiLeftArrow, BiRightArrow } from "react-icons/bi";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { useStore } from "../StateProvider";
const Calender = ({ name = "", onchange = () => {} }) => {
  const store = useStore();
  const months = [
    "JAN",
    "FEB",
    "MAR",
    "APR",
    "MAY",
    "JUN",
    "JUL",
    "AUG",
    "SEP",
    "OCT",
    "NOV",
    "DEC",
  ];
  const [date, setdate] = useState(() => {
    const currentdate = new Date();
    return {
      Dates: currentdate,
      year: currentdate.getFullYear(),
      month: currentdate.getMonth() + 1,
      Stringmonth: months[currentdate.getMonth()],
      date: currentdate.getDate(),
      monthyear: false,
    };
  });
  const [dates, setdates] = useState([]);

  const monthref = useRef([]);
  const yearref = useRef([]);

  useEffect(() => {
    console.log("Date responce - ", date);
    if (date.monthyear) {
      monthref.current[date.month - 1]?.scrollIntoView({
        behavior: "smooth", // Optional: Adds smooth scrolling animation
        block: "start", // Align the card to the top of the scroll view
      });
      yearref.current[date.year - 1990]?.scrollIntoView({
        behavior: "smooth", // Optional: Adds smooth scrolling animation
        block: "start", // Align the card to the top of the scroll view
      });
    }
  }, [date]);

  useEffect(() => {
    const currentdate = new Date();
    changeDates(currentdate.getFullYear(), currentdate.getMonth());
  }, []);

  function changeDates(year, month) {
    // Get the first day of the month
    let dayone = new Date(year, month, 1).getDay();

    // Get the last date of the month
    let lastdate = new Date(year, month + 1, 0).getDate();

    // Get the day of the last date of the month
    let dayend = new Date(year, month, lastdate).getDay();

    // Get the last date of the previous month
    let monthlastdate = new Date(year, month, 0).getDate();

    let changedate = [];

    // Loop to add the last dates of the previous month
    for (let i = dayone; i > 0; i--) {
      changedate.push(["notcurrent", monthlastdate - i + 1]);
    }

    // Loop to add the dates of the current month
    for (let i = 1; i <= lastdate; i++) {
      changedate.push(["current", i]);
    }

    // Loop to add the first dates of the next month
    for (let i = dayend; i < 6; i++) {
      changedate.push(["notcurrent", i - dayend + 1]);
    }

    setdates((prev) => {
      prev = changedate;
      return [...prev];
    });
    console.log(
      "Dates responce date - ",
      dayone,
      lastdate,
      dayend,
      monthlastdate
    );
  }
  return (
    <div className="h-[85%] grid grid-rows-8 relative">
      <div className="row-span-1 w-full h-full">
        <div className="flex justify-between items-center h-full mx-5">
          <div>
            <FaChevronLeft
              className="cursor-pointer"
              onClick={() => {
                const changedate = new Date(
                  date.Dates.setMonth(date.month - 2)
                );
                changeDates(changedate.getFullYear(), changedate.getMonth());
                setdate((prev) => {
                  prev.Dates = changedate;
                  prev.month = changedate.getMonth() + 1;
                  prev.Stringmonth = months[changedate.getMonth()];
                  prev.year = changedate.getFullYear();
                  return { ...prev };
                });
              }}
            />
          </div>
          <div
            className="font-bold cursor-pointer"
            onClick={() => {
              setdate((prev) => {
                prev.monthyear = true;
                return { ...prev };
              });
            }}
          >
            {date.Stringmonth + " " + date.year}
          </div>
          <div>
            <FaChevronRight
              className="cursor-pointer"
              onClick={() => {
                const changedate = new Date(date.Dates.setMonth(date.month));
                changeDates(changedate.getFullYear(), changedate.getMonth());
                setdate((prev) => {
                  prev.Dates = changedate;
                  prev.month = changedate.getMonth() + 1;
                  prev.Stringmonth = months[changedate.getMonth()];
                  prev.year = changedate.getFullYear();
                  return { ...prev };
                });
              }}
            />
          </div>
        </div>
      </div>
      <div className="row-span-1  w-full h-[10px] grid grid-cols-7 justify-items-center items-center font-bold">
        <h1>S</h1>
        <h1>M</h1>
        <h1>T</h1>
        <h1>W</h1>
        <h1>T</h1>
        <h1>F</h1>
        <h1>S</h1>
      </div>
      <div className="row-span-6 w-full h-full grid grid-cols-7 justify-items-center items-center">
        {dates.map(([type, item]) => (
          <h1
            className={
              "p-1 h-8 w-8 rounded-full flex justify-center items-center cursor-pointer " +
              (type === "current"
                ? store.getvalue(name) ===
                  date.year +
                    "-" +
                    (date.month.toString().length > 1
                      ? date.month
                      : "0" + date.month) +
                    "-" +
                    (item.toString().length > 1 ? item : "0" + item)
                  ? " bg-primary text-secondary "
                  : new Date().getFullYear() +
                      "-" +
                      ((new Date().getMonth() + 1).toString().length > 1
                        ? new Date().getMonth() + 1
                        : "0" + (new Date().getMonth() + 1)) +
                      "-" +
                      (new Date().getDate().toString().length > 1
                        ? new Date().getDate()
                        : "0" + new Date().getDate()) ==
                    date.year +
                      "-" +
                      (date.month.toString().length > 1
                        ? date.month
                        : "0" + date.month) +
                      "-" +
                      (item.toString().length > 1 ? item : "0" + item)
                  ? " bg-tertiary "
                  : " hover:bg-[#a9a9ff] hover:bg-opacity-45 "
                : "text-[#b7b4b4]")
            }
            onClick={() => {
              let month = "";
              let selecteddate = "";
              console.log(
                "Time -",
                new Date().getFullYear() +
                  "-" +
                  ((new Date().getMonth() + 1).toString().length > 1
                    ? new Date().getMonth() + 1
                    : "0" + (new Date().getMonth() + 1)) +
                  "-" +
                  (new Date().getDate().toString().length > 1
                    ? new Date().getDate()
                    : "0" + new Date().getDate()),
                new Date().getMonth(),
                date.year +
                  "-" +
                  (date.month.toString().length > 1
                    ? date.month
                    : "0" + date.month) +
                  "-" +
                  (item.toString().length > 1 ? item : "0" + item)
              );

              if (item.toString().length > 1) {
                selecteddate = item;
              } else {
                selecteddate = "0" + item;
              }
              if (date.month.toString().length > 1) {
                month = date.month;
              } else {
                month = "0" + date.month;
              }

              if (type === "current") {
                console.log(
                  "stored date time ",
                  store.getvalue(name),
                  date.year + "-" + date.month + "-" + item,
                  store.getvalue(name) ===
                    date.year + "-" + date.month + "-" + item
                );
                store.setvalue(
                  name,
                  date.year + "-" + month + "-" + selecteddate
                );
                onchange(date.year + "-" + month + "-" + selecteddate);
              }
            }}
          >
            {item}
          </h1>
        ))}
      </div>
      <div
        className={
          "bg-Alice_Blue h-full rounded-2xl shadow-inner shadow-primary w-full absolute top-0 left-0 p-3" +
          (date.monthyear ? " flex " : " hidden ")
        }
      >
        <div className="h-full  w-full overflow-x-auto overflow-y-auto gap-2 flex flex-col">
          {Array.from(Array(111).keys()).map((item, index) => (
            <div
              className={
                "flex justify-center items-center rounded-xl mx-3 cursor-pointer " +
                (date.year === 1990 + index
                  ? " bg-primary text-secondary "
                  : "bg-secondary text-primary")
              }
              key={index}
              ref={(el) => (yearref.current[index] = el)}
              onClick={() => {
                setdate((prev) => {
                  prev.year = 1990 + index;
                  return { ...prev };
                });
              }}
            >
              {1990 + index}
            </div>
          ))}
        </div>
        <div className="h-full  w-full overflow-x-auto overflow-y-auto gap-2 flex flex-col">
          {months.map((item, index) => (
            <div
              className={
                "flex justify-center items-center rounded-xl mx-3 cursor-pointer " +
                (date.Stringmonth === item
                  ? " bg-primary text-secondary "
                  : "bg-secondary text-primary")
              }
              key={index}
              ref={(el) => (monthref.current[index] = el)}
              onClick={() => {
                setdate((prev) => {
                  prev.month = index + 1;
                  prev.Stringmonth = item;
                  prev.monthyear = false;
                  return { ...prev };
                });
                changeDates(date.year, index);
              }}
            >
              {item}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Calender;
