// export const host = "localhost";
const host = "192.168.1.44";
export const BASEURL = `http://${host}:8000`;

// *Account Company Endpoints
export const URL_Account_Company_List = "/api/v2/accountingcompany/getcompany";
export const URL_Account_Company_By_ID =
  "/api/v2/accountingcompany/getcompany/";
export const URL_Account_Company_Create =
  "/api/v2/accountingcompany/addnewcompany";
export const URL_Account_Company_Update =
  "/api/v2/accountingcompany/updatecompany/";
export const URL_Account_Company_Delete =
  "/api/v2/accountingcompany/deletecompany/";

// *Accounts Invoices
export const URL_Account_TrailBalance = "/api/v2/accounting/trialbalance/";
export const URL_Account_BalanceSheet = "/api/v2/accounting/balancesheet/";
export const URL_Account_GeneralLedger = "/api/v2/accounting/ledger/";
export const URL_Account_ProfitLoss = "/api/v2/accounting/profitandloss/";
export const URL_Account_TDSReport = "/api/v2/accounting/tdsreport/";

// *Accounts Groups
export const URL_Account_Group_List = `/api/v2/accounting/getallaccountcore`;
export const URL_Account_Group_By_ID = `/api/v2/accounting/accountcorebyid/`;
export const URL_Account_Group_Create = `/api/v2/accounting/addaccountcore`;
export const URL_Account_Group_Update = `/api/v2/accounting/updateaccountcore/`;
export const URL_Account_Group_Delete = `/api/v2/accounting/deleteaccountcore/`;

// *Accounts Charts
export const URL_Account_Charts_List =
  "/api/v2/ChartsOfAccounts/getallchartsofaccounts/";
export const URL_Account_Charts_By_ID =
  "/api/v2/ChartsOfAccounts/getchartsofaccounts/";
export const URL_Account_Charts_Create =
  "/api/v2/ChartsOfAccounts/addchartsofaccounts";
export const URL_Account_Charts_Update =
  "/api/v2/ChartsOfAccounts/updatechartsofaccounts/";
export const URL_Account_Charts_Delete =
  "/api/v2/ChartsOfAccounts/deletechartsofaccounts/";

// *Accounts Customer
export const URL_Account_Customer_List = "/api/v2/Customer/getclients";
export const URL_Account_Customer_By_ID = "/api/v2/Customer/getclient/";
export const URL_Account_Customer_Create =
  "/api/v2/Customer/addclientwithaccounts";
export const URL_Account_Customer_Update =
  "/api/v2/Customer/updateclientwithaccounts/";
export const URL_Account_Customer_Project_List =
  "/api/v2/Customer/getclientprojects/";
export const URL_Account_Customer_Delete = "/api/v2/Customer/deleteclient/";

// *Accounts Expenses
export const URL_Account_Expense_List = "/api/v2/expense/getallexpense/";
export const URL_Account_Expense_By_ID = "/api/v2/expense/getexpensebyid/";
export const URL_Account_Expense_Create = "/api/v2/expense/addexpense";
export const URL_Account_Expense_Update = "/api/v2/expense/updateexpense?id=";
export const URL_Account_Expense_ListBy_ProjectId =
  "/api/v2/expense/getprojectsexpense/";
export const URL_Account_Expense_UpdateStatus =
  "api/v2/expense/expensestatusupdate?id=";
export const URL_Account_Expense_Delete = "/api/v2/expense/deleteexpense/";
export const URL_Account_Expense_AwaitingApproval =
  "/api/v2/expense/awaitingapproval/";
export const URL_Account_Expense_Approval = "/api/v2/expense/approve/";
export const URL_Account_Expense_paid = "/api/v2/expense/paid/";
export const URL_Account_Expense_Reject = "/api/v2/expense/reject/";

// *Accounts Invoices
export const URL_Account_Invoice_List = "/api/v2/invoice/getallinvoices/";
export const URL_Account_Invoice_By_ID = "/api/v2/invoice/getinvoicebyid/";
export const URL_Account_Invoice_PDF_By_ID = "/api/v2/invoice/invoices/pdf/";
export const URL_Account_Invoice_Create = "/api/v2/invoice/invoices";
export const URL_Account_Invoice_Update = "/api/v2/invoice/updateinvoice/";
export const URL_Account_Invoice_UpdateStatus =
  "/api/v2/invoice/invoicestatusupdate?id=";
export const URL_Account_Invoice_Delete = "/api/v2/invoice/deleteinvoice/";

// *Accounts Journal
export const URL_Account_Journal_List = "/api/v2/journalentry/getalljournals/";
export const URL_Account_Journal_By_ID = "/api/v2/journalentry/journals/";
export const URL_Account_Journal_Create = "/api/v2/journalentry/journalentry";
export const URL_Account_Journal_UpdateApprove =
  "/api/v2/journalentry/journalapproveupdate?id=";
export const URL_Account_Journal_UpdateStatus =
  "api/v2/journalentry/journalstatusupdate?id=";
export const URL_Account_Journal_Delete = "/api/v2/journalentry/deletejournal/";

// *Accounts Payable
export const URL_Account_Payable_List = "/api/v2/payable/getallpayables/";
export const URL_Account_Payable_By_ID = "/api/v2/payable/getpayablebyid/";
export const URL_Account_Payable_Create = "/api/v2/payable/addpayables";
export const URL_Account_Payable_Update = "/api/v2/payable/updatepayable?id=";
export const URL_Account_Payable_UpdateStatus =
  "/api/v2/payable/payablestatusupdate?id=";
export const URL_Account_Payable_Delete = "/api/v2/payable/deletepayable/";

// *Accounts Receipt
export const URL_Account_Receipt_List =
  "/api/v2/paymentreceipt/getallpaymentreceipt/";
export const URL_Account_Receipt_By_ID =
  "/api/v2/paymentreceipt/getpaymentreceiptbyid/";
export const URL_Account_Receipt_Create =
  "/api/v2/paymentreceipt/paymentreceipt";
export const URL_Account_Receipt_Update =
  "api/v2/paymentreceipt/updatepaymentreceipt?id=";
export const URL_Account_Receipt_Delete =
  "/api/v2/paymentreceipt/deletepayment/";
