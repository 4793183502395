import { useStore } from "../StateProvider";

const DatePicker = ({
  className = { field: "", lable: "", input: "" },
  name = "",
  label = "",
  doubleline = false,
  readonly = false,
  onchange = () => {},
}) => {
  const store = useStore();
  let props = store.getprops(name) || {};

  if (!props.hidden) {
    return (
      <div
        className={
          (className.field === ""
            ? "flex text-[14px] w-full h-full px-3 "
            : className.field) +
          (doubleline ? "flex-col gap-1" : "flex-row items-center gap-3")
        }
      >
        <label
          className={
            className.lable === ""
              ? "w-fit  min-w-[150px] max-w-full"
              : className.lable
          }
          onClick={() => {
            document.getElementById(name).focus();
          }}
        >
          {label}
          {props.mandatory && (
            <span className="text-[#ff2828] text-[14px] h-fit">*</span>
          )}
        </label>
        <input
          className={
            (className.input === ""
              ? "w-full border rounded-lg font-medium disabled:bg-[#c6c6ca] disabled:text-[#4d4d4e] "
              : className.input) +
            (!doubleline ? " p-2 " : " px-2 py-1") +
            (props.mandatory && props.show
              ? " rounded-2xl border-2 border-[#ff2828] "
              : "")
          }
          id={name}
          type="date"
          value={store.getvalue(name)?.split("T")[0]}
          disabled={props.readonly}
          onChange={(event) => {
            store.setvalue(name, event.target.value + "T00:00:00Z");
            onchange(event.target.value);
          }}
        />
      </div>
    );
  }
};

export default DatePicker;
