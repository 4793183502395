import "./App.css";
import Layout from "./Components/Layout/Layout";
import { BrowserRouter as Router } from "react-router-dom";
import { StoreProvider } from "./Components/StateProvider";
import PageRouter from "./Components/Layout/PageRouter";
import { ToastContainer } from "react-toastify";

function App() {
  document.title = "Absolute Legal Law Firm"
  return (
    <Router>
      <ToastContainer
        position="top-left"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        style={{
          color: "#000",
        }}
      />
      <StoreProvider>
        <PageRouter />
      </StoreProvider>
    </Router>
  );
}

export default App;
