import { useNavigate, useParams } from "react-router-dom";
import { useStore } from "../../Components/StateProvider";
import { useEffect, useState } from "react";
import Select from "react-select";
import { getlocal, getStorage, setStorage } from "../../functions/localstorage";
import {
  baseurl,
  ErrorAlert,
  getList,
  GetRecords,
  saveRecord,
  SuccessAlert,
  UpdateRecord,
  UploadFile,
  WarningAlert,
} from "../../functions/api";
import AccountsLayout from "../Accounts/AccountsLayout";
import FormView from "../../Components/Elements/FormView";
import { MdEdit, MdOutlineFileUpload } from "react-icons/md";
import { FaRegEye } from "react-icons/fa6";
import { HiOutlineArrowSmDown, HiOutlineArrowSmUp } from "react-icons/hi";
import { PrimaryBTN } from "../../Constancs/cssconst";
import { FaArrowRight, FaEdit, FaPenNib, FaTruckLoading } from "react-icons/fa";
import { TiTick } from "react-icons/ti";
import {
  URL_Account_Charts_List,
  URL_Account_Client_Create,
  URL_Account_Client_Update,
  URL_Account_Customer_By_ID,
  URL_Account_Customer_Create,
  URL_Account_Customer_Update,
} from "../../Constancs/apiconst";

function FormHeader({
  State = "New",
  FormName = "",
  FormNumber = "",
  Actions = [],
}) {
  return (
    <div className="flex items-center justify-between bg-secondary h-[38px] rounded-lg p-1 shadow-[0px_0px_10px_3px_rgba(0,0,0,0.1)] ">
      <div className="flex gap-3 h-full items-center">
        <span className=" text-[14px] border flex items-center px-1 py-0.5 rounded-lg">
          {State}
        </span>
        <span className="text-[14px]  flex flex-col">
          <span className="text-[12px] font-bold text-Ocean_Blue">
            {FormName}
          </span>
          <span className="text-[14px] font-semibold ">{FormNumber}</span>
        </span>
      </div>
      <div className="flex gap-3 h-full items-center mr-8">
        {Actions.map((item, index) => {
          if (item.visible)
            return (
              <button
                key={index}
                className={
                  "border px-2 py-1 rounded-lg font-semibold hover:bg-tertiary " +
                  (item.type === "primary"
                    ? " bg-Ocean_Blue text-secondary "
                    : " bg-Alice_Blue ")
                }
                onClick={item.onClick}
              >
                {item.ButtonName}
              </button>
            );
        })}
      </div>
    </div>
  );
}

function FormStage({ Stages = [] }) {
  return (
    <div className="flex items-center justify-center h-[38px] gap-3">
      {Stages.map((stage, index) => (
        <div key={index} className=" flex gap-1 items-center">
          <span
            className={
              " border p-1 rounded-full text-[14px] " +
              (stage.selected
                ? " bg-Ocean_Blue text-secondary "
                : stage.completed
                ? " bg-[#3ec95c] text-secondary "
                : " ")
            }
          >
            {stage.completed ? <TiTick /> : stage.icon}
          </span>
          <h3 className="text-[14px] pr-3">{stage.Name}</h3>
          {index < Stages.length - 1 ? <FaArrowRight /> : ""}
        </div>
      ))}
    </div>
  );
}

function TabsView({ tabsList = [], className = "" }) {
  const [tabs, settabs] = useState(0);
  return (
    <ul
      className={
        className +
        " flex flex-wrap text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:border-gray-700 dark:text-gray-400"
      }
    >
      <li className="me-2">
        {tabsList.map((tab, tabId) => (
          <button
            key={tabId}
            className={
              " p-2 text-primary  rounded-t-lg hover:bg-tertiary hover:text-primary " +
              (tabId === tabs
                ? " bg-Ocean_Blue text-secondary "
                : " bg-Alice_Blue ")
            }
            onClick={() => {
              tab.onClick && tab.onClick();
              settabs(tabId);
            }}
          >
            {tab.name}
          </button>
        ))}
      </li>
    </ul>
  );
}

const CustomerForm = () => {
  const { option, id } = useParams();
  const navigate = useNavigate();
  // const getrecord = `/users/users/${customerId}`;
  const attachment_Prefix = "Journal_";
  const store = useStore();
  let storage = getStorage();
  const [selectedOptions, setSelectedOptions] = useState([]);

  // *initial values
  const init = {
    clientname: "",
    phoneno: "",
    email: "",
    company_id: "",
    salaes_ledger_id: 0,
    receivable_ledger_id: 0,
    payable_ledger_id: 0,
    account_no: "",
    account_holder_name: "",
    bank_name: "",
    branch: "",
    ifsc_code: "",
    bank_address: "",
    address: [],
  };
  const initProps = {
    add: false,
    delete: false,
    on_off: false,
    print: false,
    update: false,
    view: false,

    clientname: {},
    phoneno: {},
    email: {},
    company_id: {
      company_id_list: [{ label: "Please Choose", value: "" }],
    },

    account_no: {},
    account_holder_name: {},
    bank_name: {},
    branch: {},
    ifsc_code: {},
    bank_address: {},
    address: {
      state_list: [
        { value: "", label: "Please choose" },
        { value: "Andhra Pradesh", label: "Andhra Pradesh" },
        { value: "Arunachal Pradesh", label: "Arunachal Pradesh" },
        { value: "Assam", label: "Assam" },
        { value: "Bihar", label: "Bihar" },
        { value: "Chhattisgarh", label: "Chhattisgarh" },
        { value: "Goa", label: "Goa" },
        { value: "Gujarat", label: "Gujarat" },
        { value: "Haryana", label: "Haryana" },
        { value: "Himachal Pradesh", label: "Himachal Pradesh" },
        { value: "Jharkhand", label: "Jharkhand" },
        { value: "Karnataka", label: "Karnataka" },
        { value: "Kerala", label: "Kerala" },
        { value: "Madhya Pradesh", label: "Madhya Pradesh" },
        { value: "Maharashtra", label: "Maharashtra" },
        { value: "Manipur", label: "Manipur" },
        { value: "Meghalaya", label: "Meghalaya" },
        { value: "Mizoram", label: "Mizoram" },
        { value: "Nagaland", label: "Nagaland" },
        { value: "Odisha", label: "Odisha" },
        { value: "Punjab", label: "Punjab" },
        { value: "Rajasthan", label: "Rajasthan" },
        { value: "Sikkim", label: "Sikkim" },
        { value: "Tamil Nadu", label: "Tamil Nadu" },
        { value: "Telangana", label: "Telangana" },
        { value: "Tripura", label: "Tripura" },
        { value: "Uttar Pradesh", label: "Uttar Pradesh" },
      ],
      countries_list: [
        { value: "", label: "Please choose" },
        { value: "India", label: "India" },
      ],
      addressmandatory: [],
    },
  };

  // *states declaration for records and props
  const [Record, setRecord] = useState(init);
  const [props, setprops] = useState(initProps);

  // *initial values set on the page load
  // *and change on dependencies of option and id changes
  useEffect(() => {
    const localprops = getlocal("properties")?.customers || {};
    store.setmodule("customer", init);
    store.stopLoading();

    HandleMultiPropsChange(localprops);
    GetCompanyList();
    getPayableList();
    getReceivableList();
    getSalesList();
    HandleMultiPropsChange({
      clientname: { mandatory: true },
      company_id: { mandatory: true },
      email: { mandatory: true },
      address: { hidden: false },
      bank_name: { hidden: true },
      account_holder_name: { hidden: true },
      account_no: { hidden: true },
      ifsc_code: { hidden: true },
      branch: { hidden: true },
      bank_address: { hidden: true },
    });
    if (option === "view") {
      HandleMultiPropsChange({
        clientname: { readonly: true },
        phoneno: { readonly: true },
        email: { readonly: true },
        account_no: { readonly: true },
        account_holder_name: { readonly: true },
        bank_name: { readonly: true },
        branch: { readonly: true },
        ifsc_code: { readonly: true },
        bank_address: { readonly: true },
        address: { readonly: true },
      });
    } else {
      HandleMultiPropsChange({
        clientname: { mandatory: true },
        phoneno: { mandatory: true },
        email: { mandatory: true },
      });
    }
    if (id != undefined) {
      GetRecords(URL_Account_Customer_By_ID + id).then((res) => {
        if (res.success) {
          res = res.record;
          console.log("URL_Customer_By_ID", res);

          handleMultiFieldChange({
            clientname: res.clientname,
            company_id: res.company_id.map((item) => item.company_id),
            phoneno: res.phoneno,
            email: res.email,
            account_no: res.account_no,
            account_holder_name: res.account_holder_name,
            bank_name: res.bank_name,
            branch: res.branch,
            ifsc_code: res.ifsc_code,
            bank_address: res.bank_address,
            address: JSON.parse(res.address) || [],
          });
          setSelectedOptions(
            res.company_id.map((item) => ({
              ...item,
              value: item.company_id,
              label: item.company_name,
            }))
          );
          let addressprops = JSON.parse(res.address) || [];
          addressprops = addressprops.map((item) => false);
          HandlePropsChange("address", {
            ...props.address,
            addressmandatory: addressprops,
          });
        }
      });
    } else {
    }
  }, [option, id]);

  function GetCompanyList() {
    getList({
      listUrl: "/api/v2/accountingcompany/getcompany/",
    }).then((res) => {
      if (res.success) {
        // console.log("1234",res?.data?.list);
        res = res?.data?.list || [];
        res = res.map((item) => ({
          value: item.id,
          label: item.company_name,
        }));
        HandlePropsChange("company_id", {
          company_id_list: [...initProps.company_id.company_id_list, ...res],
        });
      }
    });
  }
  //Load List of datas for dropdowns
  function getReceivableList() {
    getList({
      listUrl: URL_Account_Charts_List + id,
      field: "group_id",
      search: "10",
      count: 1000,
    }).then((res) => {
      if (res.success) {
        res = res?.data?.list || [];
        res = res.map((item) => ({
          value: item.id,
          label: item.ledger_name,
        }));
        console.log("URL_Account_Charts_List receivable", res);
      }
    });
  }
  function getPayableList() {
    getList({
      listUrl: URL_Account_Charts_List + id,
      field: "group_id",
      search: "11",
      count: 1000,
    }).then((res) => {
      if (res.success) {
        res = res?.data?.list || [];
        res = res.map((item) => ({
          value: item.id,
          label: item.ledger_name,
        }));
        console.log("URL_Account_Charts_List Payable", res);
      }
    });
  }
  function getSalesList() {
    getList({
      listUrl: URL_Account_Charts_List + id,
      field: "group_id",
      search: "18",
      count: 1000,
    }).then((res) => {
      if (res.success) {
        res = res?.data?.list || [];
        res = res.map((item) => ({
          value: item.id,
          label: item.ledger_name,
        }));
        console.log("URL_Account_Charts_List Sales", res);
      }
    });
  }

  // *print the Record and Props if any value change between the Record and Props
  useEffect(() => {
    console.log("Record State change -> ", Record);
    console.log("Props State change -> ", props);
    console.log("selectedOptions State change -> ", selectedOptions);
  }, [Record, props, selectedOptions]);

  const validateEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };

  // *create Or update the Record
  function update() {
    store.startLoading();
    let savedata = { ...Record };
    const email = savedata.email;
    if (savedata.email && !validateEmail(email)) {
      store.stopLoading();
      ErrorAlert("Invalid email address. Please enter a valid email.");
      return;
    }
    savedata.modifiedBy = store.getuser().id;
    savedata.address = JSON.stringify(savedata.address);
    console.log("Submiting savedata", savedata);
    if (
      CheckForAddress() &&
      CheckMandatory() &&
      CheckforCompany() &&
      CheckForNumber()
    ) {
      UpdateRecord(URL_Account_Customer_Update + id, savedata).then((res) => {
        console.log("Resonce of saveRecord - ", res);
        if (res.success) {
          store.stopLoading();
          SuccessAlert("Customer Submitted Successfully");
          store.navback();
        } else {
          store.stopLoading();
          ErrorAlert(
            res?.errormessage?.response?.data ||
              " Error Found Please contact the Admin"
          );
        }
      });
    }
  }
  function Submit() {
    store.startLoading();
    let savedata = { ...Record };
    const email = savedata.email;
    if (savedata.email && !validateEmail(email)) {
      store.stopLoading();
      ErrorAlert("Invalid email address. Please enter a valid email.");
      return;
    }
    savedata.modifiedBy = store.getuser().id;
    savedata.createdBy = store.getuser().id;
    savedata.address = JSON.stringify(savedata.address);
    console.log("Submiting savedata", savedata);
    if (
      CheckForAddress() &&
      CheckMandatory() &&
      CheckforCompany() &&
      CheckForNumber()
    ) {
      saveRecord(URL_Account_Customer_Create, savedata).then((res) => {
        console.log("Resonce of saveRecord - ", res);
        if (res.success) {
          store.stopLoading();
          SuccessAlert("Customer Submitted Successfully");
          store.navback();
        } else {
          store.stopLoading();
          ErrorAlert(
            res?.errormessage?.response?.data ||
              " Error Found Please contact the Admin"
          );
        }
      });
    }
  }
  // *Check for the mandatory fields if empty or not
  function CheckMandatory() {
    let out = true;
    for (let obj in props) {
      if (typeof props[obj] === "object") {
        if (props[obj].mandatory) {
          if (Record[obj] == "" || Record[obj] == " ") {
            HandlePropsChange(obj, { mandatory: true, show: true });
            out = false;
          } else {
            HandlePropsChange(obj, { mandatory: true, show: false });
          }
        }
      }
    }
    out === false && ErrorAlert("Please fill the Mandatory Fields.");
    out === false && store.stopLoading();
    return out;
  }
  function CheckForAddress() {
    let addressprops = [...props.address.addressmandatory];
    let out = true;
    Record.address.map((address, addressId) => {
      if (
        address?.line_1 != undefined &&
        address?.line_1 != "" &&
        address?.state != undefined &&
        address?.state != "" &&
        address?.country != undefined &&
        address?.country != "" &&
        address?.pincode != undefined &&
        address?.pincode != ""
      ) {
        console.log("address Found");
        addressprops[addressId] = false;
      } else {
        console.log("address not found");
        addressprops[addressId] = true;
        out = false;
      }
    });
    HandlePropsChange("address", {
      ...props.address,
      addressmandatory: [...addressprops],
    });
    out === false && ErrorAlert("Please fill the Mandatory Address Fields.");
    out === false && store.stopLoading();
    return out;
  }
  function CheckforCompany() {
    console.log("CheckforCompany", Record.company_id.length > 0);

    if (Record.company_id.length > 0) {
      HandlePropsChange("company_id", { ...props.company_id, show: false });
      return true;
    } else {
      HandlePropsChange("company_id", { ...props.company_id, show: true });
      WarningAlert("Please fill the Mandatory Fields.");
      store.stopLoading();
      return false;
    }
  }
  function CheckForNumber() {
    let out = true;
    if (Record.phoneno.length < 8) {
      out = false;
      store.stopLoading();
      WarningAlert("Phone number need to have atlease 8 characters.");
      HandlePropsChange("phoneno", { mandatory: true, show: true });
    } else {
      HandlePropsChange("phoneno", { mandatory: true, show: true });
    }
    return out;
  }

  // *Handle Fields changes in Record States
  function handleFieldChange(fieldName, value) {
    setRecord((prev) => {
      return { ...prev, [fieldName]: value };
    });
  }
  function handleMultiFieldChange(value = {}) {
    setRecord((prev) => {
      for (let fieldName in value) {
        prev[fieldName] = value[fieldName];
      }
      return { ...prev };
    });
  }
  // *Handle Props Changes in Props States
  function HandlePropsChange(fieldName, value = {}) {
    setprops((prev) => {
      let oldprop = prev[fieldName];

      for (let prophead in value) {
        oldprop[prophead] = value[prophead];
      }
      prev[fieldName] = oldprop;
      return { ...prev };
    });
  }
  function HandleMultiPropsChange(NewProps = {}) {
    setprops((prev) => {
      for (let fieldName in NewProps) {
        let oldprop = prev[fieldName];
        let newprop = NewProps[fieldName];
        console.log("typeof newprop", typeof newprop);

        if (typeof newprop === "object") {
          for (let prophead in newprop) {
            oldprop[prophead] = newprop[prophead];
          }
          prev[fieldName] = oldprop;
        } else {
          prev[fieldName] = newprop;
        }
      }
      console.log("updated props", prev);

      return { ...prev };
    });
  }
  const options = [
    { value: "apple", label: "Apple" },
    { value: "banana", label: "Banana" },
    { value: "cherry", label: "Cherry" },
    { value: "date", label: "Date" },
  ];

  const handleChange = (selected) => {
    setSelectedOptions(selected);
    const selectedindexs = selected.map((option) => option.value);
    setRecord((prev) => ({
      ...prev,
      company_id: selectedindexs,
    }));
  };

  return (
    <FormView
      actions={[
        {
          Name: "Submit",
          type: "primary",
          onclick: Submit,
          visible: id === undefined,
        },
        {
          Name: "Update",
          type: "primary",
          onclick: update,
          visible: option === "edit" && id !== undefined && props.update,
        },
        {
          Name: "Cancel",
          onclick: () => {
            navigate(-1);
          },
          visible: true,
        },
      ]}
    >
      <div className="grid grid-cols-2 gap-2 w-full ">
        <div // clientname
          className={
            "text-[14px] w-full h-full px-3 flex-row items-center gap-3 " +
            (props.clientname.hidden ? " hidden " : " flex ")
          }
        >
          <label className={"w-fit  min-w-[150px] max-w-full"}>
            Customer Name
            {props.clientname.mandatory && (
              <span className="text-[#ff2828] text-[14px] h-fit">*</span>
            )}
          </label>
          <input
            className={
              "w-full border-b border-Old_Silver border-opacity-35 px-2 py-1 text-Old_Silver disabled:bg-[#c6c6ca] disabled:bg-opacity-20 outline-none " +
              (props.clientname.mandatory && props.clientname.show
                ? " rounded-2xl border-2 border-[#ff2828] "
                : "")
            }
            id={"clientname"}
            type="text"
            value={Record.clientname}
            disabled={props.clientname.readonly}
            onChange={(event) => {
              handleFieldChange("clientname", event.target.value);
            }}
          />
        </div>
        <div // email
          className={
            "text-[14px] w-full h-full px-3 flex-row items-center gap-3 " +
            (props.email.hidden ? " hidden " : " flex ")
          }
        >
          <label className={"w-fit  min-w-[150px] max-w-full"}>
            E-Mail Id
            {props.email.mandatory && (
              <span className="text-[#ff2828] text-[14px] h-fit">*</span>
            )}
          </label>
          <input
            className={
              "w-full border-b border-Old_Silver border-opacity-35 px-2 py-1 text-Old_Silver disabled:bg-[#c6c6ca] disabled:bg-opacity-20 outline-none " +
              (props.email.mandatory && props.email.show
                ? " rounded-2xl border-2 border-[#ff2828] "
                : "")
            }
            id={"email"}
            type="text"
            value={Record.email}
            disabled={props.email.readonly}
            onChange={(event) => {
              handleFieldChange("email", event.target.value);
            }}
          />
        </div>
        <div // phoneno
          className={
            "text-[14px] w-full h-full px-3 flex-row items-center gap-3 " +
            (props.phoneno.hidden ? " hidden " : " flex ")
          }
        >
          <label className={"w-fit  min-w-[150px] max-w-full"}>
            Phone Number
            {props.phoneno.mandatory && (
              <span className="text-[#ff2828] text-[14px] h-fit">*</span>
            )}
          </label>
          <input
            className={
              "w-full border-b border-Old_Silver border-opacity-35 px-2 py-1 text-Old_Silver disabled:bg-[#c6c6ca] disabled:bg-opacity-20 outline-none " +
              (props.phoneno.mandatory && props.phoneno.show
                ? " rounded-2xl border-2 border-[#ff2828] "
                : "")
            }
            id={"phoneno"}
            type="number"
            value={Record.phoneno}
            disabled={props.phoneno.readonly}
            onChange={(event) => {
              handleFieldChange("phoneno", event.target.value);
            }}
          />
        </div>
        <div
          className={
            "text-[14px] w-full h-full px-3 flex-row items-center gap-3 " +
            (props.company_id.hidden ? " hidden " : " flex ")
          }
        >
          <label className={"w-fit  min-w-[150px] max-w-full"}>
            Company
            {props.company_id.mandatory && (
              <span className="text-[#ff2828] text-[14px] h-fit">*</span>
            )}
          </label>
          <Select
            className={
              "w-full" +
              (props.company_id.mandatory && props.company_id.show
                ? " rounded-1xl border-2 border-[#ff2828] "
                : "")
            }
            isMulti
            name="Company"
            options={props.company_id.company_id_list}
            value={selectedOptions}
            onChange={handleChange}
          />
        </div>
        <TabsView
          className="col-span-2 mt-1"
          tabsList={[
            {
              name: "Address",
              onClick: () => {
                HandleMultiPropsChange({
                  address: { hidden: false },
                  bank_name: { hidden: true },
                  account_holder_name: { hidden: true },
                  account_no: { hidden: true },
                  ifsc_code: { hidden: true },
                  branch: { hidden: true },
                  bank_address: { hidden: true },
                });
              },
            },
            {
              name: "Bank",
              onClick: () => {
                HandleMultiPropsChange({
                  address: { hidden: true },
                  bank_name: { hidden: false },
                  account_holder_name: { hidden: false },
                  account_no: { hidden: false },
                  ifsc_code: { hidden: false },
                  branch: { hidden: false },
                  bank_address: { hidden: false },
                });
              },
            },
          ]}
        />

        {Record.address.map((address, addressId) => (
          <span
            className={
              "grid grid-cols-2 col-span-2 gap-3 " +
              (props?.address?.addressmandatory &&
                props?.address?.addressmandatory[addressId] &&
                " border-[#ff2828] border-2 ")
            }
          >
            <div
              className={
                "col-span-2 flex items-center " +
                (props.address.hidden ? " hidden " : " block ")
              }
            >
              <input
                className="text-[18px]"
                value={address.name}
                onChange={(event) => {
                  let newaddress = Record.address;
                  newaddress[addressId].name = event.target.value;
                  handleFieldChange("address", newaddress);
                }}
              />
              <FaEdit className="text-[18px] mr-1 opacity-25" />
              <hr className="flex-grow ml-3" />
              {option !== "view" && (
                <button
                  className="hover:bg-Alice_Blue py-1 px-2 font-semibold text-[14px] border rounded-lg"
                  onClick={() => {
                    let newaddress = [...Record.address];
                    let addressprops = [...props.address.addressmandatory];
                    newaddress = newaddress.filter(
                      (item, index) => index != addressId
                    );
                    addressprops = addressprops.filter(
                      (item, index) => index != addressId
                    );
                    handleFieldChange("address", newaddress);
                    HandlePropsChange("address", {
                      ...props.address,
                      addressmandatory: addressprops,
                    });
                  }}
                >
                  X
                </button>
              )}
            </div>
            <div // address.Line_1
              className={
                "text-[14px] w-full h-full px-3 flex-row items-center gap-3 " +
                (props.address.hidden ? " hidden " : " flex ")
              }
            >
              <label className={"w-fit  min-w-[150px] max-w-full"}>
                Address Line 1
                {props.address.addressmandatory && (
                  <span className="text-[#ff2828] text-[14px] h-fit">*</span>
                )}
              </label>
              <input
                className={
                  "w-full border-b border-Old_Silver border-opacity-35 px-2 py-1 text-Old_Silver disabled:bg-[#c6c6ca] disabled:bg-opacity-20 outline-none " +
                  (props.address.addressmandatory && props.address.show
                    ? " rounded-2xl border-2 border-[#ff2828] "
                    : "")
                }
                id={"address"}
                type="text"
                value={address.line_1}
                disabled={props.address.readonly}
                onChange={(event) => {
                  let newaddress = Record.address;
                  newaddress[addressId].line_1 = event.target.value;
                  handleFieldChange("address", newaddress);
                }}
              />
            </div>
            <div // address.Line_2
              className={
                "text-[14px] w-full h-full px-3 flex-row items-center gap-3 " +
                (props.address.hidden ? " hidden " : " flex ")
              }
            >
              <label className={"w-fit  min-w-[150px] max-w-full"}>
                Address Line 2
                {/* {props.address.addressmandatory && (
                      <span className="text-[#ff2828] text-[14px] h-fit">
                        *
                      </span>
                    )} */}
              </label>
              <input
                className={
                  "w-full border-b border-Old_Silver border-opacity-35 px-2 py-1 text-Old_Silver disabled:bg-[#c6c6ca] disabled:bg-opacity-20 outline-none " +
                  (props.address.addressmandatory && props.address.show
                    ? " rounded-2xl border-2 border-[#ff2828] "
                    : "")
                }
                id={"address"}
                type="text"
                value={address.line_2}
                disabled={props.address.readonly}
                onChange={(event) => {
                  let newaddress = Record.address;
                  newaddress[addressId].line_2 = event.target.value;
                  handleFieldChange("address", newaddress);
                }}
              />
            </div>
            <div // address.state
              className={
                "flex text-[14px] w-full h-full px-3 flex-row items-center gap-3 " +
                (props.address.hidden ? " hidden " : " flex ")
              }
            >
              <label className={"w-fit  min-w-[150px] max-w-full"}>
                State
                {props.address.addressmandatory && (
                  <span className="text-[#ff2828] text-[14px] h-fit">*</span>
                )}
              </label>
              <select
                id={"address"}
                onDragEnd={() => {
                  console.log("At end arraived");
                }}
                className={
                  " w-full border-b border-Old_Silver border-opacity-35 px-2 py-1 text-Old_Silver disabled:bg-[#c6c6ca] disabled:bg-opacity-20   outline-none " +
                  (props.address.addressmandatory && props.address.show
                    ? " rounded-2xl border-2 border-[#ff2828] "
                    : "")
                }
                onChange={(event) => {
                  let newaddress = Record.address;
                  newaddress[addressId].state = event.target.value;
                  handleFieldChange("address", newaddress);
                }}
                value={address.state}
                disabled={props.address.readonly}
                aria-placeholder="please select sam"
              >
                {props.address.state_list.map((item, index) => (
                  <option key={index} value={item.value}>
                    {item.label}
                  </option>
                ))}
              </select>
            </div>
            <div // address.country
              className={
                "flex text-[14px] w-full h-full px-3 flex-row items-center gap-3 " +
                (props.address.hidden ? " hidden " : " flex ")
              }
            >
              <label className={"w-fit  min-w-[150px] max-w-full"}>
                Country
                {props.address.addressmandatory && (
                  <span className="text-[#ff2828] text-[14px] h-fit">*</span>
                )}
              </label>
              <select
                id={"address"}
                onDragEnd={() => {
                  console.log("At end arraived");
                }}
                className={
                  " w-full border-b border-Old_Silver border-opacity-35 px-2 py-1 text-Old_Silver disabled:bg-[#c6c6ca] disabled:bg-opacity-20   outline-none " +
                  (props.address.addressmandatory && props.address.show
                    ? " rounded-2xl border-2 border-[#ff2828] "
                    : "")
                }
                onChange={(event) => {
                  let newaddress = Record.address;
                  newaddress[addressId].country = event.target.value;
                  handleFieldChange("address", newaddress);
                }}
                value={address.country}
                disabled={props.address.readonly}
                aria-placeholder="please select sam"
              >
                {props.address.countries_list.map((item, index) => (
                  <option key={index} value={item.value}>
                    {item.label}
                  </option>
                ))}
              </select>
            </div>
            <div // address.pincode
              className={
                "text-[14px] w-full h-full px-3 flex-row items-center gap-3 " +
                (props.address.hidden ? " hidden " : " flex ")
              }
            >
              <label className={"w-fit  min-w-[150px] max-w-full"}>
                Pincode
                {props.address.addressmandatory && (
                  <span className="text-[#ff2828] text-[14px] h-fit">*</span>
                )}
              </label>
              <input
                className={
                  "w-full border-b border-Old_Silver border-opacity-35 px-2 py-1 text-Old_Silver disabled:bg-[#c6c6ca] disabled:bg-opacity-20 outline-none " +
                  (props.address.addressmandatory && props.address.show
                    ? " rounded-2xl border-2 border-[#ff2828] "
                    : "")
                }
                id={"address"}
                type="text"
                value={address.pincode}
                disabled={props.address.readonly}
                onChange={(event) => {
                  let newaddress = Record.address;
                  newaddress[addressId].pincode = event.target.value;
                  handleFieldChange("address", newaddress);
                }}
              />
            </div>
          </span>
        ))}
        {option !== "view" && (
          <div
            className={
              "col-span-2 " + (props.address.hidden ? " hidden " : " block ")
            }
          >
            <button
              className=" hover:bg-Ocean_Blue hover:text-secondary bg-Alice_Blue border p-2 rounded-lg"
              onClick={() => {
                let newaddress = [...Record.address];
                let newaddressProps = [...props.address.addressmandatory];
                if (
                  newaddress.filter((item) => item.name === "other Address")
                    .length > 0
                ) {
                  WarningAlert(
                    'Address with the same name "Other Address" is already exist. Please Change the Address header name'
                  );
                } else {
                  if (newaddress.length == 1) {
                    newaddress.push({
                      name: "Delivery Address",
                      line_1: "",
                      line_2: "",
                      state: "",
                      country: "",
                      pincode: "",
                    });
                  } else if (newaddress.length == 0) {
                    newaddress.push({
                      name: "Permanent Address",
                      line_1: "",
                      line_2: "",
                      state: "",
                      country: "",
                      pincode: "",
                    });
                  } else {
                    newaddress.push({
                      name: "other Address",
                      line_1: "",
                      line_2: "",
                      state: "",
                      country: "",
                      pincode: "",
                    });
                  }
                  newaddressProps.push(false);
                  handleFieldChange("address", newaddress);
                  HandlePropsChange("address", {
                    ...props.address,
                    addressmandatory: [...newaddressProps],
                  });
                }
              }}
            >
              Add Address
            </button>
          </div>
        )}
        <div // bank_name
          className={
            "text-[14px] w-full h-full px-3 flex-row items-center gap-3 " +
            (props.bank_name.hidden ? " hidden " : " flex ")
          }
        >
          <label className={"w-fit  min-w-[150px] max-w-full"}>
            Bank Name
            {props.bank_name.mandatory && (
              <span className="text-[#ff2828] text-[14px] h-fit">*</span>
            )}
          </label>
          <input
            className={
              "w-full border-b border-Old_Silver border-opacity-35 px-2 py-1 text-Old_Silver disabled:bg-[#c6c6ca] disabled:bg-opacity-20 outline-none " +
              (props.bank_name.mandatory && props.bank_name.show
                ? " rounded-2xl border-2 border-[#ff2828] "
                : "")
            }
            id={"bank_name"}
            type="text"
            value={Record.bank_name}
            disabled={props.bank_name.readonly}
            onChange={(event) => {
              handleFieldChange("bank_name", event.target.value);
            }}
          />
        </div>
        <div // account_holder_name
          className={
            "text-[14px] w-full h-full px-3 flex-row items-center gap-3 " +
            (props.account_holder_name.hidden ? " hidden " : " flex ")
          }
        >
          <label className={"w-fit  min-w-[150px] max-w-full"}>
            Account Holder Name
            {props.account_holder_name.mandatory && (
              <span className="text-[#ff2828] text-[14px] h-fit">*</span>
            )}
          </label>
          <input
            className={
              "w-full border-b border-Old_Silver border-opacity-35 px-2 py-1 text-Old_Silver disabled:bg-[#c6c6ca] disabled:bg-opacity-20 outline-none " +
              (props.account_holder_name.mandatory &&
              props.account_holder_name.show
                ? " rounded-2xl border-2 border-[#ff2828] "
                : "")
            }
            id={"account_holder_name"}
            type="text"
            value={Record.account_holder_name}
            disabled={props.account_holder_name.readonly}
            onChange={(event) => {
              handleFieldChange("account_holder_name", event.target.value);
            }}
          />
        </div>
        <div // account_no
          className={
            "text-[14px] w-full h-full px-3 flex-row items-center gap-3 " +
            (props.account_no.hidden ? " hidden " : " flex ")
          }
        >
          <label className={"w-fit  min-w-[150px] max-w-full"}>
            Account Number
            {props.account_no.mandatory && (
              <span className="text-[#ff2828] text-[14px] h-fit">*</span>
            )}
          </label>
          <input
            className={
              "w-full border-b border-Old_Silver border-opacity-35 px-2 py-1 text-Old_Silver disabled:bg-[#c6c6ca] disabled:bg-opacity-20 outline-none " +
              (props.account_no.mandatory && props.account_no.show
                ? " rounded-2xl border-2 border-[#ff2828] "
                : "")
            }
            id={"account_no"}
            type="text"
            value={Record.account_no}
            disabled={props.account_no.readonly}
            onChange={(event) => {
              handleFieldChange("account_no", event.target.value);
            }}
          />
        </div>
        <div // ifsc_code
          className={
            "text-[14px] w-full h-full px-3 flex-row items-center gap-3 " +
            (props.ifsc_code.hidden ? " hidden " : " flex ")
          }
        >
          <label className={"w-fit  min-w-[150px] max-w-full"}>
            IFSC Code
            {props.ifsc_code.mandatory && (
              <span className="text-[#ff2828] text-[14px] h-fit">*</span>
            )}
          </label>
          <input
            className={
              "w-full border-b border-Old_Silver border-opacity-35 px-2 py-1 text-Old_Silver disabled:bg-[#c6c6ca] disabled:bg-opacity-20 outline-none " +
              (props.ifsc_code.mandatory && props.ifsc_code.show
                ? " rounded-2xl border-2 border-[#ff2828] "
                : "")
            }
            id={"ifsc_code"}
            type="text"
            value={Record.ifsc_code}
            disabled={props.ifsc_code.readonly}
            onChange={(event) => {
              handleFieldChange("ifsc_code", event.target.value);
            }}
          />
        </div>
        <div // branch
          className={
            "text-[14px] w-full h-full px-3 flex-row items-center gap-3 " +
            (props.branch.hidden ? " hidden " : " flex ")
          }
        >
          <label className={"w-fit  min-w-[150px] max-w-full"}>
            Branch
            {props.branch.mandatory && (
              <span className="text-[#ff2828] text-[14px] h-fit">*</span>
            )}
          </label>
          <input
            className={
              "w-full border-b border-Old_Silver border-opacity-35 px-2 py-1 text-Old_Silver disabled:bg-[#c6c6ca] disabled:bg-opacity-20 outline-none " +
              (props.branch.mandatory && props.branch.show
                ? " rounded-2xl border-2 border-[#ff2828] "
                : "")
            }
            id={"branch"}
            type="text"
            value={Record.branch}
            disabled={props.branch.readonly}
            onChange={(event) => {
              handleFieldChange("branch", event.target.value);
            }}
          />
        </div>
        <div // bank_address
          className={
            "text-[14px] w-full h-full px-3 flex-row items-center gap-3 " +
            (props.bank_address.hidden ? " hidden " : " flex ")
          }
        >
          <label className={"w-fit  min-w-[150px] max-w-full"}>
            Bank Address
            {props.bank_address.mandatory && (
              <span className="text-[#ff2828] text-[14px] h-fit">*</span>
            )}
          </label>
          <input
            className={
              "w-full border-b border-Old_Silver border-opacity-35 px-2 py-1 text-Old_Silver disabled:bg-[#c6c6ca] disabled:bg-opacity-20 outline-none " +
              (props.bank_address.mandatory && props.bank_address.show
                ? " rounded-2xl border-2 border-[#ff2828] "
                : "")
            }
            id={"bank_address"}
            type="text"
            value={Record.bank_address}
            disabled={props.bank_address.readonly}
            onChange={(event) => {
              handleFieldChange("bank_address", event.target.value);
            }}
          />
        </div>
      </div>
    </FormView>
  );
};

export default CustomerForm;
