import { useNavigate, useParams } from "react-router-dom";
import { useStore } from "../../../Components/StateProvider";
import { useEffect, useState } from "react";
import { jsPDF } from "jspdf";
import {
  baseurl,
  ErrorAlert,
  GetDateTimeFormat,
  GetRecords,
  RupeeFormat,
  UploadFile,
  URLPRIFIX,
  WarningAlert,
} from "../../../functions/api";
import AccountsLayout from "../AccountsLayout";
import FormView from "../../../Components/Elements/FormView";
import { MdOutlineFileUpload } from "react-icons/md";
import { FaRegEye } from "react-icons/fa6";
import { HiOutlineArrowSmDown, HiOutlineArrowSmUp } from "react-icons/hi";
import * as XLSX from "xlsx";

const NestedListView = ({ records, store }) => {
  const { companyId } = useParams();

  const [expandedIds, setExpandedIds] = useState([]);

  const [transactionDetails, setTransactionDetails] = useState({});

  const toggleExpand = (accountName) => {
    setExpandedIds((prev) =>
      prev.includes(accountName)
        ? prev.filter((item) => item !== accountName)
        : [...prev, accountName]
    );
  };

  const renderTransactions = (transactions) => {
    return transactions.map((transaction, index) => (
      <div
        key={index}
        className="flex justify-between w-full cursor-pointer text-[#344cb7] hover:underline"
        onClick={() => {
          switch (transaction.transactiontype_id) {
            case 1:
              store.navTo(
                `/${URLPRIFIX}/${companyId}/accounts/invoice/view/${transaction.transaction_id}`
              );
              break;
            case 2:
              store.navTo(
                `/${URLPRIFIX}/${companyId}/accounts/journal/view/${transaction.transaction_id}`
              );
              break;
            case 3:
              store.navTo(
                `/${URLPRIFIX}/${companyId}/accounts/expense/view/${transaction.transaction_id}`
              );
              break;
            case 4:
              store.navTo(
                `/${URLPRIFIX}/${companyId}/accounts/receipt/view/${transaction.transaction_id}`
              );
              break;
            case 7:
              store.navTo(
                `/${URLPRIFIX}/${companyId}/accounts/payable/view/${transaction.transaction_id}`
              );
              break;

            default:
              break;
          }
        }}
      >
        <span className="font-medium">{transaction.referenceno}</span>
        <span className="font-medium">{RupeeFormat(transaction.amount)}</span>
      </div>
    ));
  };

  const renderRecords = (items, level = 0) => {
    return items.map((item) => (
      <div key={item.accountName} className={`pl-${level * 4}`}>
        <div
          className="flex items-center cursor-pointer"
          onClick={() => toggleExpand(item.accountName)}
        >
          <span className="flex justify-between w-full hover:bg-Azureish_White">
            <span className="font-medium">
              {(item.subCategories?.length > 0 ||
                item.transactionDetails?.length > 0) && (
                <span className="mr-1 text-[12px]">
                  {item?.subCategories?.length > 0 &&
                    (!expandedIds.includes(item.accountName) ? "▼" : "►")}
                  {item?.transactionDetails?.length > 0 &&
                    (expandedIds.includes(item.accountName) ? "▼" : "►")}
                  {/* ◄ ▲ ▼ ►  */}
                </span>
              )}
              {item.accountName}
            </span>
            <span className="font-medium">{RupeeFormat(item.amount)}</span>
          </span>
        </div>
        {!expandedIds.includes(item.accountName) &&
        item?.subCategories?.length > 0 ? (
          <div className="ml-8 ">
            {renderRecords(item.subCategories, level + 1)}
          </div>
        ) : (
          expandedIds.includes(item.accountName) &&
          item?.transactionDetails?.length > 0 && (
            <div className="ml-8 ">
              {renderTransactions(item.transactionDetails, level + 1)}
            </div>
          )
        )}
      </div>
    ));
  };

  return <div>{renderRecords(records)}</div>;
};
const ProfitLoss = () => {
  const { companyId } = useParams();

  const navigate = useNavigate();
  // const getrecord = `/users/users/${id}`;
  const attachment_Prefix = "Journal_";
  const store = useStore();

  // *initial values
  const init = {
    revenues: [],
    expenses: [],
    totalRevenue: "",
    totalExpenses: "0",
    netProfit: "0",

    fromDate: "",
    ToDate: "",
  };
  const initProps = {
    add: false,
    delete: false,
    on_off: false,
    print: false,
    update: false,
    view: false,
    revenues: {},
    expenses: {},
    totalRevenue: {},
    totalExpenses: {},
    netProfit: {},

    fromDate: {},
    ToDate: {},
  };

  // *states declaration for records and props
  const [Record, setRecord] = useState(init);
  const [props, setprops] = useState(initProps);

  // *initial values set on the page load
  useEffect(() => {
    const dateTime = GetDateTimeFormat();
    store.setmodule("profitandloss", init);

    handleMultiFieldChange({
      fromDate: dateTime.MonthStartDate + "Z",
      ToDate: dateTime.MonthEndDate + "Z",
    });

    loadRecord();
  }, []);

  function loadRecord(fromDate, ToDate) {
    GetRecords(
      `/api/v2/accounting/profitandloss/${companyId}?startDate=${
        fromDate || Record.fromDate
      }&endDate=${ToDate || Record.ToDate}`
    ).then((res) => {
      console.log("accounting/profitandloss", res);
      if (res.success) {
        res = res.record;
        // res = {
        //   revenues: [
        //     {
        //       accountName: "Asset",
        //       amount: 111914,
        //       isbalanced: false,
        //       child: [
        //         {
        //           accountName: "CurrentAsset",
        //           amount: 111914,
        //           isbalanced: false,
        //           child: [
        //             {
        //               accountName: "Sundry Debtor",
        //               amount: 111914,
        //               isbalanced: false,
        //               child: [
        //                 {
        //                   accountName: "Sarath Kumar",
        //                   amount: 111914,
        //                   isbalanced: false,
        //                 },
        //               ],
        //             },
        //           ],
        //         },
        //         {
        //           accountName: "Sundry Debtor",
        //           amount: 111914,
        //           isbalanced: false,
        //           child: [
        //             {
        //               accountName: "CurrentAsset",
        //               amount: 111914,
        //               isbalanced: false,
        //             },
        //           ],
        //         },
        //       ],
        //     },
        //     {
        //       accountName: "Asset",
        //       amount: 111914,
        //       isbalanced: false,
        //       child: [
        //         {
        //           accountName: "CurrentAsset",
        //           amount: 111914,
        //           isbalanced: false,
        //           child: [
        //             {
        //               accountName: "Sundry Debtor",
        //               amount: 111914,
        //               isbalanced: false,
        //               child: [
        //                 {
        //                   accountName: "Sarath Kumar",
        //                   amount: 111914,
        //                   isbalanced: false,
        //                 },
        //               ],
        //             },
        //           ],
        //         },
        //         {
        //           accountName: "Sundry Debtor",
        //           amount: 111914,
        //           isbalanced: false,
        //           child: [
        //             {
        //               accountName: "CurrentAsset",
        //               amount: 111914,
        //               isbalanced: false,
        //             },
        //           ],
        //         },
        //       ],
        //     },
        //     {
        //       accountName: "Asset",
        //       amount: 111914,
        //       isbalanced: false,
        //       child: [
        //         {
        //           accountName: "CurrentAsset",
        //           amount: 111914,
        //           isbalanced: false,
        //           child: [
        //             {
        //               accountName: "Sundry Debtor",
        //               amount: 111914,
        //               isbalanced: false,
        //               child: [
        //                 {
        //                   accountName: "Sarath Kumar",
        //                   amount: 111914,
        //                   isbalanced: false,
        //                 },
        //               ],
        //             },
        //           ],
        //         },
        //         {
        //           accountName: "Sundry Debtor",
        //           amount: 111914,
        //           isbalanced: false,
        //           child: [
        //             {
        //               accountName: "CurrentAsset",
        //               amount: 111914,
        //               isbalanced: false,
        //             },
        //           ],
        //         },
        //       ],
        //     },
        //     {
        //       accountName: "Asset",
        //       amount: 111914,
        //       isbalanced: false,
        //       child: [
        //         {
        //           accountName: "CurrentAsset",
        //           amount: 111914,
        //           isbalanced: false,
        //           child: [
        //             {
        //               accountName: "Sundry Debtor",
        //               amount: 111914,
        //               isbalanced: false,
        //               child: [
        //                 {
        //                   accountName: "Sarath Kumar",
        //                   amount: 111914,
        //                   isbalanced: false,
        //                 },
        //               ],
        //             },
        //           ],
        //         },
        //         {
        //           accountName: "Sundry Debtor",
        //           amount: 111914,
        //           isbalanced: false,
        //           child: [
        //             {
        //               accountName: "CurrentAsset",
        //               amount: 111914,
        //               isbalanced: false,
        //             },
        //           ],
        //         },
        //       ],
        //     },
        //   ],
        //   expenses: [
        //     {
        //       accountName: "Asset",
        //       amount: 111914,
        //       isbalanced: false,
        //       child: [
        //         {
        //           accountName: "CurrentAsset",
        //           amount: 111914,
        //           isbalanced: false,
        //           child: [
        //             {
        //               accountName: "Sundry Debtor",
        //               amount: 111914,
        //               isbalanced: false,
        //               child: [
        //                 {
        //                   accountName: "Sarath Kumar",
        //                   amount: 111914,
        //                   isbalanced: false,
        //                 },
        //               ],
        //             },
        //           ],
        //         },
        //         {
        //           accountName: "Sundry Debtor",
        //           amount: 111914,
        //           isbalanced: false,
        //           child: [
        //             {
        //               accountName: "CurrentAsset",
        //               amount: 111914,
        //               isbalanced: false,
        //             },
        //           ],
        //         },
        //       ],
        //     },
        //     {
        //       accountName: "Asset",
        //       amount: 111914,
        //       isbalanced: false,
        //       child: [
        //         {
        //           accountName: "CurrentAsset",
        //           amount: 111914,
        //           isbalanced: false,
        //           child: [
        //             {
        //               accountName: "Sundry Debtor",
        //               amount: 111914,
        //               isbalanced: false,
        //               child: [
        //                 {
        //                   accountName: "Sarath Kumar",
        //                   amount: 111914,
        //                   isbalanced: false,
        //                 },
        //               ],
        //             },
        //           ],
        //         },
        //         {
        //           accountName: "Sundry Debtor",
        //           amount: 111914,
        //           isbalanced: false,
        //           child: [
        //             {
        //               accountName: "CurrentAsset",
        //               amount: 111914,
        //               isbalanced: false,
        //             },
        //           ],
        //         },
        //       ],
        //     },
        //     {
        //       accountName: "Asset",
        //       amount: 111914,
        //       isbalanced: false,
        //       child: [
        //         {
        //           accountName: "CurrentAsset",
        //           amount: 111914,
        //           isbalanced: false,
        //           child: [
        //             {
        //               accountName: "Sundry Debtor",
        //               amount: 111914,
        //               isbalanced: false,
        //               child: [
        //                 {
        //                   accountName: "Sarath Kumar",
        //                   amount: 111914,
        //                   isbalanced: false,
        //                 },
        //               ],
        //             },
        //           ],
        //         },
        //         {
        //           accountName: "Sundry Debtor",
        //           amount: 111914,
        //           isbalanced: false,
        //           child: [
        //             {
        //               accountName: "CurrentAsset",
        //               amount: 111914,
        //               isbalanced: false,
        //             },
        //           ],
        //         },
        //       ],
        //     },
        //     {
        //       accountName: "Asset",
        //       amount: 111914,
        //       isbalanced: false,
        //       child: [
        //         {
        //           accountName: "CurrentAsset",
        //           amount: 111914,
        //           isbalanced: false,
        //           child: [
        //             {
        //               accountName: "Sundry Debtor",
        //               amount: 111914,
        //               isbalanced: false,
        //               child: [
        //                 {
        //                   accountName: "Sarath Kumar",
        //                   amount: 111914,
        //                   isbalanced: false,
        //                 },
        //               ],
        //             },
        //           ],
        //         },
        //         {
        //           accountName: "Sundry Debtor",
        //           amount: 111914,
        //           isbalanced: false,
        //           child: [
        //             {
        //               accountName: "CurrentAsset",
        //               amount: 111914,
        //               isbalanced: false,
        //             },
        //           ],
        //         },
        //       ],
        //     },
        //     {
        //       accountName: "Asset",
        //       amount: 111914,
        //       isbalanced: false,
        //       child: [
        //         {
        //           accountName: "CurrentAsset",
        //           amount: 111914,
        //           isbalanced: false,
        //           child: [
        //             {
        //               accountName: "Sundry Debtor",
        //               amount: 111914,
        //               isbalanced: false,
        //               child: [
        //                 {
        //                   accountName: "Sarath Kumar",
        //                   amount: 111914,
        //                   isbalanced: false,
        //                 },
        //               ],
        //             },
        //           ],
        //         },
        //         {
        //           accountName: "Sundry Debtor",
        //           amount: 111914,
        //           isbalanced: false,
        //           child: [
        //             {
        //               accountName: "CurrentAsset",
        //               amount: 111914,
        //               isbalanced: false,
        //             },
        //           ],
        //         },
        //       ],
        //     },
        //   ],
        //   totalRevenue: 111914,
        //   netProfit: 0,
        //   totalExpenses: 111914,
        //   isbalanced: true,
        // };
        handleMultiFieldChange({
          revenues: res.revenues,
          expenses: res.expenses,
          totalRevenue: res.totalRevenue,
          totalExpenses: res.totalExpenses,
          netProfit: res.netProfit,
        });
      }
    });
  }

  // *print the Record and Props if any value change between the Record and Props
  useEffect(() => {
    console.log("Record State change -> ", Record);
    console.log("Props State change -> ", props);
  }, [Record, props]);

  // *create Or update the Record
  function update() {}
  function Submit() {
    store.startLoading();
    let savedata = { ...Record };
    savedata.modifiedBy = store.getuser().id;
    savedata.createdBy = store.getuser().id;
    console.log("Submiting savedata", savedata);
    if (CheckMandatory()) {
      store.stopLoading();
      localStorage.setItem("journal_Number", Record.journal_Number + 1);

      //   saveRecord(setrecord, savedata).then((res) => {
      //     console.log("Resonce of saveRecord - ", res);
      //     if (res.success) {
      //       store.stopLoading();
      //       SuccessAlert("User Submitted Successfully");
      //       store.navback();
      //     } else {
      //       store.stopLoading();
      //       ErrorAlert(
      //         res?.errormessage?.response?.data?.errorMessage ||
      //           " Error Found Please contact the Admin"
      //       );
      //     }
      //   });
    }
  }

  // *Check for the mandatory fields if empty or not
  function CheckMandatory() {
    let out = true;
    let Fields = "";
    for (let obj in props) {
      if (typeof props[obj] === "object") {
        if (props[obj].mandatory) {
          if (Record[obj] == "" || Record[obj] == " ") {
            HandlePropsChange(obj, { mandatory: true, show: true });
            out = false;
          } else {
            HandlePropsChange(obj, { mandatory: true, show: false });
          }
        }
      }
    }
    out === false && ErrorAlert("Please fill the Mandatory Fields.");
    out === false && store.stopLoading();
    return out;
  }

  // *Handle Fields changes in Record States
  function handleFieldChange(fieldName, value) {
    setRecord((prev) => {
      return { ...prev, [fieldName]: value };
    });
  }
  function handleMultiFieldChange(value = {}) {
    setRecord((prev) => {
      for (let fieldName in value) {
        prev[fieldName] = value[fieldName];
      }
      return { ...prev };
    });
  }

  // *Handle Props Changes in Props States
  function HandlePropsChange(fieldName, value = {}) {
    setprops((prev) => {
      let oldprop = prev[fieldName];

      for (let prophead in value) {
        oldprop[prophead] = value[prophead];
      }
      prev[fieldName] = oldprop;
      return { ...prev };
    });
  }
  function HandleMultiPropsChange(NewProps = {}) {
    setprops((prev) => {
      for (let fieldName in NewProps) {
        let oldprop = prev[fieldName];
        let newprop = NewProps[fieldName];
        console.log("typeof newprop", typeof newprop);

        if (typeof newprop === "object") {
          for (let prophead in newprop) {
            oldprop[prophead] = newprop[prophead];
          }
          prev[fieldName] = oldprop;
        } else {
          prev[fieldName] = newprop;
        }
      }
      console.log("updated props", prev);

      return { ...prev };
    });
  }

  // *Handle Change in the fields
  function HandleAttachement(event) {
    var bodyFormData = new FormData();
    bodyFormData.append("file", event.target.files[0]);
    UploadFile(attachment_Prefix, bodyFormData).then((res) => {
      if (res.success) {
        handleFieldChange("attachment", res.data);
      }
    });
  }
  const ExportExcel = () => {
    if (
      !Record ||
      (Record.revenues.length === 0 && Record.expenses.length === 0)
    ) {
      WarningAlert("No data available to export.");
      return;
    } else {
      let rows = [];
      const fromDate = new Date(Record.fromDate);
      const toDate = new Date(Record.ToDate);
      const formattedFromDate = fromDate.toISOString().split("T")[0];
      const formattedToDate = toDate.toISOString().split("T")[0];
      rows.push(["", "", ` ${formattedFromDate} - ${formattedToDate} `]);
      rows.push(["", "", "Balance"]);
      // rows.push(['INCOME', '', '']);

      Record.revenues.forEach((revenue) => {
        rows.push([revenue.accountName,"", revenue.amount, "", ""]);
        revenue.subCategories.forEach((subCategory) => {
          rows.push([
            `  ${subCategory.accountName}`,"",
            subCategory.amount,
            "",
            "",
          ]);
          subCategory.transactionDetails
          .forEach((transaction) => {
            rows.push(["", ` ${transaction.referenceno}`, transaction.amount]);

            // transaction.subCategories.forEach((transaction1) => {
            //   rows.push([
            //     "",
            //     `${transaction1.accountName}`,
            //     transaction1.amount,
            //     "",
            //     "",
            //   ]);
            //   transaction1.transactionDetails.forEach((transaction2) => {
            //     rows.push([
            //       "",
            //       "",
            //       `${transaction2.referenceno}`,
            //       transaction2.amount,
            //       "",
            //       "",
            //     ]);
            //   });
            // });
          });
        });
        rows.push([""]);
      });
      // rows.push(['EXPENSE', '', '']);
      Record.expenses.forEach((exp) => {
        rows.push([exp.accountName,"", exp.amount, "", ""]);
        exp.subCategories.forEach((subCategory) => {
          rows.push([
            `  ${subCategory.accountName}`,"",
            subCategory.amount,
            "",
            "",
          ]);
          subCategory.transactionDetails.forEach((transaction) => {
            rows.push(["", ` ${transaction.
              referenceno}`, transaction.amount]);

            // transaction.subCategories.forEach((transaction1) => {
            //   rows.push([
            //     "",
            //     `${transaction1.accountName}`,
            //     transaction1.amount,
            //     "",
            //     "",
            //   ]);
            //   transaction1.transactionDetails.forEach((transaction2) => {
            //     rows.push([
            //       "",
            //       "",
            //       `${transaction2.referenceno}`,
            //       transaction2.amount,
            //       "",
            //       "",
            //     ]);
            //   });
            // });
          });
        });
        rows.push([""]);
      });
      rows.push(["TOTAL INCOME", "", Record.totalRevenue]);
      rows.push(["TOTAL EXPENSE", "", Record.totalExpenses]);
      rows.push(["NET PROFIT", "", Record.netProfit]);

      const ws = XLSX.utils.aoa_to_sheet(rows);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Balance Sheet");
      XLSX.writeFile(wb, "Profit & Loss.xlsx");
    }
  };

  
  const ExportPDF = () => {
    if (
      !Record ||
      (Record.revenues.length === 0 && Record.expenses.length === 0)
    ) {
      WarningAlert("No data available to export.");
      return;
    }
  
    const doc = new jsPDF();
    const data = Record;
    const pageWidth = doc.internal.pageSize.width;
    const HeaderTitle = "PROFIT AND LOSS";
    const logo = "/Assets/images/Logo.png";
    const textWidth = doc.getTextWidth(HeaderTitle);
    const xPosition = (pageWidth - textWidth) / 2;
    const fromDate = new Date(Record.fromDate);
    const toDate = new Date(Record.ToDate);
    const formattedFromDate = fromDate.toISOString().split("T")[0];
    const formattedToDate = toDate.toISOString().split("T")[0];
    const pageHeight = doc.internal.pageSize.height;
    doc.addImage(logo, "PNG", 15, 5, 15, 15);
    const dateRange = `  ${formattedFromDate} - ${formattedToDate}`;
    doc.setFontSize(10);
    doc.setTextColor(0, 0, 66);
    doc.text("ABSOLUTE LEGAL", 8, 25);
    doc.text("LAW FIRM", 15, 30);
  
    doc.setTextColor(0, 0, 0);
    doc.setFont("helvetica", "bold");
    doc.setFontSize(16);
    doc.text(HeaderTitle, xPosition, 20);
  
    doc.setFontSize(12);
    doc.setDrawColor(169, 169, 169);
    doc.setLineWidth(0.2);
    const textWidth1 = doc.getTextWidth(dateRange);
    const textHeight = 8;
    doc.rect(155, 5, textWidth1 + 2, textHeight);
    doc.text(dateRange, 155, 10);
  
    let yPosition = 40;
  
    const addContentToPage = (text, x, y) => {
      const pageHeight = doc.internal.pageSize.height;
      if (y > pageHeight - 20) {
        doc.addPage();
        y = 10; 
      }
      doc.text(text, x, y);
      return y ;
    };
    doc.setFont("helvetica", "bold");
    doc.text("Net Profit", 20, yPosition);
    doc.text(`${Record?.netProfit}`, 120, yPosition);
    doc.setLineWidth(0.2);
    doc.line(10, yPosition + 2, pageWidth - 10, yPosition + 2);
    yPosition += 10;
  
    doc.text("Total Income", 20, yPosition);
    doc.text(`${Record?.totalRevenue}`, 120, yPosition);
    doc.setLineWidth(0.2);
    doc.line(10, yPosition + 2, pageWidth - 10, yPosition + 2);
    yPosition += 10;
  
    doc.text("Total Expense", 20, yPosition);
    doc.text(`${Record?.totalExpenses}`, 120, yPosition);
    doc.setLineWidth(0.2);
    doc.line(10, yPosition + 2, pageWidth - 10, yPosition + 2);
    yPosition += 10;
  
    data.revenues.forEach((rev) => {
      doc.setFont("helvetica", "bold");
      yPosition = addContentToPage(rev.accountName, 20, yPosition);
      yPosition = addContentToPage(`${rev.amount}`, 120, yPosition);
      doc.setLineWidth(0.1);
      doc.line(10, yPosition + 2, pageWidth - 10, yPosition + 2);
      yPosition += 8;
  
      doc.setFont("helvetica", "normal");
      rev.subCategories.forEach((subCategory) => {
        yPosition = addContentToPage(`   ${subCategory.accountName}`, 25, yPosition);
        yPosition = addContentToPage(`${subCategory.amount}`, 120, yPosition);
        doc.line(10, yPosition + 2, pageWidth - 10, yPosition + 2);
        yPosition += 8;
  
        subCategory.transactionDetails.forEach((sub) => {
          yPosition = addContentToPage(`     ${sub.referenceno}`, 30, yPosition);
          yPosition = addContentToPage(`${sub.amount}`, 120, yPosition);
          doc.line(10, yPosition + 2, pageWidth - 10, yPosition + 2);
          yPosition += 8;
        });
      });
    });

    data.expenses.forEach((exp) => {
      doc.setFont("helvetica", "bold");
      yPosition = addContentToPage(exp.accountName, 20, yPosition);
      yPosition = addContentToPage(`${exp.amount}`, 120, yPosition);
      doc.setLineWidth(0.1);
      doc.line(10, yPosition + 2, pageWidth - 10, yPosition + 2);
      yPosition += 8;
  
      doc.setFont("helvetica", "normal");
      exp.subCategories.forEach((subCategory) => {
        yPosition = addContentToPage(`     ${subCategory.accountName}`, 25, yPosition);
        yPosition = addContentToPage(`${subCategory.amount}`, 120, yPosition);
        doc.line(10, yPosition + 2, pageWidth - 10, yPosition + 2);
        yPosition += 8;
  
        subCategory.transactionDetails.forEach((sub) => {
          yPosition = addContentToPage(`   ${sub.referenceno}`, 30, yPosition);
          yPosition = addContentToPage(`${sub.amount}`, 120, yPosition);
          doc.line(10, yPosition + 2, pageWidth - 10, yPosition + 2);
          yPosition += 8;
        });
      });
    });
    const pageCount = doc.internal.getNumberOfPages();
    for (let i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      const pageNumber = `Page ${i}`;
      const pageNumberX = (pageWidth - doc.getTextWidth(pageNumber)) / 2; 
      doc.setFontSize(10);
      doc.text(pageNumber, pageNumberX, pageHeight - 10);
    }
    doc.save("Profit&Loss.pdf");
  };
  
  
  return (
    <AccountsLayout HeaderTitle={"Profit and Loss"}>
      <div className="flex px-10 gap-3 py-1 bg-Alice_Blue justify-center items-center border-b border-Old_Silver">
        <div // fromDate
          className={
            "flex text-[14px] w-full h-full px-3 flex-row items-center gap-3 " +
            (props.fromDate.hidden ? " hidden " : " flex ")
          }
        >
          <label className={"w-fit  min-w-[100px] max-w-full"}>
            From Date
            {props.fromDate.mandatory && (
              <span className="text-[#ff2828] text-[14px] h-fit">*</span>
            )}
          </label>
          <input
            className={
              "w-full border-b border-Old_Silver border-opacity-35 font-medium disabled:bg-[#c6c6ca] text-Old_Silver disabled:bg-opacity-20 px-2 py-1 " +
              (props.fromDate.mandatory && props.fromDate.show
                ? " rounded-2xl border-2 border-[#ff2828] "
                : "")
            }
            id={"fromDate"}
            type="date"
            value={Record.fromDate.split("T")[0]}
            disabled={props.fromDate.readonly}
            onChange={(event) => {
              console.log("fromDate new Tsate");
              if (
                new Date(event.target.value + "T00:00:00Z") <
                new Date(Record.ToDate)
              ) {
                handleFieldChange(
                  "fromDate",
                  event.target.value + "T00:00:00Z"
                );
                loadRecord(event.target.value + "T00:00:00Z", Record.ToDate);
              } else {
                handleMultiFieldChange({
                  fromDate: event.target.value + "T00:00:00Z",
                  ToDate: event.target.value + "T23:59:59Z",
                });
                loadRecord(
                  event.target.value + "T00:00:00Z",
                  event.target.value + "T23:59:59Z"
                );
              }
            }}
          />
        </div>
        <div // ToDate
          className={
            "flex text-[14px] w-full h-full px-3 flex-row items-center gap-3 " +
            (props.ToDate.hidden ? " hidden " : " flex ")
          }
        >
          <label className={"w-fit  min-w-[100px] max-w-full"}>
            To Date
            {props.ToDate.mandatory && (
              <span className="text-[#ff2828] text-[14px] h-fit">*</span>
            )}
          </label>
          <input
            className={
              "w-full border-b border-Old_Silver border-opacity-35 font-medium disabled:bg-[#c6c6ca] text-Old_Silver disabled:bg-opacity-20 px-2 py-1 " +
              (props.ToDate.mandatory && props.ToDate.show
                ? " rounded-2xl border-2 border-[#ff2828] "
                : "")
            }
            id={"ToDate"}
            type="date"
            value={Record.ToDate.split("T")[0]}
            disabled={props.ToDate.readonly}
            onChange={(event) => {
              if (
                new Date(Record.fromDate) <
                new Date(event.target.value + "T23:59:59Z")
              ) {
                handleFieldChange("ToDate", event.target.value + "T23:59:59Z");
                loadRecord(Record.fromDate, event.target.value + "T23:59:59Z");
              } else {
                WarningAlert(
                  "The 'To Date' cannot be earlier than the 'From Date'"
                );
              }
            }}
          />
        </div>
        <div className="flex gap-5">
          <button
            className={
              "py-2 px-6 rounded-xl font-bold text-[12px]  bg-primary text-secondary"
            }
            onClick={ExportExcel}
          >
            EXCEL
          </button>
          <button
            className={
              "py-2 px-6 rounded-xl font-bold text-[12px]  bg-primary text-secondary"
            }
            onClick={ExportPDF}
          >
            PDF
          </button>
        </div>
      </div>
      <div className="flex h-[80%] px-10 pt-5 bg-Alice_Blue rounded-lg">
        <div className="w-[50%] p-2 ">
          <div className="h-full">
            <h1 className="font-bold text-[18px]">Income</h1>
            <div className=" h-[90%] overflow-y-auto p-1">
              <NestedListView records={Record.revenues} store={store} />
            </div>
          </div>
          <div className="h-[5%] font-semibold ">
            {"Total Income : " + RupeeFormat(Record.totalRevenue)}
          </div>
        </div>
        <div className="w-[50%] p-2 ">
          <div className="h-full ">
            <h1 className="font-bold text-[18px]">Expense</h1>
            <div className=" h-[90%] overflow-y-auto p-1">
              <NestedListView records={Record.expenses} store={store} />
            </div>
          </div>
          <div className="h-[5%] font-semibold ">
            {"Total Expense: " + RupeeFormat(Record.totalExpenses)}
          </div>
        </div>
      </div>
      <div className="h-[10%] text-[16px] font-semibold px-12 bg-Alice_Blue items-center flex pt-5">
        <h1>{"Net profit: " + RupeeFormat(Record.netProfit)}</h1>
      </div>
    </AccountsLayout>
  );
};

export default ProfitLoss;
