import { useNavigate, useParams } from "react-router-dom";
import { useStore } from "../../../Components/StateProvider";
import { useEffect, useState } from "react";
import * as XLSX from "xlsx";
import { jsPDF } from "jspdf";
import {
  ErrorAlert,
  GetDateTimeFormat,
  GetRecords,
  RupeeFormat,
  UploadFile,
  URLPRIFIX,
  WarningAlert,
} from "../../../functions/api";
import AccountsLayout from "../AccountsLayout";

const CustomerLedger = () => {
  const { companyId } = useParams();
  const getrecord = `/users/users/${companyId}`;
  const attachment_Prefix = "Journal_";
  const store = useStore();

  // *initial values
  const init = {
    entries: [],
    totalDebit: [],
    totalCredit: [],
    fromDate: "",
    ToDate: "",
  };
  const initProps = {
    add: false,
    delete: false,
    on_off: false,
    print: false,
    update: false,
    view: false,
    entries: {},
    totalDebit: {},
    totalCredit: {},
    fromDate: {},
    ToDate: {},
  };

  // *states declaration for records and props
  const [Record, setRecord] = useState(init);
  const [props, setprops] = useState(initProps);

  // *initial values set on the page load
  useEffect(() => {
    store.setmodule("ledger", init);

    const dateTime = GetDateTimeFormat();

    handleMultiFieldChange({
      fromDate: dateTime.MonthStartDate +"Z",
      ToDate: dateTime.MonthEndDate+"Z",
    });

    loadRecord(dateTime.MonthStartDate + "Z", dateTime.MonthEndDate + "Z");
  }, []);

  function loadRecord(fromDate, ToDate) {
    GetRecords(
      `/api/v2/accounting/customer/${companyId}?startDate=${
        fromDate || Record.fromDate
      }&endDate=${ToDate || Record.ToDate}`
    ).then((res) => {
      console.log("accounting/ledger", res);
      if (res.success) {
        res = res.record;
        handleMultiFieldChange({
          entries: res,
        });
      }
    });
  }

  // *print the Record and Props if any value change between the Record and Props
  useEffect(() => {
    console.log("Record State change -> ", Record);
    console.log("Props State change -> ", props);
  }, [Record, props]);

  // *create Or update the Record
  function update() {}
  function Submit() {
    store.startLoading();
    let savedata = { ...Record };
    savedata.modifiedBy = store.getuser().id;
    savedata.createdBy = store.getuser().id;
    console.log("Submiting savedata", savedata);
    if (CheckMandatory()) {
      store.stopLoading();
      localStorage.setItem("journal_Number", Record.journal_Number + 1);

      //   saveRecord(setrecord, savedata).then((res) => {
      //     console.log("Resonce of saveRecord - ", res);
      //     if (res.success) {
      //       store.stopLoading();
      //       SuccessAlert("User Submitted Successfully");
      //       store.navback();
      //     } else {
      //       store.stopLoading();
      //       ErrorAlert(
      //         res?.errormessage?.response?.data?.errorMessage ||
      //           " Error Found Please contact the Admin"
      //       );
      //     }
      //   });
    }
  }

  // *Check for the mandatory fields if empty or not
  function CheckMandatory() {
    let out = true;
    let Fields = "";
    for (let obj in props) {
      if (typeof props[obj] === "object") {
        if (props[obj].mandatory) {
          if (Record[obj] == "" || Record[obj] == " ") {
            HandlePropsChange(obj, { mandatory: true, show: true });
            out = false;
          } else {
            HandlePropsChange(obj, { mandatory: true, show: false });
          }
        }
      }
    }
    out === false && ErrorAlert("Please fill the Mandatory Fields.");
    out === false && store.stopLoading();
    return out;
  }

  // *Handle Fields changes in Record States
  function handleFieldChange(fieldName, value) {
    setRecord((prev) => {
      return { ...prev, [fieldName]: value };
    });
  }
  function handleMultiFieldChange(value = {}) {
    setRecord((prev) => {
      for (let fieldName in value) {
        prev[fieldName] = value[fieldName];
      }
      return { ...prev };
    });
  }

  // *Handle Props Changes in Props States
  function HandlePropsChange(fieldName, value = {}) {
    setprops((prev) => {
      let oldprop = prev[fieldName];

      for (let prophead in value) {
        oldprop[prophead] = value[prophead];
      }
      prev[fieldName] = oldprop;
      return { ...prev };
    });
  }
  function HandleMultiPropsChange(NewProps = {}) {
    setprops((prev) => {
      for (let fieldName in NewProps) {
        let oldprop = prev[fieldName];
        let newprop = NewProps[fieldName];
        console.log("typeof newprop", typeof newprop);

        if (typeof newprop === "object") {
          for (let prophead in newprop) {
            oldprop[prophead] = newprop[prophead];
          }
          prev[fieldName] = oldprop;
        } else {
          prev[fieldName] = newprop;
        }
      }
      console.log("updated props", prev);

      return { ...prev };
    });
  }

  // *Handle Change in the fields
  function HandleAttachement(event) {
    var bodyFormData = new FormData();
    bodyFormData.append("file", event.target.files[0]);
    UploadFile(attachment_Prefix, bodyFormData).then((res) => {
      if (res.success) {
        handleFieldChange("attachment", res.data);
      }
    });
  }

   const ExportExcel = () => {
      try {
        if (!Record || !Record.entries || Record.entries.length === 0) {
          WarningAlert("No data available to export.");
          return;
        }
        const formatEntries = (entries) => {
          return entries.reduce((formatted, entry) => {
           
            const formattedEntry = {
              Name: entry.customerName,
              AccountName: entry.ledgerName,
              InvoiceDate: "",
              InvoiceDueDate: "",
              Debit: entry.debit,
              Credit: entry.credit
            };
    
            formatted.push(formattedEntry);
            if (entry.transactions && entry.transactions.length > 0) {
              entry.transactions.forEach((subEntry) => {
                const invoiceDate = subEntry.invoice_date ? new Date(subEntry.invoice_date) : null;
                const dueDate = subEntry.invoice_due_date ? new Date(subEntry.invoice_due_date) : null;
    
                const formattedInvDate = invoiceDate && !isNaN(invoiceDate.getTime())
                  ? invoiceDate.toISOString().split("T")[0]
                  : '';
                const formattedDueDate = dueDate && !isNaN(dueDate.getTime())
                  ? dueDate.toISOString().split("T")[0]
                  : '';
    
  
                formatted.push({
                  Name: subEntry.referenceno,  
                  AccountName: subEntry.ledgerName,  
                  InvoiceDate: formattedInvDate,
                  InvoiceDueDate: formattedDueDate,
                  Debit: subEntry.
                  transactionTotalCredit,
                  Credit: subEntry.
                  transactionTotalCredit
                  
                });
              });
            }
    
            return formatted;
          }, []);
        };
    
        const formattedEntries = formatEntries(Record.entries);
    
        const fromDate = new Date(Record.fromDate);
        const toDate = new Date(Record.ToDate);
        const formattedFromDate = fromDate.toISOString().split("T")[0];
        const formattedToDate = toDate.toISOString().split("T")[0];
    
        let rows = [];
        rows.push(["", "", ` ${formattedFromDate} - ${formattedToDate} `]);
        rows.push(["", ""])
        const ws = XLSX.utils.aoa_to_sheet(rows);
        XLSX.utils.sheet_add_json(ws, formattedEntries, { origin: -1 });
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Entries");
        XLSX.writeFile(wb, "Customer Ledger.xlsx");
      } catch (error) {
        console.error("Error generating Excel file:", error);
        WarningAlert("An error occurred while generating the Excel file.");
      }
    };
    const ExportPDF = () => {
      try {
        if (!Record || !Record.entries || Record.entries.length === 0) {
          WarningAlert("No data available to export.");
          return;
        }
        const doc = new jsPDF('l', 'mm', 'a4');
        const data = Record;
        const HeaderTitle = "Customer LEDGER";
        const logo = "/Assets/images/Logo.png";
        const textWidth = doc.getTextWidth(HeaderTitle);
        const pageWidth = doc.internal.pageSize.width;
        const pageHeight = doc.internal.pageSize.height;
        const xPosition = (pageWidth - textWidth) / 2;
        
        let yPosition = 20;
    
        doc.addImage(logo, "PNG", 15, 5, 15, 15);
    
        const fromDate = new Date(Record?.fromDate);
        const toDate = new Date(Record?.ToDate);
        const formattedFromDate = fromDate.toISOString().split("T")[0];
        const formattedToDate = toDate.toISOString().split("T")[0];
        const dateRange = `${formattedFromDate} - ${formattedToDate}`;
    
        doc.setFontSize(12);
        doc.setFont("helvetica", "bold");
        doc.text("ABSOLUTE LEGAL", 8, 25);
        doc.text("LAW FIRM", 12, 30);
        doc.setFontSize(16);
        doc.text(HeaderTitle, xPosition, yPosition);
        yPosition += 10;
    
        doc.setFontSize(11);
        doc.setLineWidth(0.2);
        const textWidth1 = 60;
        const textHeight = 8;
        doc.rect(220, 5, textWidth1 + 2, textHeight);
        doc.text(dateRange, 230, 10);
  
        yPosition += 15;
        doc.setFontSize(12);
        doc.setFont("helvetica", "bold");
        doc.text("Name", 20, yPosition);
        doc.text("Account Name", 70, yPosition);
        doc.text("Invoice Date", 140, yPosition);
        doc.text("Invoice DueDate", 175, yPosition);
        doc.text("Debit", 225, yPosition);
        doc.text("Credit", 265, yPosition);
        doc.setDrawColor(169, 169, 169);
        doc.setLineWidth(0.2);
        doc.line(10, yPosition + 2, pageWidth - 10, yPosition + 2);
        yPosition += 10; 
       
        doc.setFont("helvetica", "normal");
        data.entries.forEach((entry) => {
          doc.setFontSize(11)
          if (yPosition > pageHeight - 20) {
            doc.addPage();
            yPosition = 20;
            doc.setLineWidth(0.2);
            doc.line(10, yPosition + 2, pageWidth - 10, yPosition + 2);
            yPosition += 10;
          }
          doc.text(entry.customerName, 20, yPosition);
          doc.text(entry.ledgerName, 70, yPosition);
          doc.text(`${entry.totalLedgerDebit}`, 225, yPosition);
          doc.text(`${entry.totalLedgerCredit}`, 265, yPosition);
          doc.setLineWidth(0.05);
          doc.line(10, yPosition + 2, pageWidth - 10, yPosition + 2);
          yPosition += 10; 
          doc.setFont("helvetica", "normal");
          entry.transactions.forEach((subEntry) => {
            doc.setTextColor('#000042');
            doc.setFontSize(11);
            const invoiceDate = subEntry.invoice_date ? new Date(subEntry.invoice_date) : "";
            const dueDate = subEntry.invoice_due_date ? new Date(subEntry.invoice_due_date) : "";
        
            const formattedInvDate = invoiceDate && !isNaN(invoiceDate.getTime()) 
                ? invoiceDate.toISOString().split("T")[0] 
                : ''; 
            const formatteddueDate = dueDate && !isNaN(dueDate.getTime()) 
                ? dueDate.toISOString().split("T")[0] 
                : '';
            if (yPosition > pageHeight - 20) {
              doc.addPage();
              yPosition = 20; 
            }
            doc.setFontSize(11);
            doc.text(`${subEntry.referenceno}`, 20, yPosition);
            doc.text(`${subEntry.ledgerName}`, 70, yPosition);
            doc.text(`${formattedInvDate}`, 140, yPosition);
            doc.text(`${formatteddueDate}`, 175, yPosition);
            doc.text(`${subEntry.transactionTotalDebit}`, 225, yPosition);
            doc.text(`${subEntry.transactionTotalCredit}`, 265, yPosition);
            doc.setLineWidth(0.01);
            doc.line(10, yPosition + 2, pageWidth - 10, yPosition + 2);
            yPosition += 10; 
            doc.setTextColor('#000000'); 
          });
          
        
        });
        const pageCount = doc.internal.getNumberOfPages();
        for (let i = 1; i <= pageCount; i++) {
          doc.setPage(i);
          const pageNumber = `Page ${i}`;
          const pageNumberX = (pageWidth - doc.getTextWidth(pageNumber)) / 2; 
          doc.setFontSize(10);
          doc.text(pageNumber, pageNumberX, pageHeight - 10);
        }
       
        doc.save("CustomerLedger.pdf");
      } catch (error) {
        console.error("Error generating PDF:", error);
        WarningAlert("An error occurred while generating the PDF.");
      }
    };
  return (
    <AccountsLayout HeaderTitle={"Customer Ledger"}>
      <div className="flex px-10 gap-3 py-1 bg-Alice_Blue justify-center items-center border-b border-Old_Silver">
        <div // fromDate
          className={
            "flex text-[14px] w-full h-full px-3 flex-row items-center gap-3 " +
            (props.fromDate.hidden ? " hidden " : " flex ")
          }
        >
          <label className={"w-fit  min-w-[100px] max-w-full"}>
            From Date
            {props.fromDate.mandatory && (
              <span className="text-[#ff2828] text-[14px] h-fit">*</span>
            )}
          </label>
          <input
            className={
              "w-full border-b border-Old_Silver border-opacity-35 font-medium disabled:bg-[#c6c6ca] text-Old_Silver disabled:bg-opacity-20 px-2 py-1 " +
              (props.fromDate.mandatory && props.fromDate.show
                ? " rounded-2xl border-2 border-[#ff2828] "
                : "")
            }
            id={"fromDate"}
            type="date"
            value={Record.fromDate.split("T")[0]}
            disabled={props.fromDate.readonly}
            onChange={(event) => {
              console.log("fromDate new Tsate");
              if (
                new Date(event.target.value + "T00:00:00Z") <
                new Date(Record.ToDate)
              ) {
                handleFieldChange(
                  "fromDate",
                  event.target.value + "T00:00:00Z"
                );
                loadRecord(event.target.value + "T00:00:00Z", Record.ToDate);
              } else {
                handleMultiFieldChange({
                  fromDate: event.target.value + "T00:00:00Z",
                  ToDate: event.target.value + "T23:59:59Z",
                });
                loadRecord(
                  event.target.value + "T00:00:00Z",
                  event.target.value + "T23:59:59Z"
                );
              }
            }}
          />
        </div>
        <div // ToDate
          className={
            "flex text-[14px] w-full h-full px-3 flex-row items-center gap-3 " +
            (props.ToDate.hidden ? " hidden " : " flex ")
          }
        >
          <label className={"w-fit  min-w-[100px] max-w-full"}>
            To Date
            {props.ToDate.mandatory && (
              <span className="text-[#ff2828] text-[14px] h-fit">*</span>
            )}
          </label>
          <input
            className={
              "w-full border-b border-Old_Silver border-opacity-35 font-medium disabled:bg-[#c6c6ca] text-Old_Silver disabled:bg-opacity-20 px-2 py-1 " +
              (props.ToDate.mandatory && props.ToDate.show
                ? " rounded-2xl border-2 border-[#ff2828] "
                : "")
            }
            id={"ToDate"}
            type="date"
            value={Record.ToDate.split("T")[0]}
            disabled={props.ToDate.readonly}
            onChange={(event) => {
              if (
                new Date(Record.fromDate) <
                new Date(event.target.value + "T23:59:59Z")
              ) {
                handleFieldChange("ToDate", event.target.value + "T23:59:59Z");
                loadRecord(Record.fromDate, event.target.value + "T23:59:59Z");
              } else {
                WarningAlert(
                  "The 'To Date' cannot be earlier than the 'From Date'"
                );
              }
            }}
          />
        </div>
        <div className="flex gap-5">
          <button
            className={
              "py-2 px-6 rounded-xl font-bold text-[12px]  bg-primary text-secondary"
            }
            onClick={ExportExcel}
          >
            EXCEL
          </button>
          <button
            className={
              "py-2 px-6 rounded-xl font-bold text-[12px]  bg-primary text-secondary"
            }
            onClick={ExportPDF}
          >
            PDF
          </button>
        </div>
      </div>

      <div className="flex  h-[90%] px-3 pt-2 bg-Alice_Blue rounded-lg overflow-x-auto">
        <div className="w-fit p-2 ">
          <table className="w-fit">
            <thead className=" bg-Azureish_White">
              <th className="min-w-[380px] text-left px-2 text-nowrap  ">Account Name</th>
              <th className="min-w-[140px] text-left px-2 text-nowrap  border-l">
                Account Name
              </th>
              <th className="min-w-[140px] text-left px-2 text-nowrap  border-l">
                Invoice Date
              </th>
              <th className="min-w-[140px] text-left px-2 text-nowrap  border-l">
                Invoice Due Date
              </th>
              <th className="min-w-[140px] text-left px-2 text-nowrap  border-l">Debit</th>
              <th className="min-w-[140px] text-left px-2 text-nowrap  border-l">Credit</th>
            </thead>
            <tbody>
              {Record.entries.map((item, index) => (
                <>
                  <tr
                    key={index}
                    className="border-b border-tertiary hover:bg-Azureish_White cursor-pointer"
                    onClick={() => {
                      let entries = Record.entries;
                      if (item.show) {
                        entries[index].show = false;
                      } else {
                        entries[index].show = true;
                      }
                      handleFieldChange("entries", entries);
                    }}
                  >
                    <td className="min-w-[380px] text-left px-2">
                      <span className="text-[12px]">
                        {item.show ? "▼ " : "► "}
                      </span>
                      {item.customerName}
                    </td>
                    <td className={"min-w-[140px] px-2 pt-1 border-l text-nowrap"}>
                      {item.ledgerName}
                    </td>
                    <td className={"min-w-[140px] px-2 pt-1 border-l"}>{""}</td>
                    <td className={"min-w-[140px] px-2 pt-1 border-l"}>{""}</td>
                    <td
                      className={
                        "min-w-[140px] px-2 pt-1 border-l" +
                        (item.totalLedgerDebit == 0
                          ? " text-Old_Silver "
                          : " text-primary font-medium ")
                      }
                    >
                      {RupeeFormat(item.totalLedgerDebit)}
                    </td>
                    <td
                      className={
                        "min-w-[140px] px-2 pt-1 border-l" +
                        (item.totalLedgerCredit == 0
                          ? " text-Old_Silver "
                          : " text-primary font-medium ")
                      }
                    >
                      {RupeeFormat(item.totalLedgerCredit)}
                    </td>
                  </tr>
                  {item.show &&
                    item.transactions.map((subitems, subindex) => (
                      <tr
                        key={subindex}
                        className="border-b border-tertiary hover:bg-Azureish_White cursor-pointer group"
                        onClick={() => {
                          switch (subitems.transactiontype_id) {
                            case 1:
                              store.navTo(
                                `/${URLPRIFIX}/${companyId}/accounts/invoice/view/${subitems.transaction_id}`
                              );
                              break;
                            case 2:
                              store.navTo(
                                `/${URLPRIFIX}/${companyId}/accounts/journal/view/${subitems.transaction_id}`
                              );
                              break;
                            case 3:
                              store.navTo(
                                `/${URLPRIFIX}/${companyId}/accounts/expense/view/${subitems.transaction_id}`
                              );
                              break;
                            case 4:
                              store.navTo(
                                `/${URLPRIFIX}/${companyId}/accounts/receipt/view/${subitems.transaction_id}`
                              );
                              break;
                            case 7:
                              store.navTo(
                                `/${URLPRIFIX}/${companyId}/accounts/payable/view/${subitems.transaction_id}`
                              );
                              break;

                            default:
                              break;
                          }
                        }}
                      >
                        <td className="min-w-[380px] text-[#344cb7] group-hover:underline pl-8">
                          {subitems.referenceno}
                        </td>
                        <td className={"min-w-[140px] px-2 pt-1 border-l text-nowrap"}>
                          {subitems.ledgerName}
                        </td>
                        <td className={"min-w-[140px] px-2 pt-1 border-l text-nowrap"}>
                          {subitems?.invoice_date?.split("T")[0]}
                        </td>
                        <td className={"min-w-[140px] px-2 pt-1 border-l text-nowrap"}>
                          {subitems.invoice_due_date?.split("T")[0]}
                        </td>
                        <td
                          className={
                            "min-w-[140px] px-2 pt-1 border-l" +
                            (subitems.transactionTotalDebit == 0
                              ? " text-Old_Silver "
                              : " text-primary font-medium ")
                          }
                        >
                          {RupeeFormat(subitems.transactionTotalDebit)}
                        </td>
                        <td
                          className={
                            "min-w-[140px] px-2 pt-1 border-l" +
                            (subitems.transactionTotalCredit == 0
                              ? " text-Old_Silver "
                              : " text-primary font-medium ")
                          }
                        >
                          {RupeeFormat(subitems.transactionTotalCredit)}
                        </td>
                      </tr>
                    ))}
                </>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </AccountsLayout>
  );
};

export default CustomerLedger;
