import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getFulllocal, getlocal, setlocal } from "../functions/localstorage";
import { saveRecord } from "../functions/api";

function Statehandler() {
  const navigator = useNavigate();
  const init = {
    system: { login: false, module: "" },
    records: {},
    properties: {},
  };
  const [State, setState] = useState(init);

  useEffect(() => {
    savestate(getFulllocal());
    console.log("State", State);
  }, []);

  function getSystem() {
    return (State && State.system) || {};
  }

  function savestate(newState) {
    setState((prev) => {
      prev = newState;
      return { ...prev };
    });
    setlocal(newState);
  }

  // Return function

  const functionList = {
    State,
    getvalue,
    // gettoken,
    // getmodule,
    // getLogin,
    // getsystemvalue,
    getHeader,
    // getmenu,
    // getuser,
    // setvaluetomodule,
    getprops,
    getRecord,

    setvalue,
    // settoken,
    // setmodule,
    // setprops,
    // setLogin,
    setsystemvalue,
    setheader,
    // setmenu,
    // setuser,
    // setvaluetomodule,

    navTo,
    navBack,

    login,
    // logout,
    reset,
    // navToNewTab,
    initModule,
  };

  function getRecord() {
    return (State && State.records && State.records[State.system.module]) || {};
  }
  function getprops() {
    return (
      (State && State.properties && State.properties[State.system.module]) || {}
    );
  }
  function getHeader() {
    return (State && State.system && State.system.header) || "header";
  }
  function getvalue(field) {
    let mod = State.system.module;
    return (
      State && State.records && State.records[mod] && State.records[mod][field]
    );
  }
  function navTo(Link) {
    navigator(Link);
  }
  function navBack() {
    navigator(-1);
  }
  function setvalue(field, value) {
    let state = { ...State };
    if (state === undefined) state = init;
    if (state.records === undefined) state.records = {};
    if (state.records[state.system.module] === undefined)
      state.records[state.system.module] = {};
    state.records[state.system.module][field] = value;
    savestate(state);
  }
  function setsystemvalue(field, value) {
    let state = { ...State };
    if (state === undefined) state = init;
    if (state.system === undefined) state.system = {};
    state.system[field] = value;
    savestate(state);
  }
  function setheader(value) {
    let state = { ...State };
    console.log("heaser", state);

    // if (state === undefined) state = init;
    // if (state.system === undefined) state.system = {};
    state.system.header = value;
    savestate(state);
  }
  function login() {
    let state = { ...State };
    if (state === undefined) state = init;
    if (state.system === undefined) state.system = {};
    state.system.login = true;
    savestate(state);
  }
  function initModule(moduleName, initObject, header) {
    let state = getFulllocal();

    if (state === undefined) state = init;

    state.system.module = moduleName;
    state.system.header = header;
    document.title = state.system.header;

    if (!Object.keys(state.records).includes(moduleName)) {
      if (state.records === undefined) state.records = {};
      state.records[moduleName] = initObject || {};
    }
    console.log("Init", state);

    savestate(state);
    // return { setheader };
  }
  function reset(record) {
    let state = { ...State };
    if (state === undefined) state = init;
    if (state.records === undefined) state.records = {};
    state.records[state.system.module] = record;
    savestate(state);
  }

  return functionList;
}

export default Statehandler;
