import React from "react";
import { PrimaryBTN } from "../Constancs/cssconst";
import { useStore } from "../Components/StateProvider";

const UnAuthorized401 = () => {
  const store = useStore()
  return (
    <div className="h-screen bg-secondary flex justify-center items-center">
      <button
        className={
          "bg-primary text-secondary py-3 px-5 rounded-xl h-fit w-fit font-bold flex gap-8 absolute top-10 left-10"
        }
        onClick={()=>{
          store.navTo(-1)
        }}
      >
        <span>←</span> <span>Back</span>
      </button>
      <img className="h-full" src="/Assets/images/Unauthorized.svg" />
    </div>
  );
};

export default UnAuthorized401;
