import React, { useEffect } from "react";

const ListView = ({
  width = "100%",
  height = "90%",
  header = [],
  records = [],
  onedit,
  onview,
  ondelete,
  onclick,
  Tablehead = " min-w-[150px] pr-[10px] max-sm:text-[26px] md:text-[20px] lg:text-[16px] xl:text-[20px] font-bold ",
  Tablerecord = "min-w-[150px] pr-[10px] max-sm:text-[26px] md:text-[20px] lg:text-[12px] xl:text-[16px] overflow-hidden",
}) => {
  return (
    <div
      className={`flex  overflow-auto `}
      style={{ height: height, width: width }}
    >
      <table className="table-auto w-full h-fit">
        <thead className="sticky top-0 bg-secondary text-left mt-10 border-b">
          <tr className="px-1">
            {header.map(([name, label], index) => {
              if (label !== "id") {
                return (
                  <th key={index} className={Tablehead}>
                    {name}
                  </th>
                );
              }
            })}
          </tr>
        </thead>

        <tbody>
          {records.map((item, index) => (
            <tr
              key={index}
              className={
                "border-b h-8 hover:bg-[#b3b3f5] rounded-xl " +
                (item.error && ` bg-red bg-opacity-35 `)
              }
            >
              {header.map(([name, label], fieldindex) => {
                // console.log("Listview actioon",field == "ACTION" && (ondelete!=undefined||onview!=undefined||onedit!=undefined),(ondelete!=undefined||onview!=undefined||onedit!=undefined));

                if (label == "ACTION") {
                  return (
                    <td key={fieldindex} className={Tablerecord}>
                      <div className="flex gap-7">
                        {onedit && (
                          <img
                            src="/Assets/images/pencil.png"
                            className=" cursor-pointer w-5 h-5 hover:scale-125"
                            onClick={() => {
                              onedit(item["id"]);
                            }}
                          />
                        )}
                        {onview && (
                          <img
                            src="/Assets/images/eye.png "
                            className=" cursor-pointer w-5 h-5 hover:scale-125"
                            onClick={() => {
                              onview(item["id"]);
                            }}
                          />
                        )}
                        {ondelete && (
                          <img
                            src="/Assets/images/trash.png"
                            className=" cursor-pointer w-5 h-5 hover:scale-125"
                            onClick={() => {
                              const confirmDelete = window.confirm("Are you sure you want to delete?");
                              if (confirmDelete) {
                                ondelete(item["id"]);
                              }
                            }}
                          />
                        )}
                      </div>
                    </td>
                  );
                }  else if (label === "activeStatus") {
                  return (
                    <td key={fieldindex} className={Tablerecord}>
                      {item[label] ? "Active" : "Inactive"}
                    </td>
                  );
                }else if (
                  label.toLowerCase().includes("date") &&
                  item[label] != undefined
                ) {
                  const [date, time] = item[label].split("T");
                  if (time != null && time != undefined) {
                    // console.log("Time ", date, time);

                    return (
                      
                      <td
                        key={fieldindex}
                        className={Tablerecord}
                        onClick={() => {
                          onclick && onclick(item["id"]);
                        }}
                      >
                        {date}
                      </td>
                    );
                  }
                   else {
                    return (
                      <td key={fieldindex} className={Tablerecord}>
                        ---
                      </td>
                    );
                  }
                } else if (label != "id") {
                  return (
                    <td
                      key={fieldindex}
                      className={Tablerecord}
                      onClick={() => {
                        onclick && onclick(item["id"]);
                      }}
                    >
                      {item[label]}
                    </td>
                  );
                } else {
                  <td key={fieldindex} className={Tablerecord}>
                    ---
                  </td>;
                }
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ListView;
