import { useNavigate, useParams } from "react-router-dom";
import { useStore } from "../../../Components/StateProvider";
import { useEffect, useState } from "react";
import {
  getlocal,
  getStorage,
  setStorage,
} from "../../../functions/localstorage";
import {
  baseurl,
  ErrorAlert,
  getList,
  GetListDropdown,
  GetRecords,
  saveRecord,
  SuccessAlert,
  UpdateRecord,
  UploadFile,
} from "../../../functions/api";
import AccountsLayout from "../AccountsLayout";
import FormView from "../../../Components/Elements/FormView";
import { MdOutlineFileUpload } from "react-icons/md";
import { FaRegEye } from "react-icons/fa6";
import { HiOutlineArrowSmDown, HiOutlineArrowSmUp } from "react-icons/hi";
import { PrimaryBTN } from "../../../Constancs/cssconst";
import { FaArrowRight, FaPenNib, FaTruckLoading } from "react-icons/fa";
import { TiTick } from "react-icons/ti";
import {
  Account_Group_List,
  GroupList,
  URL_Account_Charts_Create,
  URL_Account_Charts_Update,
  URL_Account_Group_List,
} from "../../../Constancs/apiconst";

function FormHeader({
  State = "New",
  FormName = "",
  FormNumber = "",
  Actions = [],
}) {
  return (
    <div className="flex items-center justify-between bg-secondary h-[38px] rounded-lg p-1 shadow-[0px_0px_10px_3px_rgba(0,0,0,0.1)] ">
      <div className="flex gap-3 h-full items-center">
        <span className=" text-[14px] border flex items-center px-1 py-0.5 rounded-lg">
          {State}
        </span>
        <span className="text-[14px]  flex flex-col">
          <span className="text-[12px] font-bold text-Ocean_Blue">
            {FormName}
          </span>
          <span className="text-[14px] font-semibold ">{FormNumber}</span>
        </span>
      </div>
      <div className="flex gap-3 h-full items-center mr-8">
        {Actions.map((item, index) => {
          if (item.visible)
            return (
              <button
                key={index}
                className={
                  "border px-2 py-1 rounded-lg font-semibold hover:bg-tertiary " +
                  (item.type === "primary"
                    ? " bg-Ocean_Blue text-secondary "
                    : " bg-Alice_Blue ")
                }
                onClick={item.onClick}
              >
                {item.ButtonName}
              </button>
            );
        })}
      </div>
    </div>
  );
}

function FormStage({ Stages = [] }) {
  return (
    <div className="flex items-center justify-center h-[38px] gap-3">
      {Stages.map((stage, index) => (
        <div key={index} className=" flex gap-1 items-center">
          <span
            className={
              " border p-1 rounded-full text-[14px] " +
              (stage.selected
                ? " bg-Ocean_Blue text-secondary "
                : stage.completed
                ? " bg-[#3ec95c] text-secondary "
                : " ")
            }
          >
            {stage.completed ? <TiTick /> : stage.icon}
          </span>
          <h3 className="text-[14px] pr-3">{stage.Name}</h3>
          {index < Stages.length - 1 ? <FaArrowRight /> : ""}
        </div>
      ))}
    </div>
  );
}

const LedgerForm = () => {
  const { companyId, option, ledgerId } = useParams();
  const navigate = useNavigate();
  const getrecord = `/api/v2/ChartsOfAccounts/getchartsofaccounts/${ledgerId}`;
  const attachment_Prefix = "Journal_";
  const store = useStore();
  let storage = getStorage();

  // *initial values
  const init = {
    ledger_name: "",
    // updated_ob: "",
    opening_balance: "",
    group_id: "",
  };
  const initProps = {
    add: false,
    delete: false,
    on_off: false,
    print: false,
    update: false,
    view: false,

    ledger_name: { hidden: false, mandatory: true, readonly: false },
    // updated_ob: {},
    opening_balance: { hidden: false, mandatory: true, readonly: false },
    group_id: {
      group_id_list: [{ label: "Please Choose", value: "" }],
    },
  };

  // *states declaration for records and props
  const [Record, setRecord] = useState(init);
  const [props, setprops] = useState(initProps);

  // *initial values set on the page load
  // *and change on dependencies of option and id changes
  useEffect(() => {
    console.log("Ledger ledgerId", ledgerId);
    // *Get the Group list For the group_id Field

    getList({ listUrl: URL_Account_Group_List, count: 1000 })
      .then((res) => {
        if (res.success) {
          res = res.data.list || [];

          let group_id_list = [...initProps.group_id.group_id_list];

          // *Load List to the group_id Format
          res?.map((group) => {
            group_id_list.push({
              value: group?.id || "",
              label: group?.account_core_name || "",
            });
          });

          // *Load Data to the props
          HandlePropsChange("group_id", { ...props.group_id, group_id_list });
        }
      })
      .then(() => {
        if (ledgerId != undefined) {
          GetRecords(getrecord).then((res) => {
            if (res.success) {
              res = res.record[0];
              console.log("Get_the_Ledger", res);

              handleMultiFieldChange({
                ...Record,
                ledger_name: res.ledger_name,
                group_id: res.group_id,
                //opening_balance:res.updated_ob,
                opening_balance: res.opening_balance,
              });
            }
          });
          if (option === "view") {
            HandleMultiPropsChange({
              ledger_name: { readonly: true },
              group_id: { readonly: true },
              opening_balance: { readonly: true },
            });
          } else {
            HandleMultiPropsChange({
              ledger_name: { mandatory: true },
              group_id: { mandatory: true },
              opening_balance: { readonly: true },
            });
          }
        } else {
          HandleMultiPropsChange({
            ledger_name: { mandatory: true },
            group_id: { mandatory: true },
            opening_balance: { mandatory: true },
          });
        }
      });
  }, [option, ledgerId]);

  // *print the Record and Props if any value change between the Record and Props
  useEffect(() => {
    console.log("Record State change -> ", Record);
    console.log("Props State change -> ", props);
  }, [Record, props]);

  // *create Or update the Record
  function update() {
    store.startLoading();
    let savedata = { ...Record };
    savedata.modified_by = store.getuser().id;
    savedata.company_id = companyId;
    console.log("Submiting savedata", savedata);
    if (CheckMandatory()) {
      UpdateRecord(URL_Account_Charts_Update + ledgerId, savedata).then(
        (res) => {
          console.log("Resonce of saveRecord - ", res);
          if (res.success) {
            store.stopLoading();
            SuccessAlert("Ledger Updated Successfully");
            store.navback();
          } else {
            store.stopLoading();
            ErrorAlert(
              res?.errormessage?.response?.data?.errorMessage ||
                " Error Found Please contact the Admin"
            );
          }
        }
      );
    }
  }
  function Submit() {
    store.startLoading();
    let savedata = { ...Record };
    savedata.modified_by = store.getuser().id;
    savedata.company_id = companyId;
    savedata.createdBy = store.getuser().id;
    console.log("Submiting savedata", savedata);
    if (CheckMandatory()) {
      saveRecord(URL_Account_Charts_Create, savedata).then((res) => {
        console.log("Resonce of saveRecord - ", res);
        if (res.success) {
          store.stopLoading();
          SuccessAlert("Ledger Submitted Successfully");
          store.navback();
        } else {
          store.stopLoading();
          ErrorAlert(
            res?.errormessage?.response?.data ||
              "Error Found Please contact the Admin"
          );
        }
      });
    }
  }
  // *Check for the mandatory fields if empty or not
  function CheckMandatory() {
    let out = true;
    for (let obj in props) {
      if (typeof props[obj] === "object") {
        if (props[obj].mandatory) {
          console.log(
            "CheckMandatory",
            obj,
            Record[obj],
            Record[obj] === "",
            Record[obj] === " "
          );

          if (Record[obj] === "" || Record[obj] === " ") {
            HandlePropsChange(obj, { mandatory: true, show: true });
            out = false;
          } else {
            HandlePropsChange(obj, { mandatory: true, show: false });
          }
        }
      }
    }
    out === false && ErrorAlert("Please fill the Mandatory Fields.");
    out === false && store.stopLoading();
    return out;
  }

  // *Handle Fields changes in Record States
  function handleFieldChange(fieldName, value) {
    setRecord((prev) => {
      return { ...prev, [fieldName]: value };
    });
  }
  function handleMultiFieldChange(value = {}) {
    setRecord((prev) => {
      for (let fieldName in value) {
        prev[fieldName] = value[fieldName];
      }
      return { ...prev };
    });
  }
  // *Handle Props Changes in Props States
  function HandlePropsChange(fieldName, value = {}) {
    setprops((prev) => {
      let oldprop = prev[fieldName];

      for (let prophead in value) {
        oldprop[prophead] = value[prophead];
      }
      prev[fieldName] = oldprop;
      return { ...prev };
    });
  }
  function HandleMultiPropsChange(NewProps = {}) {
    setprops((prev) => {
      for (let fieldName in NewProps) {
        let oldprop = prev[fieldName] || {}; // Ensure oldprop is initialized
        let newprop = NewProps[fieldName];
        console.log("typeof newprop", typeof newprop);

        // Check if newprop is an object
        if (typeof newprop === "object") {
          for (let prophead in newprop) {
            // Ensure oldprop is not undefined before trying to update its properties
            oldprop[prophead] = newprop[prophead];
          }
          // Update the prev state with the modified oldprop
          prev[fieldName] = oldprop;
        } else {
          // If newprop is not an object, directly update prev[fieldName]
          prev[fieldName] = newprop;
        }
      }
      console.log("updated props", prev);

      return { ...prev }; // Return a copy of the updated state
    });
  }

  // function HandleMultiPropsChange(NewProps = {}) {
  //   setprops((prev) => {
  //     for (let fieldName in NewProps) {
  //       let oldprop = prev[fieldName];
  //       let newprop = NewProps[fieldName];
  //       console.log("typeof newprop", typeof newprop);

  //       if (typeof newprop === "object") {
  //         for (let prophead in newprop) {
  //           oldprop[prophead] = newprop[prophead];
  //         }
  //         prev[fieldName] = oldprop;
  //       } else {
  //         prev[fieldName] = newprop;
  //       }
  //     }
  //     console.log("updated props", prev);

  //     return { ...prev };
  //   });
  // }

  // *Handle Change in the fields
  function HandleCustomer(value) {}

  return (
    <AccountsLayout HeaderTitle={"Charts of Accounts"}>
      <div className="flex flex-col h-full text-[12px]">
        <FormHeader
          // State="Draft"
          FormName="Ledger"
          FormNumber={
            Record.ledger_name === "" ? "New Ledger" : Record.ledger_name
          }
          Actions={[
            {
              ButtonName: "Submitted",
              type: "primary",
              onClick: Submit,
              visible: ledgerId === undefined,
            },
            {
              ButtonName: "Update",
              type: "primary",
              onClick: update,
              visible: option === "edit" && ledgerId !== undefined,
            },
            {
              ButtonName: "Cancel",
              onClick: () => {
                navigate(-1);
              },
              visible: true,
            },
          ]}
        />
        {/* <FormStage Stages={props.Stages.Stages_list} /> */}
        <div className="flex bg-secondary flex-col items-center flex-grow rounded-lg shadow-[0px_0px_10px_3px_rgba(0,0,0,0.1)] overflow-auto">
          <div className="grid grid-cols-1 gap-2 w-[80%] mt-5">
            <div // ledger_name
              className={
                "text-[14px] w-full h-full px-3 flex-row items-center gap-3 " +
                (props.ledger_name.hidden ? " hidden " : " flex ")
              }
            >
              <label className={"w-fit  min-w-[150px] max-w-full"}>
                Ledger Name
                {props.ledger_name.mandatory && (
                  <span className="text-[#ff2828] text-[14px] h-fit">*</span>
                )}
              </label>
              <input
                className={
                  "w-full border-b border-Old_Silver border-opacity-35 px-2 py-1 text-Old_Silver disabled:bg-[#c6c6ca] disabled:bg-opacity-20 outline-none " +
                  (props.ledger_name.mandatory && props.ledger_name.show
                    ? " rounded-2xl border-2 border-[#ff2828] "
                    : "")
                }
                id={"ledger_name"}
                type="text"
                value={Record.ledger_name}
                disabled={props.ledger_name.readonly}
                onChange={(event) => {
                  handleFieldChange("ledger_name", event.target.value);
                }}
              />
            </div>
            <div // group_id
              className={
                "flex text-[14px] w-full h-full px-3 flex-row items-center gap-3 " +
                (props.group_id.hidden ? " hidden " : " flex ")
              }
            >
              <label className={"w-fit  min-w-[150px] max-w-full"}>
                Group Name
                {props.group_id.mandatory && (
                  <span className="text-[#ff2828] text-[14px] h-fit">*</span>
                )}
              </label>
              <select
                id={"group_id"}
                onDragEnd={() => {
                  console.log("At end arraived");
                }}
                className={
                  " w-full border-b border-Old_Silver border-opacity-35 px-2 py-1 text-Old_Silver disabled:bg-[#c6c6ca] disabled:bg-opacity-20   outline-none " +
                  (props.group_id.mandatory && props.group_id.show
                    ? " rounded-2xl border-2 border-[#ff2828] "
                    : "")
                }
                onChange={(event) => {
                  handleFieldChange("group_id", event.target.value);
                  // HandleCustomer(event.target.value);
                  // TotalCalculation();
                }}
                value={Record.group_id}
                disabled={props.group_id.readonly}
                aria-placeholder="please select sam"
              >
                {props.group_id.group_id_list.map((item, index) => (
                  <option key={index} value={item.value}>
                    {item.label}
                  </option>
                ))}
              </select>
            </div>
            <div // updated_ob
              className={
                "text-[14px] w-full h-full px-3 flex-row items-center gap-3 " +
                (props.opening_balance.hidden ? " hidden " : " flex ")
              }
            >
              <label className={"w-fit  min-w-[150px] max-w-full"}>
                Opening Balance
                {props.opening_balance.mandatory && (
                  <span className="text-[#ff2828] text-[14px] h-fit">*</span>
                )}
              </label>
              <input
                className={
                  "w-full border-b border-Old_Silver border-opacity-35 px-2 py-1 text-Old_Silver disabled:bg-[#c6c6ca] disabled:bg-opacity-20 outline-none " +
                  (props.opening_balance.mandatory && props.opening_balance.show
                    ? " rounded-2xl border-2 border-[#ff2828] "
                    : "")
                }
                id={"opening_balance"}
                type="number"
                value={Record.opening_balance}
                disabled={props.opening_balance.readonly}
                onChange={(event) => {
                  handleFieldChange("opening_balance", event.target.value);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </AccountsLayout>
  );
};

export default LedgerForm;
