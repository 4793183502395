import React from "react";

const LoadingSpinner = ({Message = "Loading"}) => {
  
  return (
    <div className="absolute top-0 left-0 w-full h-full bg-Alice_Blue bg-opacity-75 z-40 rounded-3xl">
      <div className="flex-col h-full gap-4 flex items-center justify-center">
        <div className="flex space-x-2 items-baseline">
          <div className="text-primary text-[28px] font-bold">{Message}</div>

          <div
            className="w-4 h-4 bg-primary rounded-full animate-dotBounce"
            style={{ animationDelay: "0.0s" }}
          ></div>
          <div
            className="w-4 h-4 bg-primary rounded-full animate-dotBounce"
            style={{ animationDelay: "0.2s" }}
          ></div>
          <div
            className="w-4 h-4 bg-primary rounded-full animate-dotBounce"
            style={{ animationDelay: "0.4s" }}
          ></div>
        </div>
        <span className="loading loading-dots loading-lg"></span>
      </div>
    </div>
  );
};

export default LoadingSpinner;
