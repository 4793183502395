import React, { useEffect, useState } from "react";
import {
  contentHeader,
  TableHeader,
  TableText,
} from "../../Constancs/cssconst";
import {
  ErrorAlert,
  getList,
  GetListDropdown,
  saveRecord,
  SuccessAlert,
  UpdateRecord,
  URLPRIFIX,
} from "../../functions/api";
import { IoChevronForwardOutline } from "react-icons/io5";
import { getlocal } from "../../functions/localstorage";
import Dropdown from "../Fields/Dropdown";

const TaskAssignment = ({ store, props }) => {
  const [task, settask] = useState("");
  useEffect(() => {
    const user = getlocal("system")?.user || {};

    getList({
      listUrl: "/subtask/getsubtasklist",
      field: user?.role == "1" ? "status" : "assigned_to",
      search: user?.role == "1" ? "" : user?.id,
      sort: "asc",
      page: 1,
      count: 100,
    }).then((res) => {
      console.log("getLoadtask catagaory", res);
      store.setvalue("assignTask", res?.data?.list);
    });
  }, []);

  useEffect(() => {
    let taskrecord = getlocal("records")?.dashboard;

    GetListDropdown({
      listUrl: "/users/getusers",
      field: "userName",
      sort: "asc",
      count: 100,
      store: store,
    });

    if (
      taskrecord?.assignTask != undefined &&
      taskrecord?.opentask != undefined &&
      taskrecord?.opentask != -1
    ) {
      settask(taskrecord?.assignTask[taskrecord?.opentask].assigned_to);
    }
  }, []);

  useEffect(() => {
    let taskrecord = getlocal("records")?.dashboard;
    console.log(
      "task-",
      task,
      taskrecord,
      taskrecord?.assignTask != undefined,
      taskrecord?.opentask != undefined,
      taskrecord?.opentask != -1
    );
  }, [task]);

  function Submit(data) {
    console.log("Submit", data);
    data.modifiedBy = store.getuser().id;
    if (typeof data.activeStatus === "string") {
      data.activeStatus = data.activeStatus == "true" ? true : false;
    }
    UpdateRecord("/subtask/updatesubtask/" + data.id, data).then((res) => {
      if (res.success) {
        SuccessAlert("Task Assigned Successfully");
      } else {
        ErrorAlert(
          "Unable to save the Task Assigned as unchoose\n\nPlease Choose assigned to Field"
        );
      }
    });
  }

  return (
    <>
      <h1 className={contentHeader}>Task Assignments</h1>
      {props?.tasks?.add && (
        <div
          className="absolute right-5 top-4 text-[18px] bg-primary lg:h-6 lg:w-6 h-9 w-9 flex justify-center items-center rounded-full  text-secondary cursor-pointer"
          onClick={() => {
            store.navTo("/" + URLPRIFIX + "/tasks/create");
          }}
        >
          +
        </div>
      )}
      <div className="xl:h-[360px] lg:h-[280px] overflow-x-auto flex items-center w-full flex-col gap-4">
        {store.getvalue("assignTask") &&
          store.getvalue("assignTask").map((item, index) => (
            <div
              key={index}
              className={
                "bg-secondary w-full ease-linear transition-all rounded-2xl " +
                (store.getvalue("opentask") == index
                  ? " h-[200px] "
                  : " h-[30px] hover:bg-[#a9a9ff] hover:bg-opacity-45 ")
              }
              onClick={() => {
                // store.navTo("/" + URLPRIFIX + "/tasks/view/" + item.id);
                if (store.getvalue("opentask") !== index) {
                  store.setvalue("opentask", index);
                  settask(item.assigned_to);
                }
              }}
            >
              <div
                className={
                  " w-full flex relative  " +
                  (store.getvalue("opentask") == index
                    ? " flex-col px-3 pt-2 "
                    : " items-center h-full px-3 cursor-pointer  ")
                }
              >
                <h1
                  className={
                    "text-primary font-bold " +
                    (store.getvalue("opentask") == index
                      ? " text-[18px] "
                      : " text-[14px]  ")
                  }
                >
                  {item.subtask_title}
                </h1>
                {store.getvalue("opentask") !== index && <h1>-</h1>}
                <h1
                  className={
                    store.getvalue("opentask") == index
                      ? " text-[10px] bg-Jasmine w-fit px-2 py-[2px] rounded-r-xl "
                      : " text-[14px] "
                  }
                >
                  {item.status}
                </h1>
                {store.getvalue("opentask") !== index && (
                  <div className="absolute right-3 bg-secondary">
                    <IoChevronForwardOutline />
                  </div>
                )}
              </div>

              {store.getvalue("opentask") == index && (
                <div className="px-3 pt-2 bounce_animation text-[12px] gap-1 flex flex-col ">
                  <h1>
                    Client Name: <span>{item.clientName}</span>{" "}
                  </h1>
                  <h1>
                    Due Date: <span>{item?.dueDate?.split("T")[0]}</span>{" "}
                  </h1>
                  <h1>
                    Assigned To:{" "}
                    <select
                      onChange={(e) => {
                        item.assigned_to = e.target.value;
                        settask(e.target.value);
                      }}
                      value={task + ""}
                    >
                      <option value={""}>Choose Person</option>
                      {store.getprops("userName_list") &&
                        store.getprops("userName_list").map((item, index) => (
                          <option key={index} value={item.value}>
                            {item.label}
                          </option>
                        ))}
                    </select>
                  </h1>
                </div>
              )}
              {store.getvalue("opentask") == index && (
                <div className="px-3 text-[12px] gap-2 flex py-2 bounce_animation ">
                  <button
                    className="bg-tertiary px-3 py-1 rounded-2xl font-bold hover:scale-110 "
                    onClick={() => {
                      store.setvalue("opentask", -1);
                      settask(-1);
                    }}
                  >
                    Close
                  </button>
                  <button
                    className="bg-tertiary px-3 py-1 rounded-2xl font-bold  hover:scale-110"
                    onClick={() => {
                      store.navTo("/" + URLPRIFIX + "/tasks/view/" + item.id);
                    }}
                  >
                    View
                  </button>
                  {props?.task?.update && (
                    <button
                      className="bg-primary text-secondary px-3 py-1 rounded-2xl font-bold  hover:scale-110"
                      onClick={() => {
                        Submit(item);
                      }}
                    >
                      Assign
                    </button>
                  )}
                </div>
              )}
            </div>
          ))}
      </div>
    </>
  );
};

export default TaskAssignment;
