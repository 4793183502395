import DatePicker from "../Components/Fields/DatePicker";
import Dropdown from "../Components/Fields/Dropdown";
import Journal from "../Components/Fields/Journal";
import String from "../Components/Fields/String";
import FormView from "../Components/Elements/FormView";
import ListView from "../Components/Elements/ListView";
import { useStore } from "../Components/StateProvider";
import {
  GetAllLitigation,
  getList,
  GetListDropdown,
  GetListview,
  GetLitigation,
  Setlists,
  SetLitigation,
  URLPRIFIX,
} from "../functions/api";
import React, { useEffect, useState } from "react";
import { FaSearch } from "react-icons/fa";
import { useParams } from "react-router-dom";

const NonLitigation = () => {
  const params = useParams();

  const store = useStore();

  const [categories, setcategories] = useState({
    clientlist: [],
    contentscreen: false,
    Contentform: {},
    projects: [],
    documents: [],
    Tasks: [],
  });

  const init = {};

  const category = {
    all: "1",
    hotel: "2",
    Film: "1",
    Rental: "3",
  };

  useEffect(() => {
    store.setmodule(params.category, init);
    store.stopLoading();
    
    store.setheader(
      "category - " +
        (params.category === "all" ? "HOTEL" : params.category.toUpperCase())
    );

    getList({
      listUrl: "/contact/getcontacts",
      field: "categoryName",
      search: params.category,
      sort: "asc",
      page: 1,
      count: 100,
    }).then((res) => {
      console.log("getList catagaory", res);
      setcategories((prev) => {
        prev.clientlist = res.data.list.map((item) => {
          item.open = false;
          return item;
        });
        console.log("getList catagaory", res);
        return { ...prev };
      });
    });
    getList({
      listUrl: "/subtask/getsubtasklist",
      field: "status",
      search: "inprogress",
      sort: "asc",
      page: 1,
      count: 20,
    }).then((res) => {
      console.log("getList catagaory", res);
      setcategories((prev) => {
        prev.Tasks = res.data.list;
        console.log("getList catagaory", res);
        return { ...prev };
      });
    });
  }, [params.category]);

  useEffect(() => {
    console.log("categories ->", categories);
  }, [categories]);

  function openClient(client_Id) {
    getList({
      listUrl: "/project/getprojectlist",
      field: "contact_id",
      search: client_Id,
      sort: "asc",
      page: 1,
      count: 100,
    }).then((res) => {
      console.log("Client list", res);
      setcategories((prev) => {
        prev.clientlist = prev.clientlist.map((item) => {
          if (client_Id === item.id) {
            item.open = true;
            item.header = "Client";
            prev.Contentform = item;
            prev.contentscreen = true;

            init.clientName = item.clientName;
            init.phoneno = item.phoneno;
            init.email = item.email;
            init.film_name = item.film_name;
            init.address = item.address;
            Object.keys(category).filter((cato) => {
              if (category[cato] == item.category_ID) {
                init.category = cato;
              }
            });
            init.URL = item.url;
            init.activestatus = true;
            store.reset(init);
          } else {
            item.open = false;
          }
          return item;
        });
        prev.projects = res.data.list.map((item) => {
          item.open = false;
          return item;
        });

        console.log("getList catagaory", res);
        return { ...prev };
      });
    });
  }
  function openproject(project_id) {
    getList({
      listUrl: "/tasks/getprojectstasklist",
      field: "project_id",
      search: project_id,
      sort: "asc",
      page: 1,
      count: 100,
    }).then((res) => {
      console.log("Projecr list", res);
      setcategories((prev) => {
        prev.projects = prev.projects.map((item) => {
          if (project_id === item.id) {
            item.open = true;
            item.header = "Project";
            prev.Contentform = item;
            prev.contentscreen = true;
            init.projectName = item.projectName;
            init.film_name = item.film_name;
            init.clientName = item.clientName;
            init.projectDescription = item.projectDescription;
            init.startDate = item.startDate;
            init.endDate = item.endDate;
            init.priority = item.priority;
            init.status = item.status;
            init.activeStatus = item.activeStatus;
            store.reset(init);
            GetListDropdown({
              listUrl: "/forms/getformslist",
              field: "name_of_Artist",
              sort: "asc",
              count: 100,
              store: store,
            });
          } else {
            item.open = false;
          }
          return item;
        });
        prev.documents = res.data.list.map((item) => {
          item.open = false;
          return item;
        });
        console.log("getList catagaory", res);
        return { ...prev };
      });
    });
  }
  function openDocument(Documnent_id) {
    getList({
      listUrl: "/subtask/getsubtasklist",
      field: "task_id",
      search: Documnent_id,
      sort: "asc",
      page: 1,
      count: 100,
    }).then((res) => {
      console.log("Doc list", res);
      setcategories((prev) => {
        prev.documents = prev.documents.map((item) => {
          if (Documnent_id === item.id) {
            console.log("Documnent", item);

            item.open = true;
            item.header = "Document";
            prev.Contentform = item;
            prev.contentscreen = true;
            init.project_id = item.project_id;
            init.form_id = item.form_id;
            init.assigned_to = item.assigned_to;
            init.task_title = item.task_title;
            init.dueDate = item.dueDate;
            init.priority = item.priority;
            init.status = item.status;
            init.description = item.description;
            init.activeStatus = item.activeStatus;
            store.reset(init);
            GetListDropdown({
              listUrl: "/project/getprojectlist",
              field: "projectName",
              sort: "asc",
              count: 100,
              store: store,
            });
            GetListDropdown({
              listUrl: "/users/getusers",
              field: "userName",
              sort: "asc",
              count: 100,
              store: store,
            });
          } else {
            item.open = false;
          }
          return item;
        });
        prev.Tasks = res.data.list.map((item) => {
          item.open = false;
          return item;
        });
        console.log("getList catagaory", res);
        return { ...prev };
      });
    });
  }
  function openTask(Task_id) {
    setcategories((prev) => {
      prev.Tasks = prev.Tasks.map((item) => {
        if (Task_id === item.id) {
          item.open = true;
          item.header = "Task";
          prev.Contentform = item;
          prev.contentscreen = true;
          init.project_id = item.project_id;
          init.form_id = item.form_id;
          init.task_id = item.task_id;
          init.subtask_title = item.subtask_title;
          init.assigned_to = item.assigned_to;
          init.dueDate = item.dueDate;
          init.priority = item.priority;
          init.status = item.status;
          init.description = item.description;
          init.activeStatus = item.activeStatus;
          store.reset(init);
          GetListDropdown({
            listUrl: "/project/getprojectlist",
            field: "projectName",
            sort: "asc",
            count: 100,
            store: store,
          });
          GetListDropdown({
            listUrl: "/users/getusers",
            field: "userName",
            sort: "asc",
            count: 100,
            store: store,
          });
          GetListDropdown({
            listUrl: "/tasks/getprojectstasklist",
            field: "task_title",
            sort: "asc",
            count: 100,
            store: store,
          });
        } else {
          item.open = false;
        }
        return item;
      });
      return { ...prev };
    });
  }
  function closeClient() {
    setcategories((prev) => {
      prev.clientlist = prev.clientlist.map((item) => {
        item.open = false;
        return item;
      });
      prev.contentscreen = false;
      return { ...prev };
    });
  }
  function onSearch(search) {
    getList({
      listUrl: "/contact/getcontacts",
      field: "categoryName",
      search: search,
      sort: "asc",
      page: 1,
      count: 100,
    }).then((res) => {
      console.log("getList catagaory", res);
      setcategories((prev) => {
        prev.clientlist = res.data.list.map((item) => {
          item.open = false;
          return item;
        });
        console.log("getList catagaory", res);
        return { ...prev };
      });
    });
  }

  return (
    <div className=" h-full flex">
      <div className="bg-tertiary h-[95%] w-[180px] py-6 flex flex-col items-center rounded-3xl gap-3">
        <h1 className="text-[18px] font-bold text-primary">CLIENTS</h1>
        {/* <div className="bg-secondary p-1 rounded-3xl  border mx-3">
          <String
            name="search"
            label={<FaSearch />}
            onchange={onSearch}
            placeholder="Search"
            className={{ input: "w-full ", field: "", lable: "w-fit" }}
          />
        </div> */}
        <div
          className="overflow-x-auto w-full"
          style={{ scrollbarWidth: "thin" }}
        >
          {categories.clientlist.length > 0 ? (
            categories.clientlist.map((client, index) => {
              if (client.open) {
                return (
                  <div
                    key={index}
                    className="p-2 mb-2 rounded-r-xl mr-3 text-[14px] bg-secondary border-l-2 border-primary "
                  >
                    <div
                      className="font-semibold cursor-pointer"
                      onClick={() => {
                        closeClient();
                      }}
                    >
                      {client.clientName}
                    </div>
                    {categories.projects.length > 0
                      ? categories.projects.map((project, index) => {
                          if (project.open) {
                            return (
                              <div
                                key={index}
                                className="p-2  mb-2 rounded-r-xl mr-3 bg-secondary border-l-2 border-primary "
                              >
                                <div
                                  className="font-bold cursor-pointer"
                                  onClick={() => {
                                    openproject(project.id);
                                  }}
                                >
                                  {project.projectName}
                                </div>
                                {categories.documents.length > 0
                                  ? categories.documents.map(
                                      (document, index) => {
                                        if (document.open) {
                                          return (
                                            <div
                                              key={index}
                                              className="p-2  mb-2 rounded-r-xl mr-3 bg-secondary border-l-2 border-primary "
                                            >
                                              <div
                                                className="font-bold cursor-pointer"
                                                onClick={() => {
                                                  openDocument(document.id);
                                                }}
                                              >
                                                {document.task_title}
                                              </div>
                                              {categories.Tasks.length > 0
                                                ? categories.Tasks.map(
                                                    (Task, index) => {
                                                      if (Task.open) {
                                                        return (
                                                          <div
                                                            key={index}
                                                            className="p-2  mb-2 rounded-r-xl mr-3 bg-secondary border-l-2 border-primary "
                                                          >
                                                            <div
                                                              className="font-bold cursor-pointer"
                                                              onClick={() => {
                                                                openTask(
                                                                  Task.id
                                                                );
                                                              }}
                                                            >
                                                              {
                                                                Task.subtask_title
                                                              }
                                                            </div>
                                                          </div>
                                                        );
                                                      } else {
                                                        return (
                                                          <div
                                                            key={index}
                                                            className="p-2 text-center  mb-2 rounded-xl text-[14px] mx-3 cursor-pointer bg-secondary"
                                                            onClick={() => {
                                                              openTask(Task.id);
                                                            }}
                                                          >
                                                            {Task.subtask_title}
                                                          </div>
                                                        );
                                                      }
                                                    }
                                                  )
                                                : ""}
                                            </div>
                                          );
                                        } else {
                                          return (
                                            <div
                                              key={index}
                                              className="p-2 text-center  mb-2 rounded-xl text-[14px] mx-3 cursor-pointer bg-secondary"
                                              onClick={() => {
                                                openDocument(document.id);
                                              }}
                                            >
                                              {document.task_title}
                                            </div>
                                          );
                                        }
                                      }
                                    )
                                  : ""}
                              </div>
                            );
                          } else {
                            return (
                              <div
                                key={index}
                                className="p-1 text-center mb-1 rounded-xl text-[12px] mx-1 cursor-pointer bg-secondary"
                                onClick={() => {
                                  openproject(project.id);
                                }}
                              >
                                {project.projectName}
                              </div>
                            );
                          }
                        })
                      : ""}
                  </div>
                );
              } else {
                return (
                  <div
                    key={index}
                    className="p-1 text-center  mb-2 rounded-xl text-[14px] mx-3 cursor-pointer bg-secondary"
                    onClick={() => {
                      openClient(client.id);
                    }}
                  >
                    {client.clientName}
                  </div>
                );
              }
            })
          ) : (
            <div className="text-center">No records Found</div>
          )}
        </div>
      </div>
      <div className="h-[95%] w-[80%] px-5 flex flex-col  rounded-3xl gap-1">
        <h1 className="text-[22px] mb-3 font-bold">
          {categories.contentscreen
            ? categories.Contentform.header
            : "PENDING TASK"}
        </h1>
        <div
          className={
            categories.contentscreen
              ? "overflow-x-auto w-full h-full"
              : "overflow-x-auto w-full grid grid-cols-4 gap-6"
          }
        >
          {categories.contentscreen ? (
            categories.Contentform.header.includes("Client") ? (
              <div className="grid grid-cols-2 gap-6">
                <String name="clientName" label="Client Name :" />
                <String name="phoneno" label="Phone Number :" />
                <String name="email" label="E-mail :" />
                <String name="category" label="Category Name :" />
                <String name="film_name" label="Film Name :" />
                <String name="address" label="Address :" />

                <Dropdown
                  name="activestatus"
                  label="Active Status :"
                  list={[
                    { value: true, label: "Active" },
                    { value: false, label: "InActive" },
                  ]}
                />
                <String name="URL" label="URL :" />
                <div className="col-span-2">
                  <div className="text-[18px] mb-3 font-bold">Projects</div>
                  <div className="grid grid-cols-4 gap-3 mb-3 items-center">
                    {categories.projects.map((item) => (
                      <div
                        onClick={() => {
                          openproject(item.id);
                        }}
                        className="bg-tertiary  px-7 py-2 rounded-xl cursor-pointer text-[14px] font-semibold col-span-1 w-full h-fit"
                      >
                        {item.projectName}
                      </div>
                    ))}

                    {store?.state?.properties?.projects?.add && (
                      <div
                        className="bg-tertiary  px-4  rounded-xl cursor-pointer text-[32px] font-normal col-span-1 w-fit h-fit"
                        onClick={() => {
                          store.navTo("/" + URLPRIFIX + "/projects/create");
                        }}
                      >
                        +
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ) : categories.Contentform.header.includes("Project") ? (
              <div className="grid grid-cols-2 gap-6">
                <String name="projectName" label="Project Name :" />
                <String name="film_name" label="Film Name :" />
                <String name="clientName" label="Client Name :" />
                <Dropdown
                  name="priority"
                  label="Priority :"
                  list={[
                    { value: "High", label: "High" },
                    { value: "Medium", label: "Medium" },
                    { value: "Low", label: "Low" },
                  ]}
                />
                <Dropdown
                  name="status"
                  label="Status :"
                  list={[
                    { value: "Draft", label: "Draft" },
                    { value: "inprogress", label: "InProgress" },
                    { value: "Completed", label: "Completed" },
                  ]}
                />
                <Dropdown
                  name="activeStatus"
                  label="Active Status :"
                  list={[
                    { value: true, label: "Active" },
                    { value: false, label: "InActive" },
                  ]}
                />
                <div className="col-span-2 max-md:col-span-1">
                  <Journal
                    name="projectDescription"
                    label="Project Description :"
                    doubleline={true}
                  />
                </div>
                <DatePicker name="startDate" label="Start Date :" />
                <DatePicker name="endDate" label="End Date :" />
                <div className="col-span-2">
                  <div className="text-[18px] mb-3 font-bold">Documents</div>
                  <div className="grid grid-cols-4 gap-3 mb-3 items-center">
                    {categories.documents.map((item) => (
                      <div
                        onClick={() => {
                          openDocument(item.id);
                        }}
                        className="bg-tertiary px-7 py-2 rounded-xl cursor-pointer text-[14px] font-semibold col-span-1 w-full h-fit"
                      >
                        {item.task_title}
                      </div>
                    ))}
                    {store?.state?.properties?.documents?.add && (
                      <div
                        className="bg-tertiary  px-4  rounded-xl cursor-pointer text-[32px] col-span-1 w-fit h-fit"
                        onClick={() => {
                          store.navTo("/" + URLPRIFIX + "/documents/create");
                        }}
                      >
                        +
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ) : categories.Contentform.header.includes("Document") ? (
              <div className="grid grid-cols-2 gap-6 mb-3">
                <String name="subtask_title" label="Task Title :" />
                <Dropdown
                  name="film_id"
                  label="Film Name :"
                  list={store.getprops("film_name_list") || []}
                />
                <Dropdown
                  name="contact_id"
                  label="Client Name :"
                  list={store.getprops("clientName_list")}
                />
                <Dropdown
                  name="project_id"
                  label="Project Name :"
                  list={store.getprops("projectName_list") || []}
                />
                <Dropdown
                  name="form_id"
                  label="Artist Name :"
                  list={store.getprops("name_of_Artist_list") || []}
                  onView={(id) => {
                    store.navTo("/" + URLPRIFIX + "/forms/view/" + id);
                  }}
                />
                <Dropdown
                  name="task_id"
                  label="Document Name :"
                  list={store.getprops("task_title_list") || []}
                />
                <Dropdown
                  name="assigned_to"
                  label="Assigned To :"
                  list={store.getprops("userName_list") || []}
                />
                <Dropdown
                  name="status"
                  label="Status :"
                  list={[
                    { value: "Draft", label: "Draft" },
                    { value: "inprogress", label: "inprogress" },
                    { value: "hold", label: "Hold" },
                    {
                      value: "waitingforapproval",
                      label: "Waiting For Approval",
                    },
                    { value: "approved", label: "Approved" },
                    { value: "rejected", label: "Rejected" },
                    { value: "completed", label: "Completed" },
                    { value: "closedcompleted", label: "Closed Completed" },
                  ]}
                />
                <DatePicker name="dueDate" label="Due Date :" />
                <div className="col-span-2 max-md:col-span-1">
                  <Journal
                    name="description"
                    label="Document Description :"
                    doubleline={true}
                  />
                </div>

                <Dropdown
                  name="priority"
                  label="Priority :"
                  list={[
                    { value: "High", label: "High" },
                    { value: "Medium", label: "Medium" },
                    { value: "Low", label: "Low" },
                  ]}
                />
                <Dropdown
                  name="activeStatus"
                  label="Active Status :"
                  list={[
                    { value: true, label: "Active" },
                    { value: false, label: "InActive" },
                  ]}
                />
                <div className="col-span-2">
                  <div className="text-[18px] mb-6 font-bold">Tasks</div>
                  <div className="grid grid-cols-4 gap-3 mb-3 items-center">
                    {categories.Tasks.map((item) => (
                      <div
                        onClick={() => {
                          openTask(item.id);
                        }}
                        className="bg-tertiary px-7 py-2 rounded-xl cursor-pointer text-[14px] font-semibold col-span-1 w-full h-fit"
                      >
                        {item.subtask_title}
                      </div>
                    ))}
                    {store?.state?.properties?.tasks?.add && (
                      <div
                        className="bg-tertiary  px-4  rounded-xl cursor-pointer text-[32px] col-span-1 w-fit h-fit"
                        onClick={() => {
                          store.navTo("/" + URLPRIFIX + "/tasks/create");
                        }}
                      >
                        +
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ) : (
              categories.Contentform.header.includes("Task") && (
                <div className="grid grid-cols-2 gap-6 mb-3">
                  <String name="subtask_title" label="Task Title :" />
                  <Dropdown
                    name="project_id"
                    label="Project Name :"
                    list={store.getprops("projectName_list") || []}
                  />
                  <Dropdown
                    name="form_id"
                    label="Form Name :"
                    list={[{ value: 1, label: "form 1" }]}
                  />
                  <Dropdown
                    name="task_id"
                    label="Task Name :"
                    list={store.getprops("task_title_list") || []}
                  />
                  <Dropdown
                    name="assigned_to"
                    label="Assigned To :"
                    list={store.getprops("userName_list") || []}
                  />
                  <Dropdown
                    name="status"
                    label="Status :"
                    list={[
                      { value: "Draft", label: "Draft" },
                      { value: "inprogress", label: "inprogress" },
                      { value: "Completed", label: "Completed" },
                    ]}
                  />
                  <div className="col-span-2">
                    <Journal
                      name="description"
                      label="Document Description :"
                      doubleline={true}
                    />
                  </div>
                  <DatePicker name="dueDate" label="Due Date :" />
                  <Dropdown
                    name="priority"
                    label="Priority :"
                    list={[
                      { value: "High", label: "High" },
                      { value: "Medium", label: "Medium" },
                      { value: "Low", label: "Low" },
                    ]}
                  />

                  <Dropdown
                    name="activeStatus"
                    label="Active Status :"
                    list={[
                      { value: true, label: "Active" },
                      { value: false, label: "InActive" },
                    ]}
                  />
                </div>
              )
            )
          ) : (
            <ListView
              Tablehead={
                "min-w-[150px] pr-[10px] max-sm:text-[26px] md:text-[20px] lg:text-[16px] xl:text-[20px] font-bold"
              }
              Tablerecord=" max-sm:text-[26px] md:text-[20px] lg:text-[12px] xl:text-[16px] cursor-pointer "
              width={"1000px"}
              height={"450px"}
              header={[
                ["Task Title", "subtask_title"],
                ["Priority", "priority"],
                ["Status", "status"],
                ["Action", "ACTION"],
                ["Due Date", "dueDate"],
              ]}
              onview={(index) => {
                openTask(index);
              }}
              records={categories.Tasks}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default NonLitigation;
