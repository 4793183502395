import { useNavigate, useParams } from "react-router-dom";
import { useStore } from "../../../Components/StateProvider";
import { useEffect, useState } from "react";
import * as XLSX from "xlsx";
import { jsPDF } from "jspdf";
import {
  baseurl,
  ErrorAlert,
  GetDateTimeFormat,
  GetRecords,
  RupeeFormat,
  UploadFile,
  WarningAlert,
} from "../../../functions/api";
import AccountsLayout from "../AccountsLayout";
import FormView from "../../../Components/Elements/FormView";
import { MdOutlineFileUpload } from "react-icons/md";
import { FaRegEye } from "react-icons/fa6";
import { HiOutlineArrowSmDown, HiOutlineArrowSmUp } from "react-icons/hi";

const TrailBalance = () => {
  const { companyId } = useParams();
  const navigate = useNavigate();
  const getrecord = `/users/users/${companyId}`;
  const attachment_Prefix = "Journal_";
  const store = useStore();

  // *initial values
  const init = {
    entries: [],
    totalDebit: [],
    totalCredit: [],
    totalCredit: [],
    totalCredit: [],
    fromDate: "",
    ToDate: "",
  };
  const initProps = {
    add: false,
    delete: false,
    on_off: false,
    print: false,
    update: false,
    view: false,
    entries: {},
    totalDebit: {},
    totalCredit: {},

    totalCredit: {},
    totalCredit: {},
    fromDate: {},
    ToDate: {},
  };

  // *states declaration for records and props
  const [Record, setRecord] = useState(init);
  const [props, setprops] = useState(initProps);

  // *initial values set on the page load
  useEffect(() => {
    store.setmodule("trialbalance", init);

    const dateTime = GetDateTimeFormat();

    handleMultiFieldChange({
      fromDate: dateTime.MonthStartDate + "Z",
      ToDate: dateTime.MonthEndDate + "Z",
    });

    loadRecord(dateTime.MonthStartDate + "Z", dateTime.MonthEndDate + "Z");
  }, []);

  function loadRecord(fromDate, ToDate) {
    GetRecords(
      `/api/v2/accounting/trialbalance/${companyId}?startDate=${
        fromDate || Record.fromDate
      }&endDate=${ToDate || Record.ToDate}`
    ).then((res) => {
      console.log("accounting/trialbalance", res);
      if (res.success) {
        res = res.record;
        handleMultiFieldChange({
          entries: res.entries,
          totalDebit: res.totalDebit,
          totalCredit: res.totalCredit,
          total_opening_balance: res.total_opening_balance,
          total_closing_balance: res.total_closing_balance,
        });
      }
    });
  }

  // *print the Record and Props if any value change between the Record and Props
  useEffect(() => {
    console.log("Record State change -> ", Record);
    console.log("Props State change -> ", props);
    console.log("RupeeFormat change -> ", RupeeFormat(1500));
  }, [Record, props]);

  // *create Or update the Record
  function update() {}
  function Submit() {
    store.startLoading();
    let savedata = { ...Record };
    savedata.modifiedBy = store.getuser().id;
    savedata.createdBy = store.getuser().id;
    console.log("Submiting savedata", savedata);
    if (CheckMandatory()) {
      store.stopLoading();
      localStorage.setItem("journal_Number", Record.journal_Number + 1);

      //   saveRecord(setrecord, savedata).then((res) => {
      //     console.log("Resonce of saveRecord - ", res);
      //     if (res.success) {
      //       store.stopLoading();
      //       SuccessAlert("User Submitted Successfully");
      //       store.navback();
      //     } else {
      //       store.stopLoading();
      //       ErrorAlert(
      //         res?.errormessage?.response?.data?.errorMessage ||
      //           " Error Found Please contact the Admin"
      //       );
      //     }
      //   });
    }
  }

  // *Check for the mandatory fields if empty or not
  function CheckMandatory() {
    let out = true;
    let Fields = "";
    for (let obj in props) {
      if (typeof props[obj] === "object") {
        if (props[obj].mandatory) {
          if (Record[obj] == "" || Record[obj] == " ") {
            HandlePropsChange(obj, { mandatory: true, show: true });
            out = false;
          } else {
            HandlePropsChange(obj, { mandatory: true, show: false });
          }
        }
      }
    }
    out === false && ErrorAlert("Please fill the Mandatory Fields.");
    out === false && store.stopLoading();
    return out;
  }

  // *Handle Fields changes in Record States
  function handleFieldChange(fieldName, value) {
    setRecord((prev) => {
      return { ...prev, [fieldName]: value };
    });
  }
  function handleMultiFieldChange(value = {}) {
    setRecord((prev) => {
      for (let fieldName in value) {
        prev[fieldName] = value[fieldName];
      }
      return { ...prev };
    });
  }

  // *Handle Props Changes in Props States
  function HandlePropsChange(fieldName, value = {}) {
    setprops((prev) => {
      let oldprop = prev[fieldName];

      for (let prophead in value) {
        oldprop[prophead] = value[prophead];
      }
      prev[fieldName] = oldprop;
      return { ...prev };
    });
  }
  function HandleMultiPropsChange(NewProps = {}) {
    setprops((prev) => {
      for (let fieldName in NewProps) {
        let oldprop = prev[fieldName];
        let newprop = NewProps[fieldName];
        console.log("typeof newprop", typeof newprop);

        if (typeof newprop === "object") {
          for (let prophead in newprop) {
            oldprop[prophead] = newprop[prophead];
          }
          prev[fieldName] = oldprop;
        } else {
          prev[fieldName] = newprop;
        }
      }
      console.log("updated props", prev);

      return { ...prev };
    });
  }

  // *Handle Change in the fields
  function HandleAttachement(event) {
    var bodyFormData = new FormData();
    bodyFormData.append("file", event.target.files[0]);
    UploadFile(attachment_Prefix, bodyFormData).then((res) => {
      if (res.success) {
        handleFieldChange("attachment", res.data);
      }
    });
  }

  const ExportExcel = () => {
    try {
    if (!Record || !Record.entries || Record.entries.length === 0) {
      WarningAlert("No data available to export.");
      return;
    }
    const formattedEntries = Record.entries.map((entry) => ({
      AccountName: entry.account_name,
      OpeningBalance: entry.opening_balance,

      Debit: entry.debit,

      Credit: entry.credit,

      ClosingBalance: entry.closing_balance,
    }));

    const totalDebit = Record.totalDebit;
    const totalCredit = Record.totalCredit;
    const totalopeningbalance = Record.total_opening_balance;
    const totalclosingbalance = Record.total_closing_balance;

    formattedEntries.push({
      AccountName: "TOTAL",
      OpeningBalance: totalopeningbalance,
      Debit: totalDebit,
      Credit: totalCredit,
      ClosingBalance: totalclosingbalance,
    });

    const fromDate = new Date(Record.fromDate);
    const toDate = new Date(Record.ToDate);
    const formattedFromDate = fromDate.toISOString().split("T")[0];
    const formattedToDate = toDate.toISOString().split("T")[0];
    let rows = [];

    rows.push(["", "", ` ${formattedFromDate} - ${formattedToDate} `]);

    const ws = XLSX.utils.aoa_to_sheet(rows);

    XLSX.utils.sheet_add_json(ws, formattedEntries, { origin: 1 });
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Entries");
    // const filename = `Trial Balance ${formattedFromDate} to ${formattedToDate}.xlsx`;

    XLSX.writeFile(wb, "Trial Balance.xlsx");
  } catch (error) {
    console.error("Error generating Excel file:", error);
    WarningAlert("An error occurred while generating the Excel file.");
  }
  };
const ExportPDF = () => {
  try {
    if (!Record || !Record.entries || Record.entries.length === 0) {
      WarningAlert("No data available to export.");
      return;
    }

    const doc = new jsPDF();
    const data = Record;
    const HeaderTitle = "TRIAL BALANCE";
    const logo = "/Assets/images/Logo.png";
    const textWidth = doc.getTextWidth(HeaderTitle);
    const pageWidth = doc.internal.pageSize.width;
    
    const xPosition = (pageWidth - textWidth) / 2;
    const fromDate = new Date(Record.fromDate);
    const toDate = new Date(Record.ToDate);
    const formattedFromDate = fromDate.toISOString().split("T")[0];
    const formattedToDate = toDate.toISOString().split("T")[0];
    doc.addImage(logo, "PNG", 15, 5, 15, 15);
    const columnWidth = 60;
    const dateRange = `  ${formattedFromDate} - ${formattedToDate}`;
    doc.setFontSize(10);
    doc.setTextColor(0, 0, 66);
    doc.text("ABSOLUTE LEGAL", 8, 25);
    doc.text("LAW FIRM", 15, 30);
    doc.setTextColor(0, 0, 0);
    doc.setFont("helvetica", "bold");
    doc.setFontSize(16);
    doc.text(HeaderTitle, xPosition, 20);
    doc.setFontSize(11);
    doc.setDrawColor(169, 169, 169);
    doc.setLineWidth(0.2);
    const textWidth1 = 60;
    const textHeight = 8;
    doc.rect(150, 5, textWidth1 + 2, textHeight);
    doc.text(dateRange, 155, 10);
    doc.text("Account Name", 10, 50);
    doc.text("OpeningBalance", 70, 50);
    doc.text("Debit", 115, 50);
    doc.text("Credit", 150, 50);
    doc.text("ClosingBalance", 175, 50);

    doc.setFont("helvetica", "normal");
    let yPosition = 60;

    const addContentToPage = (account_name, opening_balance, debit, credit, closing_balance, yPos) => {
      doc.text(account_name, 10, yPos);
      doc.text(`${opening_balance}`, 75, yPos);
      doc.text(`${debit}`, 115, yPos);
      doc.text(`${credit}`, 150, yPos);
      doc.text(`${closing_balance}`, 175, yPos);
      doc.setLineWidth(0.05);
      doc.line(10, yPos + 2, pageWidth - 10, yPos + 2);
      return yPos + 10;
    };

    data.entries.forEach((entry, index) => {
      if (yPosition > doc.internal.pageSize.height - 30) {
        doc.addPage();
        yPosition = 20; 
      }

      yPosition = addContentToPage(
        entry.account_name,
        entry.opening_balance,
        entry.debit,
        entry.credit,
        entry.closing_balance,
        yPosition
      );
    });

    const marginBottom = 30;
    const pageHeight = doc.internal.pageSize.height;
    const yPosition1 = pageHeight - marginBottom;

    doc.setTextColor(0, 0, 0);
    doc.setFont("helvetica", "bold");
    doc.text("Total", 20, yPosition1);
    doc.text(`${data.total_opening_balance}`, 75, yPosition1);
    doc.text(`${data.totalDebit}`, 115, yPosition1);
    doc.text(`${data.totalCredit}`, 150, yPosition1);
    doc.text(`${data.total_closing_balance}`, 170, yPosition1);
    const pageCount = doc.internal.getNumberOfPages();
    for (let i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      const pageNumber = `Page ${i}`;
      const pageNumberX = (pageWidth - doc.getTextWidth(pageNumber)) / 2; 
      doc.setFontSize(10);
      doc.text(pageNumber, pageNumberX, pageHeight - 10);
    }
    doc.save("Trial_Balance.pdf");
  } catch (error) {
    console.error("Error generating PDF:", error);
    WarningAlert("An error occurred while generating the PDF.");
  }
};

  return (
    <AccountsLayout HeaderTitle={"Trial Balance"}>
      <div className="flex px-10 gap-3 py-1 bg-Alice_Blue justify-center items-center border-b border-Old_Silver">
        <div // fromDate
          className={
            "flex text-[14px] w-full h-full px-3 flex-row items-center gap-3 " +
            (props.fromDate.hidden ? " hidden " : " flex ")
          }
        >
          <label className={"w-fit  min-w-[100px] max-w-full"}>
            From Date
            {props.fromDate.mandatory && (
              <span className="text-[#ff2828] text-[14px] h-fit">*</span>
            )}
          </label>
          <input
            className={
              "w-full border-b border-Old_Silver border-opacity-35 font-medium disabled:bg-[#c6c6ca] text-Old_Silver disabled:bg-opacity-20 px-2 py-1 " +
              (props.fromDate.mandatory && props.fromDate.show
                ? " rounded-2xl border-2 border-[#ff2828] "
                : "")
            }
            id={"fromDate"}
            type="date"
            value={Record.fromDate.split("T")[0]}
            disabled={props.fromDate.readonly}
            onChange={(event) => {
              console.log("fromDate new Tsate");
              if (
                new Date(event.target.value + "T00:00:00Z") <
                new Date(Record.ToDate)
              ) {
                handleFieldChange(
                  "fromDate",
                  event.target.value + "T00:00:00Z"
                );
                loadRecord(event.target.value + "T00:00:00Z", Record.ToDate);
              } else {
                handleMultiFieldChange({
                  fromDate: event.target.value + "T00:00:00Z",
                  ToDate: event.target.value + "T23:59:59Z",
                });
                loadRecord(
                  event.target.value + "T00:00:00Z",
                  event.target.value + "T23:59:59Z"
                );
              }
            }}
          />
        </div>
        <div // ToDate
          className={
            "flex text-[14px] w-full h-full px-3 flex-row items-center gap-3 " +
            (props.ToDate.hidden ? " hidden " : " flex ")
          }
        >
          <label className={"w-fit  min-w-[100px] max-w-full"}>
            To Date
            {props.ToDate.mandatory && (
              <span className="text-[#ff2828] text-[14px] h-fit">*</span>
            )}
          </label>
          <input
            className={
              "w-full border-b border-Old_Silver border-opacity-35 font-medium disabled:bg-[#c6c6ca] text-Old_Silver disabled:bg-opacity-20 px-2 py-1 " +
              (props.ToDate.mandatory && props.ToDate.show
                ? " rounded-2xl border-2 border-[#ff2828] "
                : "")
            }
            id={"ToDate"}
            type="date"
            value={Record.ToDate.split("T")[0]}
            disabled={props.ToDate.readonly}
            onChange={(event) => {
              if (
                new Date(Record.fromDate) <
                new Date(event.target.value + "T23:59:59Z")
              ) {
                handleFieldChange("ToDate", event.target.value + "T23:59:59Z");
                loadRecord(Record.fromDate, event.target.value + "T23:59:59Z");
              } else {
                WarningAlert(
                  "The 'To Date' cannot be earlier than the 'From Date'"
                );
              }
            }}
          />
        </div>
        <div className="flex gap-5">
          <button
            className={
              "py-2 px-6 rounded-xl font-bold text-[12px]  bg-primary text-secondary"
            }
            onClick={ExportExcel}
          >
            EXCEL
          </button>
          <button
            className={
              "py-2 px-6 rounded-xl font-bold text-[12px]  bg-primary text-secondary"
            }
            onClick={ExportPDF}
          >
            PDF
          </button>
        </div>
      </div>
      <div className="flex  h-[90%] px-10 pt-2 bg-Alice_Blue rounded-lg">
        <div className="w-full p-2 ">
          <div className="h-full">
            <div className="flex gap-2 p-1 font-bold pr-2.5 bg-Azureish_White">
              <span className="w-[60%]">Account Name</span>
              <span className="w-[20%] px-2 pt-1 border-l">
                Opening Balance
              </span>
              <span className="w-[20%] px-2 pt-1 border-l">Debit</span>
              <span className="w-[20%] px-2 pt-1 border-l">Credit</span>
              <span className="w-[20%] px-2 pt-1 border-l">
                Closing Balance
              </span>
            </div>
            <div className=" h-[80%] overflow-y-scroll p-1 ">
              {Record.entries.map((item, index) => {
                if (item.amount != 0) {
                  return (
                    <>
                      <div
                        key={index}
                        className="flex gap-2 border-b border-tertiary hover:bg-Azureish_White pl-1"
                        onClick={() => {
                          let entries = Record.entries;
                          if (item.show) {
                            entries[index].show = false;
                          } else {
                            entries[index].show = true;
                          }
                          handleFieldChange("entries", entries);
                        }}
                      >
                        <span className="w-[60%]">{item.account_name}</span>
                        <span
                          className={
                            " w-[20%] px-2 pt-1 border-l border-primary " +
                            (item.opening_balance == 0
                              ? " text-Old_Silver "
                              : " text-primary font-semibold ")
                          }
                        >
                          {RupeeFormat(item.opening_balance)}
                        </span>
                        <span
                          className={
                            " w-[20%] px-2 pt-1 border-l border-primary " +
                            (item.debit == 0
                              ? " text-Old_Silver "
                              : " text-primary font-semibold ")
                          }
                        >
                          {RupeeFormat(item.debit)}
                        </span>
                        <span
                          className={
                            " w-[20%] px-2 pt-1 border-l border-primary " +
                            (item.credit == 0
                              ? " text-Old_Silver "
                              : " text-primary font-semibold ")
                          }
                        >
                          {RupeeFormat(item.credit)}
                        </span>
                        <span
                          className={
                            " w-[20%] px-2 pt-1 border-l border-primary " +
                            (item.balance == 0
                              ? " text-Old_Silver "
                              : " text-primary font-semibold ")
                          }
                        >
                          {RupeeFormat(item.closing_balance)}
                        </span>
                      </div>
                    </>
                  );
                }
              })}
            </div>
            <div className="flex gap-2 p-1 font-bold pr-2.5 mt-2">
              <span className="w-[60%]">Total</span>
              <span
                className={
                  " w-[20%] px-2 pt-1 border-l border-primary " +
                  (Record.totalDebit == 0? " text-Old_Silver ": " text-primary font-semibold ")
                }
              >
                {RupeeFormat(Record.total_opening_balance)}
              </span>
              <span
                className={
                  " w-[20%] px-2 pt-1 border-l border-primary " +
                  (Record.totalDebit == 0
                    ? " text-Old_Silver "
                    : " text-primary font-semibold ")
                }
              >
                {RupeeFormat(Record.totalDebit)}
              </span>
              <span
                className={
                  " w-[20%] px-2 pt-1 border-l border-primary " +
                  (Record.totalCredit == 0
                    ? " text-Old_Silver "
                    : " text-primary font-semibold ")
                }
              >
                {RupeeFormat(Record.totalCredit)}
              </span>
              <span
                className={
                  " w-[20%] px-2 pt-1 border-l border-primary " +
                  (Record.totalDebit - Record.totalCredit == 0
                    ? " text-Old_Silver font-normal "
                    : " text-primary font-semibold ")
                }
              >
                {RupeeFormat(Record.total_closing_balance)}
              </span>
            </div>
          </div>
        </div>
      </div>
    </AccountsLayout>
  );
};


export default TrailBalance;
