import Statehandler from "../Statehandler";
import { useStore } from "../StateProvider";

const String = ({
  className = { field: "", lable: "", input: "" },
  name = "",
  type = "text",
  label = "",
  doubleline = false,
  readonly = false,
  onchange = () => {},
  onKeyPress = () => {},
}) => {
  const store = useStore();

  let props = store.getprops(name) || {};

  if (!props.hidden) {
    return (
      <div
        className={
          (className.field === ""
            ? "flex text-[8px] md:text-[14px] md:w-full md:h-full px-0 md:px-3 "
            : className.field) +
          (doubleline
            ? " flex-col md:gap-1 "
            : " flex-row items-center md:gap-3 ")
        }
      >
        <label
          className={
            className.lable === ""
              ? "w-fit min-w-[20px] md:min-w-[150px] max-w-full"
              : className.lable
          }
          onClick={() => {
            document.getElementById(name).focus();
          }}
        >
          {label}
          {props.mandatory && (
            <span className="text-[#ff2828] text-[8px] md:text-[14px] h-fit">
              *
            </span>
          )}
          {/* {" :"} */}
        </label>
        <input
          className={
            (className.input === ""
              ? "w-full border rounded-lg font-medium disabled:bg-[#c6c6ca] disabled:text-[#4d4d4e]"
              : className.input) +
            (props.mandatory && props.show
              ? "rounded-2xl border-2 border-[#ff2828] "
              : "") +
            (!doubleline ? " p-2 " : " px-2 py-1")
          }
          id={name}
          autoComplete="off"
          type={type}
          value={store.getvalue(name)}
          disabled={props.readonly}
          onChange={(event) => {
            store.setvalue(name, event.target.value);
            onchange(event.target.value);
          }}
          onKeyDown={onKeyPress}
        />
      </div>
    );
  }
};

export default String;
